import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeatmapMetricsResponse, LineMetricsResponse, TableMetricsResponse } from './requests';

export default createSlice({
  name: 'validation',
  initialState: {
    window: 604800,
    activeFilter: 'week',
    lineSuccessMetrics: [] as LineMetricsResponse,
    lineErrorMetrics: [] as LineMetricsResponse,
    tableMetrics: [] as TableMetricsResponse,
    loading: false,
    error: '' as string,
    heatmapMetrics: [] as HeatmapMetricsResponse
  },

  reducers: {
    changeFilter: (state, action: PayloadAction<'week' | 'month' | 'year'>) => {
      state.activeFilter = action.payload;
      switch (action.payload) {
        case 'week':
          state.window = 604800;
          break;
        case 'month':
          state.window = 2419200;
          break;
        case 'year':
          state.window = 29030400;
          break;
      }
    },

    lineSuccessMetrics: (state) => {
      state.loading = true;
    },

    onLineSuccessMetricsSuccess: (state, action: PayloadAction<LineMetricsResponse>) => {
      state.lineSuccessMetrics = action.payload;
      state.loading = false;
    },
    onLineSuccessMetricsError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    lineErrorMetrics: (state) => {
      state.loading = true;
    },

    onLineErrorMetricsSuccess: (state, action: PayloadAction<LineMetricsResponse>) => {
      state.lineErrorMetrics = action.payload;
      state.loading = false;
    },
    onLineErrorMetricsError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    tableMetrics: (state) => {
      state.loading = true;
    },

    onTableMetricsSuccess: (state, action: PayloadAction<TableMetricsResponse>) => {
      state.tableMetrics = action.payload;
      state.loading = false;
    },
    onTableMetricsError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },

    heatmapMetrics: (state) => {
      state.loading = true;
    },

    onHeatmapMetricsSuccess: (state, action: PayloadAction<HeatmapMetricsResponse>) => {
      state.heatmapMetrics = action.payload;
      state.loading = false;
    },
    onHeatmapMetricsError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    }
  }
});
