import moment from 'moment';
import { HttpClient } from '../../http/client';

export type ResponseListApiKey = {
  api_keys: {
    name: string;
    id: string;
    created_at: string;
    deleted_at: string;
    expiration: string;
    scopes: string[];
  }[];
};

export type ResponseCreateApiKey = {
  key: string;
};

export async function requestListApiKey(userId: string): Promise<ResponseListApiKey> {
  return await HttpClient.get(`/credentials/v1/apikeys?user_id=${userId}`);
}

export async function requestCreateApiKey(
  userId: string,
  name: string,
  expiration: Date,
  scopes: string[]
): Promise<ResponseCreateApiKey> {
  const body = {
    user_id: userId,
    name,
    scopes,
    expiration: moment(expiration).format().slice(0, 19)
  };

  return await HttpClient.post(`/credentials/v1/apikeys`, body);
}

export async function requestDeleteApiKey(keyId: string): Promise<never> {
  return await HttpClient.delete(`/credentials/v1/apikeys/${keyId}`);
}
