import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { signupActions } from '../actions';
import { requestSignup } from './requests';

function* signup(
  action: PayloadAction<{
    name: string;
    surname: string;
    email: string;
    password: string;
    country: string;
  }>
) {
  try {
    const { name, surname, email, password, country } = action.payload;
    yield call(requestSignup, name, surname, email, password, country);
    yield put(signupActions.onSignupSuccess());
  } catch (e: any) {
    yield put(
      signupActions.onSignupError({
        code: e.code
      })
    );
  }
}

export default function* signupSaga() {
  yield takeLatest(signupActions.signup.type, signup);
}
