import { AnchorNetwork, Network } from '@bloock/sdk';

export function convertAnchorNetworkToNetwork(network: AnchorNetwork): Network {
  switch (network.name) {
    case 'ethereum_mainnet':
      return Network.ETHEREUM_MAINNET;
    case 'gnosis_chain':
      return Network.GNOSIS_CHAIN;
    case 'polygon_chain':
      return Network.POLYGON_CHAIN;
    case 'ethereum_sepolia':
      return Network.ETHEREUM_SEPOLIA;
    default:
      return Network.ETHEREUM_MAINNET;
  }
}
