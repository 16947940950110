import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IInvoiceList = {
  email?: string;
  invoice_pdf?: string;
  paid_at: string;
  period_end: string;
  period_start: string;
  total: string;
  invoice_lines?: {
    amount?: string;
    description?: string;
    period_start?: string;
    period_end?: string;
    quantity?: number;
    unit_price: string;
    nickname: string;
  }[];
  subtotal: string;
  subtotal_excluding_tax: string;
  tax?: string;
  currency?: string;
  amount_due?: string;
  applied_balance?: string;
}[];

export type INextInvoice = {
  email?: string;
  invoice_pdf?: string;
  paid_at?: string;
  period_start?: string;
  period_end?: string;
  total?: string;
  invoice_lines?: {
    amount?: string;
    description?: string;
    period_start?: string;
    period_end?: string;
    quantity?: number;
    unit_price: string;
    nickname: string;
  }[];
  subtotal?: string;
  subtotal_excluding_tax: string;
  tax?: string;
  currency?: string;
  amount_due?: string;
  applied_balance?: string;
};

export default createSlice({
  name: 'invoices',
  initialState: {
    edit: {
      loading: false,
      error: '' as string
    },
    nextInvoiceLoading: false,
    invoicesLoading: false,
    error: '' as string,
    invoiceList: [] as IInvoiceList,
    nextInvoice: {} as INextInvoice
  },
  reducers: {
    invoiceList: (state) => {
      state.invoicesLoading = true;
    },
    onInvoiceListSuccess: (state, action: PayloadAction<IInvoiceList>) => {
      state.invoicesLoading = false;
      state.error = '';
      state.invoiceList = action.payload;
    },
    onInvoiceListError: (state, action: PayloadAction<{ error: number }>) => {
      state.invoicesLoading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.edit.error = 'Error while fetching information, please try again';
      }
    },
    nextInvoice: (state) => {
      state.nextInvoiceLoading = true;
    },
    onNextInvoiceSuccess: (state, action: PayloadAction<INextInvoice>) => {
      state.error = '';
      state.nextInvoice = action.payload;
      state.nextInvoiceLoading = false;
    },
    onNextInvoiceError: (state, action: PayloadAction<{ error: number }>) => {
      state.nextInvoiceLoading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.edit.error = 'Error while fetching information, please try again';
      }
    }
  }
});
