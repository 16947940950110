import { call, put, select, takeLatest } from 'redux-saga/effects';
import { IInvoiceList, INextInvoice } from '.';
import { invoicesActions } from '../actions';
import { getAuthUserId } from '../auth';
import { requestInvoiceList, requestNextInvoice } from './requests';

function* invoiceList() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: IInvoiceList = yield call(requestInvoiceList, userId) || [];
    yield put(invoicesActions.onInvoiceListSuccess(response));
  } catch (e: any) {
    yield put(
      invoicesActions.onInvoiceListError({
        error: e
      })
    );
  }
}

function* nextInvoice() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: INextInvoice = yield call(requestNextInvoice, userId) || [];
    yield put(invoicesActions.onNextInvoiceSuccess(response));
  } catch (e: any) {
    yield put(
      invoicesActions.onNextInvoiceError({
        error: e
      })
    );
  }
}

export default function* invoicesSaga() {
  yield takeLatest(invoicesActions.invoiceList.type, invoiceList);
  yield takeLatest(invoicesActions.nextInvoice.type, nextInvoice);
}
