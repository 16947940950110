import { Icon, Icons } from 'bloock-ui-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { routes } from '../routes';

const FallbackPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className=" w-screen h-screen flex flex-col md:flex-col items-center justify-center">
      <div className="todo">
        <div>
          <h1 className="text-black pb-5">{t('404-title')}</h1>
          <h2 className="font-semibold pb-12">{t('404-subtitle')}</h2>
          <p className="font-bold">{t('404-error')}</p>
          <p className="py-5">{t('404-text')}</p>
          <ul style={{ color: 'var(--secondary-color' }}>
            <li
              style={{ color: 'var(--secondary-color' }}
              className="py-2 "
              onClick={() => dispatch(push(routes.home.url))}
            >
              {t('dashboard')}
            </li>
            <li
              onClick={() =>
                dispatch(push('mailto:support@bloock.com?subject=Bloock Management Web'))
              }
              style={{ color: 'var(--secondary-color' }}
              className="py-2"
            >
              {t('help')}
            </li>
            <li
              style={{ color: 'var(--secondary-color' }}
              className="py-2"
              onClick={() => dispatch(push(routes.login.url))}
            >
              {t('login')}
            </li>
            <li
              style={{ color: 'var(--secondary-color' }}
              className="py-2"
              onClick={() => dispatch(push(routes.profile.url))}
            >
              {t('profile')}
            </li>
          </ul>
        </div>
        <div className="error404-icon">
          <Icon icon={Icons.Cactus} size={150} color={'var(--secondary-color)'}></Icon>
        </div>
      </div>
    </div>
  );
};

export default FallbackPage;
