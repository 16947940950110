import { Event, Invocation, Meta, Webhook } from '.';
import { HttpClient } from '../../http/client';

export type ResponseInvocation = {
  webhooks: Invocation[];
  meta: Meta;
};

export async function requestWebhooks(user_id: string): Promise<Webhook[]> {
  const url = `/notifications/v1/webhook/user/${user_id}`;
  return await HttpClient.get(url);
}

export async function requestEventsList(): Promise<Event> {
  const url = `/notifications/v1/events`;
  return await HttpClient.get(url);
}

export async function requestCreateWebhook(
  userId: string,
  url: string,
  event_types: string[]
): Promise<{ webhook_secret: string }> {
  const body = {
    user_id: userId,
    url,
    event_types
  };

  return await HttpClient.post(`/notifications/v1/webhook`, body);
}

export async function requestDeleteWebhook(webhook_id: string): Promise<never> {
  return await HttpClient.delete(`/notifications/v1/webhook/${webhook_id}`);
}

export async function requestWebhookDetails(webhook_id: string): Promise<Webhook> {
  const url = `/notifications/v1/webhook/${webhook_id}`;
  return await HttpClient.get(url);
}

export async function requestInvocationsList(
  webhook_id: string,
  page: number,
  per_page: number,
  status?: string
): Promise<ResponseInvocation> {
  let url = `/notifications/v1/webhook/response?webhook=${webhook_id}&page=${page}&per_page=${per_page}`;
  if (status) {
    url += `&status=${status}`;
  }
  return await HttpClient.get(url);
}

export async function requestGetInvocation(id: string): Promise<Invocation> {
  const url = `/notifications/v1/webhook/response/${id}`;
  return await HttpClient.get(url);
}
