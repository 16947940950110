import { HttpClient } from '../../http/client';
import { ResponseSubscriptionProducts } from '../metadata/requests';

export type IProduct = {
  product_id: string;
  name: string;
  description: string;
  prices: {
    price_id: string;
    currency: string;
    amount_decimal: string;
    nickname: string;
    usage_type: string;
    interval: string;
    active: boolean;
    included_amount: string;
  }[];
  metadata: { [key: string]: string };
};

export type ICoupon = {
  created_at: string;
  duration_in_months: number;
  id: string;
  name: string;
  percent_off: number;
  plans_applied: string[];
};

export type ResponseUserProduct = {
  coupon: ICoupon;
  products: ResponseSubscriptionProducts;
  cancel_at: 0;
};

export type ResponseUpdateProduct = {
  client_secret: string;
  subscription_id: string;
};

export type ResponseSubscriptionStatus = {
  status: string;
  period_start: string;
  period_end: string;
};

export async function requestUserProduct(userId: string): Promise<ResponseUserProduct> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/product`);
}

export async function requestUpdateProduct(
  userId: string,
  product_base_id: string[]
): Promise<ResponseUpdateProduct> {
  const body = {
    product_base_id
  };

  return await HttpClient.post(`/subscriptions/v1/users/${userId}/product`, body);
}

export async function requestSubscriptionStatus(
  userId: string
): Promise<ResponseSubscriptionStatus> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/subscription/status`);
}

export async function requestCancelSubscription(userId: string) {
  return await HttpClient.delete(`/subscriptions/v1/users/${userId}/subscription`);
}

export async function requestReactivateSubscription(userId: string) {
  return await HttpClient.put(`/subscriptions/v1/users/${userId}/subscription/activate`);
}
