import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Form } from '..';
import { changePasswordActions, modalActions } from '../../redux/actions';
import { useAppSelector } from '../../redux/store';
import Button from '../button';
import { TextInput } from '../form/text-input';
import { DefaultModal } from './default';
export type IChangePasswordModal = Record<string, unknown>;

export const ChangePasswordModal: React.FC<IChangePasswordModal> = () => {
  const { t: t } = useTranslation('profile');

  const { loading: changePasswordLoading, error: changePasswordError } = useAppSelector(
    (state) => state.changePassword
  );

  const dispatch = useDispatch();

  const defaultModalProps = {
    headerLabel: t('change'),
    success: false,
    dismiss: false
  };

  const schema = yup.object({
    current_password: yup.string().required(),
    new_password: yup
      .string()
      .required(t('password-required'))
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,

        t('password-validation')
      ),
    new_password_confirmation: yup
      .string()
      .required(t('password-match'))
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,

        t('password-validation')
      )
      .oneOf([yup.ref('new_password'), null], t('password-match'))
  });

  return (
    <DefaultModal {...defaultModalProps}>
      <div className="pt-4" id="simple-modal-description">
        <Form
          id="change-password-form"
          schema={schema}
          onSubmit={(data: {
            current_password: string;
            new_password: string;
            new_password_confirmation: string;
          }) => {
            dispatch(modalActions.close());
            dispatch(changePasswordActions.changePassword(data));
          }}
          error={changePasswordError}
        >
          <div className="text-left">
            <TextInput
              className="mt-3"
              name="current_password"
              type={'password'}
              label={t('current')}
            ></TextInput>

            <TextInput
              className="mt-3"
              name="new_password"
              type={'password'}
              label={t('new')}
            ></TextInput>
          </div>
          <div className="text-left">
            <TextInput
              className="mt-3"
              name="new_password_confirmation"
              type="password"
              label={t('confirm')}
            ></TextInput>
          </div>

          <span className="text-xs font-normal text-gray-500 mt-5">{t('requirements')} </span>
          <div className="mt-7 mb-3">
            <Button
              className="w-5/12 m-1"
              negative={false}
              onClick={() => dispatch(modalActions.close())}
            >
              {t('cancel')}
            </Button>
            <Button
              loading={changePasswordLoading}
              type="submit"
              className="w-5/12 m-1 "
              negative={true}
            >
              {t('save')}
            </Button>
          </div>
        </Form>
      </div>
    </DefaultModal>
  );
};
