import { call, put, takeLatest } from 'redux-saga/effects';
import { statisticsActions } from '../actions';
import { ResponseStatistics, requestStatistics } from './requests';

function* statistics() {
  try {
    const response: ResponseStatistics = yield call(requestStatistics);
    yield put(statisticsActions.onStatisticsSuccess(response));
  } catch (e: any) {
    yield put(
      statisticsActions.onStatisticsError({
        error: e
      })
    );
  }
}

export default function* statisticsSaga() {
  yield takeLatest(statisticsActions.statistics.type, statistics);
}
