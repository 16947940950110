import { all } from 'redux-saga/effects';
import accessControlSaga from './access-control/handlers';
import anchorsSaga from './anchors/handlers';
import apiKeysSaga from './apikeys/handlers';
import auditTrailSaga from './audit-trail/handlers';
import authSaga from './auth/handlers';
import availabilitySaga from './availability/handlers';
import certificatesSaga from './certificates/handlers';
import certifyData from './certifier/handlers';
import changePasswordSaga from './change-password/handlers';
import configurationSaga from './configuration/handlers';
import credentialsSchemasSaga from './credential-schemas/handlers';
import dashboardSaga from './dashboard/handlers';
import explorer from './explorer/handlers';
import feedbackSaga from './feedback/handlers';
import forgotPasswordSaga from './forgot-password/handlers';
import invoicesSaga from './invoices/handlers';
import issuersSaga from './issuers/handlers';
import keyOperationsSaga from './key-operations/handlers';
import keysSaga from './keys/handlers';
import licensesSaga from './licenses/handlers';
import loginSaga from './login/handlers';
import logsSaga from './logs/handlers';
import metadataSaga from './metadata/handlers';
import paymentSaga from './payment/handlers';
import profileSaga from './profile/handlers';
import recordsSaga from './records/handlers';
import resetPasswordSaga from './reset-password/handlers';
import signupSaga from './signup/handlers';
import statisticsSaga from './statistics/handlers';
import usageSaga from './usage/handlers';
import userProductSaga from './user-product/handlers';
import validationQuerySaga from './validation-queries/handlers';
import validationSaga from './validation/handlers';
import verifyAccountSaga from './verify-account/handlers';
import webhookSaga from './webhooks/handlers';

export default function* rootSaga() {
  yield all([
    authSaga(),
    dashboardSaga(),
    loginSaga(),
    signupSaga(),
    profileSaga(),
    changePasswordSaga(),
    resetPasswordSaga(),
    forgotPasswordSaga(),
    apiKeysSaga(),
    explorer(),
    statisticsSaga(),
    verifyAccountSaga(),
    metadataSaga(),
    feedbackSaga(),
    configurationSaga(),
    userProductSaga(),
    paymentSaga(),
    logsSaga(),
    webhookSaga(),
    licensesSaga(),
    certifyData(),
    validationSaga(),
    invoicesSaga(),
    usageSaga(),
    availabilitySaga(),
    keysSaga(),
    certificatesSaga(),
    keyOperationsSaga(),
    recordsSaga(),
    anchorsSaga(),
    issuersSaga(),
    auditTrailSaga(),
    credentialsSchemasSaga(),
    validationQuerySaga(),
    accessControlSaga()
  ]);
}
