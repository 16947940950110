import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ResponseInvocation } from './requests';

export type Webhook = {
  webhook_id: string;
  url: string;
  disabled: boolean;
  secret?: string;
  created_at?: string;
  events: {
    type: string;
    id: string;
  }[];
};

export type Event = {
  event_types: string[];
};

export type Invocation = {
  id: string;
  webhook_id: string;
  request_id: string;
  type: string;
  user_id: string;
  request_body: Record<string, unknown>;
  request_status: string;
  response_status_code: number;
  response_body: Record<string, unknown>;
  method: string;
  url: string;
  created_at: number;
};

export type Meta = {
  current_page: number;
  per_page: number;
  from: number;
  to: number;
  total: number;
  last_page: number;
};

export default createSlice({
  name: 'webhooks',
  initialState: {
    loading: false,
    loadingWebhooksList: false,
    loadingCreateWebhook: false,
    error: '' as string,
    webhooksList: [] as Webhook[],
    events: [] as any,
    webhook_secret: '' as string,
    webhook_detail: {} as Webhook,
    webhook_id: '' as string,
    invocations: [] as Invocation[],
    selectedInvocation: undefined as Invocation | undefined,
    loadingSelectedInvocation: false,
    meta: {} as Meta,
    page: 1,
    per_page: 10,
    status: ''
  },
  reducers: {
    webhooksList: (state) => {
      state.loadingWebhooksList = true;
    },

    onWebhooksListSuccess: (state, action: PayloadAction<Webhook[]>) => {
      state.webhooksList = action.payload;
      state.loadingWebhooksList = false;
    },

    onWebhooksListError: (state, action: PayloadAction<{ error: number }>) => {
      state.loadingWebhooksList = false;
      switch (action.payload.error) {
        case 4012:
          state.error = 'Error fetching webhooks List';
          break;
        default:
          state.error = 'Error fetching webhooks List';
      }
    },
    eventList: (state) => {
      state.loading = true;
    },
    onEventsListSuccess: (state, action: PayloadAction<Event>) => {
      state.events = action.payload;
      state.loading = false;
    },
    onEventListError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 4012:
          state.error = 'Error fetching events List';
          break;
        default:
          state.error = 'EError fetching events List';
      }
    },
    createWebhook: (
      state,
      _action: PayloadAction<{
        url: string;
        event_types: string[];
      }>
    ) => {
      state.loadingCreateWebhook = true;
    },
    onCreateWebhookSuccess: (state, action: PayloadAction<{ webhook_secret: string }>) => {
      state.webhook_secret = action.payload.webhook_secret;
      state.loadingCreateWebhook = false;
    },
    onCreateWebhookError: (state, _action: PayloadAction<{ error: number }>) => {
      state.loadingCreateWebhook = false;
      toast.error('Error creating webhook', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    deleteWebhook: (state, _action: PayloadAction<{ webhook_id: string }>) => {
      state.loading = true;
    },
    onDeleteWebhookSuccess: (state) => {
      state.loading = false;
      toast.success('Webhook deleted successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onDeleteWebhookError: (state, _action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      toast.error('Error deleting webhook', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onSelectInvocation: (state, _action: PayloadAction<{ id: string }>) => {
      state.loadingSelectedInvocation = true;
    },
    onSelectInvocationSuccess: (state, action: PayloadAction<Invocation>) => {
      state.selectedInvocation = action.payload;
      state.loadingSelectedInvocation = false;
    },
    onSelectInvocationError: (state, action: PayloadAction<{ error: number }>) => {
      state.loadingSelectedInvocation = false;
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    clearSelectInvocation: (state, _action: PayloadAction) => {
      state.selectedInvocation = undefined;
    },
    webhookDetails: (state, _action: PayloadAction<{ webhook_id: string }>) => {
      state.loading = true;
    },
    onWebhookDetailsSuccess: (state, action: PayloadAction<Webhook>) => {
      state.webhook_detail = action.payload;
      state.loading = false;
    },
    onWebhookDetailsError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 4012:
          state.error = 'Error fetching events List';
          break;
        default:
          state.error = 'Error fetching events List';
      }
    },
    webhookInvocationsList: (
      state,
      action: PayloadAction<{
        webhook_id: string;
        page?: number;
        pageSize?: number;
        status?: string;
      }>
    ) => {
      state.webhook_id = action.payload.webhook_id;
      state.loading = true;
    },
    onWebhookInvocationsSuccess: (state, action: PayloadAction<ResponseInvocation>) => {
      state.invocations = action.payload.webhooks;
      state.meta = action.payload.meta;
      state.loading = false;
    },
    onWebhookInvocationsError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 4012:
          state.error = 'Error fetching invocations List';
          break;
        default:
          state.error = 'Error fetching invocations List';
      }
    },
    changeStatusFilter: (state, action: PayloadAction<{ status: string }>) => {
      state.page = 1;
      state.status = action.payload.status;
    }
  }
});
