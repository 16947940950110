import { HttpClient } from '../../http/client';

export type ResponseStatistics = {
  avg_certs_per_anchor: number;
  avg_certs_per_sec: number;
  avg_time_per_anchor: number;
};

export async function requestStatistics(): Promise<ResponseStatistics> {
  return await HttpClient.get(`/anchors/v1/anchors/statistics`);
}
