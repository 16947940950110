import { HttpClient } from '../../http/client';

export type ResponseUpdateLimit = Record<string, never>;

export type ResponseRecordUsage = {
  record_counter: number;
  amount_included: number;
  record_limit: number;
  record_limit_price: number;
  unit_price: number;
  last_updated: string;
};

export async function requestRecordUsage(userId: string): Promise<ResponseRecordUsage> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/record_usage`);
}

export async function requestUpdateRecordLimit(
  userId: string,
  limit: number
): Promise<ResponseUpdateLimit> {
  return await HttpClient.post(`/subscriptions/v1/users/${userId}/record_usage`, {
    limit: limit.toString()
  });
}

export type ResponseInfrastructureRequestUsage = {
  networks: {
    requests_counter: number;
    network_name: string;
    last_updated: string;
  }[];
  amount_included: number;
  request_limit: number;
  request_limit_price: number;
  unit_price: number;
};

export async function requestInfrastructureRequestUsage(
  userId: string
): Promise<ResponseInfrastructureRequestUsage> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/request_usage`);
}

export async function requestUpdateInfrastructureRequestLimit(
  userId: string,
  limit: number
): Promise<ResponseUpdateLimit> {
  return await HttpClient.post(`/subscriptions/v1/users/${userId}/request_usage`, {
    limit: limit.toString()
  });
}

export type ResponseAvailabilityStorageUsage = {
  storage: {
    storage_type: 'hosted_storage' | 'ipfs_storage';
    stored_bytes: number;
  }[];
  stored_bytes: number;
  amount_included_bytes: number;
  storage_limit_bytes: number;
  storage_limit_price: number;
  unit_price: number;
  last_updated: string;
};

export async function requestAvailabilityStorageUsage(
  userId: string
): Promise<ResponseAvailabilityStorageUsage> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/storage_usage`);
}

export async function requestUpdateAvailabilityStorageLimit(
  userId: string,
  limit: number
): Promise<ResponseUpdateLimit> {
  return await HttpClient.post(`/subscriptions/v1/users/${userId}/storage_usage`, {
    limit: limit.toString()
  });
}

export type ResponseAvailabilityTransferUsage = {
  transfer: {
    storage_type: 'hosted_storage' | 'ipfs_storage';
    transferred_bytes: number;
  }[];
  amount_included_bytes: number;
  transfer_limit_bytes: number;
  transfer_limit_price: number;
  unit_price: number;
  last_updated: string;
};

export async function requestAvailabilityTransferUsage(
  userId: string
): Promise<ResponseAvailabilityTransferUsage> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/transfer_usage`);
}

export async function requestUpdateAvailabilityTransferLimit(
  userId: string,
  limit: number
): Promise<ResponseUpdateLimit> {
  return await HttpClient.post(`/subscriptions/v1/users/${userId}/transfer_usage`, {
    limit: limit.toString()
  });
}

export type ResponseKeysUsage = {
  key_type: 'keys_software' | 'keys_hsm';
  key_counter: number;
  amount_included: number;
  unit_price: number;
  key_limit: number;
  key_limit_price: number;
  last_updated: string;
}[];

export async function requestKeysUsage(userId: string): Promise<ResponseKeysUsage> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/key_usage`);
}

export type ResponseKeysTransactionsUsage = {
  transactions: number;
  amount_included: number;
  unit_price: number;
  transactions_limit: number;
  transactions_limit_price: number;
  last_updated: string;
};

export async function requestKeysTransactionsUsage(
  userId: string
): Promise<ResponseKeysTransactionsUsage> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/transactions_usage`);
}

export async function requestUpdateHsmKeysLimit(
  userId: string,
  limit: number
): Promise<ResponseUpdateLimit> {
  return await HttpClient.post(`/subscriptions/v1/users/${userId}/key_hsm_usage`, {
    limit: limit.toString()
  });
}

export async function requestUpdateSoftwareKeysLimit(
  userId: string,
  limit: number
): Promise<ResponseUpdateLimit> {
  return await HttpClient.post(`/subscriptions/v1/users/${userId}/key_software_usage`, {
    limit: limit.toString()
  });
}

export async function requestUpdateKeysTransactionsLimit(
  userId: string,
  limit: number
): Promise<ResponseUpdateLimit> {
  return await HttpClient.post(`/subscriptions/v1/users/${userId}/key_transactions_usage`, {
    limit: limit.toString()
  });
}
