import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, put, takeLatest } from 'redux-saga/effects';
import { routes } from '../../routes';
import { resetPasswordActions } from '../actions';
import { requestResetPassword } from './requests';

function* resetPassword(action: PayloadAction<{ password: string; token: string }>) {
  try {
    const { password, token } = action.payload;
    yield call(requestResetPassword, password, token);

    yield put(resetPasswordActions.onResetPasswordSuccess());

    yield put(push({ pathname: routes.login.url }));
  } catch (e: any) {
    yield put(
      resetPasswordActions.onResetPasswordError({
        error: e
      })
    );
  }
}

export default function* resetPasswordSaga() {
  yield takeLatest(resetPasswordActions.resetPassword.type, resetPassword);
}
