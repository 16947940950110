import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { recordsActions } from '../actions';
import { AppState } from '../reducers';
import {
  DetailRecordsResponse,
  RecordsResponse,
  getRequestRecord,
  requestDetailRecords
} from './requests';

function* getRecordsList(
  action: PayloadAction<{ page?: number; pageSize?: number; network?: string; anchorId?: number }>
) {
  try {
    const { recordFilter } = yield select((state: AppState) => state.records);

    const response: RecordsResponse = yield call(
      getRequestRecord as any,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10,
      recordFilter
    );
    yield put(recordsActions.onGetRecordsListSuccess(response));
  } catch (e: any) {
    yield put(
      recordsActions.onGetRecordsListError({
        error: e
      })
    );
  }
}

function* detailRecords(action: PayloadAction<string>) {
  try {
    const response: DetailRecordsResponse = yield call(requestDetailRecords, action.payload);

    yield put(recordsActions.onDetailRecordsSuccess(response));
  } catch (e: any) {
    yield put(
      recordsActions.onDetailRecordsError({
        error: e
      })
    );
  }
}

function* changeRecordFilter() {
  yield put(recordsActions.getRecordsList({}));
}

export default function* keysSaga() {
  yield takeLatest(recordsActions.getRecordsList.type, getRecordsList);
  yield takeLatest(recordsActions.recordDetail.type, detailRecords);
  yield takeLatest(recordsActions.changeRecordFilter.type, changeRecordFilter);
}
