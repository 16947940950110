import { JWTData, Scopes } from '../redux/auth';

enum Resource {
  UsersUser = 'users.user',
  CredentialsApikey = 'credentials.apikey',
  CredentialsSession = 'credentials.session',
  CoreAnchor = 'core.anchor',
  CoreProof = 'core.proof',
  CoreMessage = 'core.message',
  SubscriptionsSubscription = 'subscriptions.subscription',
  SubscriptionsUsage = 'subscriptions.usage',
  SubscriptionsPlan = 'subscriptions.plan',
  SubscriptionsInvoice = 'subscriptions.invoice',
  NotificationsWebhook = 'notifications.webhook',
  NotificationsFeedback = 'notifications.feedback',
  DataAvailabilityHosting = 'data-availability.hosting',
  DataAvailabilityIpfs = 'data-availability.ipfs',
  NodeProxyRedirect = 'node-proxy.redirect',
  KeysKey = 'keys.key',
  KeysCertificate = 'keys.certificate',
  KeysSign = 'keys.sign',
  KeysVerify = 'keys.verify',
  KeysEncrypt = 'keys.encrypt',
  KeysDecrypt = 'keys.decrypt',
  KeysAccessControl = 'keys.access_control',
  IdentityIssuer = 'identity.issuer',
  IdentitySchema = 'identity.schema',
  IdentityCredential = 'identity.credential',
  IdentityRevocation = 'identity.revocation',
  IdentityVerification = 'identity.verification',
  CertifierProcess = 'certifier.process',
  EventsActivity = 'events.activity'
}

enum Actions {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete'
}

export function hasScope(userData: JWTData | undefined, scopes: Scopes): boolean {
  if (!userData?.scopes) {
    return false;
  }
  for (const scope of Object.keys(scopes)) {
    let scopeAllowed = false;
    for (const userScope of Object.keys(userData?.scopes)) {
      if (scope == userScope) {
        const userActions = userData.scopes[scope];
        const allowedActions = scopes[scope];
        for (const allowedAction of allowedActions) {
          let actionAllowed = false;
          for (const userAction of userActions) {
            if (userAction == allowedAction) {
              actionAllowed = true;
            }
          }

          if (actionAllowed == false) {
            return false;
          }
        }
        scopeAllowed = true;
      }
    }

    if (scopeAllowed == false) {
      return false;
    }
  }

  return true;
}

export { Actions, Resource };
