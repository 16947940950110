import { Collapse } from 'antd';
import React from 'react';
import { EllipsisMiddle } from '../ellipsis-middle';
import { DefaultModal, IDefaultModal } from './default';

export type IAddTOTP = {
  headerLabel: string;
  successLabel?: string;
  messageFirstPart?: string;
  messageSecondPart?: string;
  titleScan?: string;
  titleUnable?: string;
  recoveryCodes?: string[];
  setUpKeyTitle?: string;
  recoveryKeysTitle?: string;
  secret?: string;
  manuallyConf?: string;
  qr?: string;
  links?: {
    href: string;
    text: string;
  }[];
  callback: () => void;
};

export const AddTOTP: React.FC<IAddTOTP> = ({ callback = () => {}, ...rest }) => {
  const defaultModalProps: IDefaultModal = {
    headerLabel: rest.headerLabel,
    successLabel: rest.successLabel,
    dismiss: false,
    width: 500,
    height: 'calc(100% - 10%)',
    successCallback: () => {
      callback();
    }
  };

  return (
    <DefaultModal {...defaultModalProps}>
      <div>
        {' '}
        <p className="text-sm pt-6">
          {rest.messageFirstPart}{' '}
          {rest.links?.map((key, id) => (
            <>
              <a className="underline" key={id} href={key.href}>
                {key.text}
              </a>
              <span>, </span>
            </>
          ))}
          {rest.messageSecondPart}
        </p>
        <h4 className="mt-8 pb-4">{rest.titleScan}</h4>
        <p className="pb-4">{rest.setUpKeyTitle}</p>
        <img src={rest.qr} />
        <h5 className="w-full my-5">{rest.titleUnable}</h5>
        <p>{rest.manuallyConf}</p>
        <div className="p-4 m-8 bg-bgSecondary w-50">
          <EllipsisMiddle copyable>{rest.secret}</EllipsisMiddle>
        </div>
        <p className="w-full pb-4 font-bold">{rest.recoveryKeysTitle}</p>
        <Collapse className="w-full">
          <Collapse.Panel key="1" header={rest.recoveryKeysTitle}>
            <div className="columns-2 w-1/2 m-auto">
              {rest.recoveryCodes?.map((code, i) => {
                return <p key={i}>{code}</p>;
              })}
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </DefaultModal>
  );
};
