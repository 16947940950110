import { Icon, Icons } from 'bloock-ui-components';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Error from '../error';

export type ITextInput = {
  name: string;
  label?: string | JSX.Element;
  icon?: JSX.Element;
  revealPassword?: boolean;
  rightLabel?: string;
  rules?: string;
  onRightLabelClick?: () => void;
};

const TextInput: React.VFC<InputHTMLAttributes<HTMLInputElement> & ITextInput> = ({
  name,
  icon = null,
  rightLabel = '',
  onRightLabelClick = () => {},
  type = 'text',
  ...rest
}) => {
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    setValue(name, rest.value);
  }, [rest.value]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <div className="flex items-center justify-between mb-3">
        <div>
          <span>{rest.label}</span>
          {rest.required && (
            <span className="pl-1" style={{ color: 'var(--danger-color)' }}>
              *
            </span>
          )}
        </div>
        <span
          className="cursor-pointer focus:underline"
          style={{ fontWeight: 400, color: 'var(--secondary-color)' }}
          onClick={onRightLabelClick}
        >
          {rightLabel}
        </span>
      </div>

      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <input
              {...rest}
              {...field}
              className={`input ${rest.className} w-full px-3 mt-0.5 mb-1 outline-none rounded text-base text-gray-900 border-none bg-transparent disabled:bg-gray-50 `}
              onChange={(e) => {
                if (rest.onChange) {
                  rest.onChange(e);
                }
                field.onChange(e);
              }}
              style={{
                border: errors[name]
                  ? '1px solid red'
                  : rest.disabled
                  ? '1px solid #d9d9d9'
                  : '1px solid #dadada',
                borderRadius: '5px',
                height: '40px',
                backgroundColor: rest.disabled ? '#f5f5f5' : '',
                color: rest.disabled ? '#00000040' : ''
              }}
              pattern={rest.rules}
              type={passwordShown ? 'text' : type}
            ></input>
          )}
        />
        {type == 'password' && !rest.disabled && (
          <div style={{ position: 'absolute', right: '2%' }}>
            <Icon
              onClick={togglePasswordVisiblity}
              icon={Icons.SeePassIcon}
              size={20}
              color={'var(--tertiary-text-color)'}
            ></Icon>
          </div>
        )}
        {icon && <div className="pl-2">{icon}</div>}
      </div>

      {errors[name] && <Error error={errors[name]}></Error>}
    </div>
  );
};

export { TextInput };
