import { Meta } from '../explorer';

export type AuditTrailListResponse = {
  data: AuditTrailFile[] | null;
  pagination: {
    meta: Meta;
  };
};

export type AuditTrailFile = {
  action: string;
  timestamp: string;
};

// export async function requestGetIssuerList(
//   page: number,
//   pageSize: number
// ): Promise<IssuerListResponse> {
//   return {
//     data: [
//       {
//         name: 'BLOOCK Admin',
//         DID: 'did:polygonid:polygon:mumbai:2q...5HL15m'
//       },
//       {
//         name: 'BLOOCK Admin',
//         DID: 'did:polygonid:polygon:mumbai:2q...5HL15m'
//       }
//     ],
//     pagination: {
//       meta: {
//         current_page: 1,
//         per_page: 1,
//         from: 1,
//         to: 1,
//         total: 1,
//         last_page: 1
//       }
//     }
//   };
// }

export async function requestGetAuditTrailList(
  page: number,
  pageSize: number
): Promise<AuditTrailListResponse> {
  return {
    data: [
      {
        action: 'Revocation',
        timestamp: '03/10/2023 10:23:00'
      },
      {
        action: 'Revocation',
        timestamp: '03/10/2023 10:23:00'
      }
    ],
    pagination: {
      meta: {
        current_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
        total: 1,
        last_page: 1
      }
    }
  };
}

// export async function requestDetailRecords(anchorId: string): Promise<DetailIssuerResponse> {
//   return await HttpClient.get(`/records/v1/records/${anchorId}`, {
//     headers: {
//       API_VERSION: '2023-11-03'
//     }
//   });
// }
