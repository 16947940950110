import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusMetricsResponse, TypeMetricsResponse } from './requests';

export default createSlice({
  name: 'dashboard',
  initialState: {
    time: 3600,
    interval: 86400,
    consumedPeriod: null,
    accChartInterval: null,
    activeFilter: 'day',
    certificationsStatistics: [] as {
      records: number;
      validations: number;
      date: string;
    }[],
    monthlyCertificationsStatistics: [] as {
      records: number;
      validations: number;
      date: string;
    }[],
    verificationsStatistics: [] as {
      records: number;
      validations: number;
      date: string;
    }[],
    requestsStatistics: [] as {
      date: string;
      success: number;
      error: number;
    }[],
    apiErrorsStatistics: [] as {
      date: string;
      success: number;
      error: number;
    }[],

    loading: false,
    error: '' as string
  },

  reducers: {
    changeFilter: (state, action: PayloadAction<'day' | 'week' | 'month' | 'year'>) => {
      state.activeFilter = action.payload;
      switch (action.payload) {
        case 'day':
          state.time = 3600;
          state.interval = 86400;
          break;
        case 'week':
          state.time = 21600;
          state.interval = 604800;
          break;
        case 'month':
          state.time = 86400;
          state.interval = 2419200;
          break;
        case 'year':
          state.time = 2592000;
          state.interval = 29030400;
          break;
      }
    },
    changeBillingFilter: (state, action: PayloadAction<'monthly' | 'yearly'>) => {
      state.activeFilter = action.payload;
      switch (action.payload) {
        case 'monthly':
          state.time = 86400;
          state.interval = 2419200;

          break;
        case 'yearly':
          state.time = 2592000;
          state.interval = 29030400;

          break;
      }
    },
    fetchStatistics: (state) => {
      state.loading = true;
    },
    setCertificationsStatistics: (state, action: PayloadAction<TypeMetricsResponse>) => {
      state.certificationsStatistics = action.payload;
    },
    setVerificationsStatistics: (state, action: PayloadAction<TypeMetricsResponse>) => {
      state.verificationsStatistics = action.payload;
    },
    setRequestsStatistics: (state, action: PayloadAction<StatusMetricsResponse>) => {
      state.requestsStatistics = action.payload;
    },
    setMontlhyDashboardStatitstics: (state, action: PayloadAction<TypeMetricsResponse>) => {
      state.monthlyCertificationsStatistics = action.payload;
    },
    setApiErrorsStatistics: (state, action: PayloadAction<StatusMetricsResponse>) => {
      state.apiErrorsStatistics = action.payload;
    },
    onFetchStatisticsSuccess: (state) => {
      state.loading = false;
    },
    onFetchStatisticsError: (state) => {
      state.loading = false;
    },
    dashboardStatus: () => {},
    onDashboardStatusSuccess: () => {},
    onDashboardStatusError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    dashboardType: () => {},
    onDashboardTypeSuccess: () => {},
    onDashboardTypeError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    montlhyDashboardType: () => {},
    onMontlhyDashboardTypeSuccess: () => {},
    onMontlhyDashboardTypeError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    }
  }
});
