import { PayloadAction } from '@reduxjs/toolkit';
import { RcFile } from 'antd/lib/upload';
import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { routes } from '../../routes';
import { identityIssuerActions } from '../actions';
import { AppState } from '../reducers';
import {
  IssuerCreateResponse,
  IssuerDetailResponse,
  IssuerListResponse,
  ResponseIdentityClaimUsage,
  ResponseIdentityRevocationUsage,
  requestCreateIssuer,
  requestGetIssuerList,
  requestIdentityClaimUsage,
  requestIdentityRevocationUsage,
  requestIssuerDetail
} from './requests';

function* getIssuerList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const response: IssuerListResponse = yield call(
      requestGetIssuerList,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10
    );
    yield put(identityIssuerActions.onGetIssuerListSuccess(response));
  } catch (e: any) {
    yield put(
      identityIssuerActions.onGetIssuerListError({
        error: e
      })
    );
  }
}

function* issuerDetail(
  action: PayloadAction<{
    did: string;
  }>
) {
  try {
    const response: IssuerDetailResponse = yield call(requestIssuerDetail, action.payload.did);

    yield put(identityIssuerActions.onIssuerDetailSuccess(response));
  } catch (e: any) {
    yield put(
      identityIssuerActions.onIssuerDetailError({
        error: e
      })
    );
  }
}

function* revocationUsage(
  action: PayloadAction<{
    total_count: number;
  }>
) {
  try {
    const response: ResponseIdentityRevocationUsage = yield call(requestIdentityRevocationUsage);

    yield put(identityIssuerActions.onIdentityRevocationSuccess(response));
  } catch (e: any) {
    yield put(
      identityIssuerActions.onIdentityRevocationUsageError({
        error: e
      })
    );
  }
}

function* claimUsage(
  action: PayloadAction<{
    total_count: number;
  }>
) {
  try {
    const response: ResponseIdentityClaimUsage = yield call(requestIdentityClaimUsage);

    yield put(identityIssuerActions.onIdentityClaimSuccess(response));
  } catch (e: any) {
    yield put(
      identityIssuerActions.onIdentityClaimUsageError({
        error: e
      })
    );
  }
}

function* issuerCreate(
  action: PayloadAction<{
    name: string;
    description: string;
    did: string;
    interval: string;
    avatar?: RcFile;
  }>
) {
  const { token } = yield select((state: AppState) => state.auth);

  try {
    const response: IssuerCreateResponse = yield call(
      requestCreateIssuer,
      action.payload.name,
      action.payload.description,
      action.payload.did,
      action.payload.interval,
      token,
      action.payload.avatar
    );

    yield put(identityIssuerActions.onIssuerCreateSuccess(response));
    yield put(push({ pathname: routes.identity.url }));
  } catch (e: any) {
    yield put(
      identityIssuerActions.onIssuerCreateError({
        error: e
      })
    );
  }
}

function* changeIssuerFilter() {
  yield put(identityIssuerActions.getIssuerList({}));
}

export default function* issuersSaga() {
  yield takeLatest(identityIssuerActions.issuerCreate.type, issuerCreate);
  yield takeLatest(identityIssuerActions.getIssuerList.type, getIssuerList);
  yield takeLatest(identityIssuerActions.changeIssuerFilter.type, changeIssuerFilter);
  yield takeLatest(identityIssuerActions.issuerDetail.type, issuerDetail);
  yield takeLatest(identityIssuerActions.identityRevocationUsage.type, revocationUsage);
  yield takeLatest(identityIssuerActions.identityClaimUsage.type, claimUsage);
}
