import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { explorerActions } from '../actions';
import { getAuthUserId } from '../auth';
import { AppState } from '../reducers';
import {
  ResponseAccRecords,
  ResponseAnchorDetails,
  ResponseAnchorNames,
  ResponseExplorer,
  ResponseRecorsUsage,
  requestAccRecords,
  requestAnchorDetails,
  requestAnchorNetworkNames,
  requestExplorer,
  requestRecordsUsage
} from './requests';

function* explorer(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const userId: string = yield select(getAuthUserId);

    const { anchorId, network, showMyAnchors } = yield select((state: AppState) => state.explorer);

    const response: ResponseExplorer = yield call(
      requestExplorer,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10,
      anchorId,
      network,
      showMyAnchors ? userId : null
    );
    yield put(explorerActions.onExplorerSuccess(response));
  } catch (e: any) {
    yield put(
      explorerActions.onExplorerError({
        error: e
      })
    );
  }
}

function* anchorNetworkNames() {
  try {
    const response: ResponseAnchorNames = yield call(requestAnchorNetworkNames);
    yield put(explorerActions.onAnchorNetworkNamesSuccess(response));
  } catch (e: any) {
    yield put(
      explorerActions.onAnchorNetworkNamesError({
        error: e
      })
    );
  }
}

function* anchorDetails(action: PayloadAction<number>) {
  try {
    const response: ResponseAnchorDetails = yield call(requestAnchorDetails, action.payload);
    yield put(explorerActions.onAnchorDetailsSuccess(response));
  } catch (e: any) {
    yield put(
      explorerActions.onAnchorDetailsError({
        error: e
      })
    );
  }
}

function* planRecords() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseAccRecords = yield call(requestAccRecords, userId) || {};
    yield put(explorerActions.onAccRecordsSuccess(response));
  } catch (e: any) {
    yield put(
      explorerActions.onAccRecordsError({
        error: e
      })
    );
  }
}

function* recordsUsage() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseRecorsUsage = yield call(requestRecordsUsage, userId) || {};
    yield put(explorerActions.onRecordsUsageSuccess(response));
  } catch (e: any) {
    yield put(
      explorerActions.onRecordsUsageError({
        error: e
      })
    );
  }
}

function* changeAnchorFilter() {
  yield put(explorerActions.explorer({}));
}

function* toggleShowMyAnchors() {
  yield put(explorerActions.explorer({}));
}

export default function* explorerSaga() {
  yield takeLatest(explorerActions.explorer.type, explorer);
  yield takeLatest(explorerActions.anchorDetails.type, anchorDetails);
  yield takeLatest(explorerActions.anchorNetworkNames.type, anchorNetworkNames);
  yield takeLatest(explorerActions.changeAnchorFilter.type, changeAnchorFilter);
  yield takeLatest(explorerActions.accRecords.type, planRecords);
  yield takeLatest(explorerActions.accRecords.type, recordsUsage);
  yield takeLatest(explorerActions.toggleShowMyAnchors.type, toggleShowMyAnchors);
}
