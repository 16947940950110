import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../redux/actions';
import { DefaultModal, IDefaultModal } from './default';

export type IInfoModal = {
  headerLabel: string;
  dismiss?: boolean;
  dismissLabel?: string;
  successLabel: string;
  message: string;
  callback: () => void;
};

export const InfoModal: React.FC<IInfoModal> = ({
  message,
  callback = () => {},
  dismiss = true,
  ...rest
}) => {
  const { t: t } = useTranslation();

  const dispatch = useDispatch();

  const defaultModalProps: IDefaultModal = {
    headerLabel: rest.headerLabel,
    dismiss: dismiss,
    dismissLabel: rest.dismissLabel ? rest.dismissLabel : t('cancel'),
    successLabel: rest.successLabel,
    successCallback: () => {
      callback();
    },
    dismissCallback: () => {
      dispatch(modalActions.close());
    }
  };

  return (
    <DefaultModal {...defaultModalProps}>
      <span className="break-words">{message}</span>
    </DefaultModal>
  );
};
