import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  ResponseAvailabilityStorageUsage,
  ResponseAvailabilityTransferUsage,
  ResponseInfrastructureRequestUsage,
  ResponseKeysTransactionsUsage,
  ResponseKeysUsage,
  ResponseRecordUsage
} from './requests';

export type Usage = {
  amountConsumed: number;
  amountIncluded: number;
  limit: number;
  limitPrice: number;
  unitPrice: number;
  updatedAt: Date;
  details: {
    name: string;
    amount: number;
  }[];
};

export type UpdateUsage = {
  limitType: 'unlimited' | 'limited';
  limitPrice: number;
};

export default createSlice({
  name: 'usage',
  initialState: {
    recordUsage: {} as Usage | null,
    infrastructureRequestUsage: {} as Usage | null,
    availabilityHostedStorageUsage: {} as Usage | null,
    availabilityHostedTransferUsage: {} as Usage | null,
    availabilityIPFSStorageUsage: {} as Usage | null,
    availabilityIPFSTransferUsage: {} as Usage | null,
    keysUsage: {} as Usage | null,
    keysTransactionsUsage: {} as Usage | null,
    loading: false,
    error: '' as string
  },

  reducers: {
    recordUsage: (state) => {
      state.loading = true;
    },
    onRecordUsageSuccess: (state, action: PayloadAction<ResponseRecordUsage>) => {
      state.loading = false;
      state.recordUsage = {
        amountConsumed: action.payload.record_counter,
        amountIncluded: action.payload.amount_included,
        limit: action.payload.record_limit,
        limitPrice: action.payload.record_limit_price,
        unitPrice: action.payload.unit_price,
        updatedAt: new Date(action.payload.last_updated),
        details: []
      };
    },
    onRecordUsageError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    updateRecordUsageLimit: (state, action: PayloadAction<UpdateUsage>) => {
      state.loading = true;
    },
    onUpdateRecordUsageLimitSuccess: (state) => {
      state.loading = false;
    },
    onUpdateRecordUsageLimitError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while updating Storage Tranfer limit';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    infrastructureRequestUsage: (state) => {
      state.loading = true;
    },
    onInfrastructureRequestUsageSuccess: (
      state,
      action: PayloadAction<ResponseInfrastructureRequestUsage>
    ) => {
      state.loading = false;

      const amountConsumed =
        action.payload.networks?.reduce((prev, curr) => {
          return prev + curr.requests_counter;
        }, 0) || 0;

      const updatedAt =
        action.payload.networks?.reduce((prev, curr) => {
          if (moment(prev).isBefore(moment(new Date(curr.last_updated)))) {
            return new Date(curr.last_updated);
          }
          return prev;
        }, new Date()) || new Date();

      state.infrastructureRequestUsage = {
        amountConsumed: amountConsumed,
        updatedAt: updatedAt,
        amountIncluded: action.payload.amount_included,
        limit: action.payload.request_limit,
        limitPrice: action.payload.request_limit_price,
        unitPrice: action.payload.unit_price,
        details: action.payload.networks?.map((network) => {
          return {
            name: network.network_name,
            amount: network.requests_counter
          };
        })
      };
    },
    onInfrastructureRequestUsageError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    updateInfrastructureRequestUsageLimit: (state, action: PayloadAction<UpdateUsage>) => {
      state.loading = true;
    },
    onUpdateInfrastructureRequestUsageLimitSuccess: (state) => {
      state.loading = false;
    },
    onUpdateInfrastructureRequestUsageLimitError: (
      state,
      action: PayloadAction<{ error: number }>
    ) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while updating Storage Tranfer limit';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    availabilityStorageUsage: (state) => {
      state.loading = true;
    },
    onAvailabilityStorageUsageSuccess: (
      state,
      action: PayloadAction<ResponseAvailabilityStorageUsage>
    ) => {
      state.loading = false;

      const hostedUsage = action.payload.storage?.filter(
        (type) => type.storage_type === 'hosted_storage'
      )[0].stored_bytes;
      state.availabilityHostedStorageUsage = {
        amountConsumed: hostedUsage,
        amountIncluded: action.payload.amount_included_bytes,
        limit: action.payload.storage_limit_bytes,
        limitPrice: action.payload.storage_limit_price,
        unitPrice: action.payload.unit_price,
        updatedAt: new Date(action.payload.last_updated),
        details: []
      };

      const ipfsUsage = action.payload.storage?.filter(
        (type) => type.storage_type === 'ipfs_storage'
      )[0].stored_bytes;
      state.availabilityIPFSStorageUsage = {
        amountConsumed: ipfsUsage,
        amountIncluded: action.payload.amount_included_bytes,
        limit: action.payload.storage_limit_bytes,
        limitPrice: action.payload.storage_limit_price,
        unitPrice: action.payload.unit_price,
        updatedAt: new Date(action.payload.last_updated),
        details: []
      };
    },
    onAvailabilityStorageUsageError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    updateAvailabilityStorageUsageLimit: (state, action: PayloadAction<UpdateUsage>) => {
      state.loading = true;
    },
    onUpdateAvailabilityStorageUsageLimitSuccess: (state) => {
      state.loading = false;
    },
    onUpdateAvailabilityStorageUsageLimitError: (
      state,
      action: PayloadAction<{ error: number }>
    ) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while updating Storage Tranfer limit';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    availabilityTransferUsage: (state) => {
      state.loading = true;
    },
    onAvailabilityTransferUsageSuccess: (
      state,
      action: PayloadAction<ResponseAvailabilityTransferUsage>
    ) => {
      state.loading = false;
      const hostedUsage = action.payload.transfer?.filter(
        (type) => type.storage_type === 'hosted_storage'
      )[0].transferred_bytes;
      state.availabilityHostedTransferUsage = {
        amountConsumed: hostedUsage,
        amountIncluded: action.payload.amount_included_bytes,
        limit: action.payload.transfer_limit_bytes,
        limitPrice: action.payload.transfer_limit_price,
        unitPrice: action.payload.unit_price,
        updatedAt: new Date(action.payload.last_updated),
        details: []
      };

      const ipfsUsage = action.payload.transfer?.filter(
        (type) => type.storage_type === 'ipfs_storage'
      )[0].transferred_bytes;
      state.availabilityIPFSTransferUsage = {
        amountConsumed: ipfsUsage,
        amountIncluded: action.payload.amount_included_bytes,
        limit: action.payload.transfer_limit_bytes,
        limitPrice: action.payload.transfer_limit_price,
        unitPrice: action.payload.unit_price,
        updatedAt: new Date(action.payload.last_updated),
        details: []
      };
    },
    onAvailabilityTransferUsageError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    updateAvailabilityTransferUsageLimit: (state, action: PayloadAction<UpdateUsage>) => {
      state.loading = true;
    },
    onUpdateAvailabilityTransferUsageLimitSuccess: (state) => {
      state.loading = false;
    },
    onUpdateAvailabilityTransferUsageLimitError: (
      state,
      action: PayloadAction<{ error: number }>
    ) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while updating Storage Tranfer limit';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    keysUsage: (state) => {
      state.loading = true;
    },
    onKeysUsageSuccess: (state, action: PayloadAction<ResponseKeysUsage>) => {
      state.loading = false;
      const softwareKeysUsage = action.payload.filter(
        (type) => type.key_type === 'keys_software'
      )[0];
      state.keysUsage = {
        amountConsumed: softwareKeysUsage.key_counter,
        amountIncluded: softwareKeysUsage.amount_included,
        limit: softwareKeysUsage.key_limit,
        limitPrice: softwareKeysUsage.key_limit_price,
        unitPrice: softwareKeysUsage.unit_price,
        updatedAt: new Date(softwareKeysUsage.last_updated),
        details: []
      };
    },
    onKeysUsageError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    updateSoftwareKeysUsageLimit: (state, action: PayloadAction<UpdateUsage>) => {
      state.loading = true;
    },
    onUpdateSoftwareKeysUsageLimitSuccess: (state) => {
      state.loading = false;
    },
    onUpdateSoftwareKeysUsageLimitError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while updating keys limit';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    updateHsmKeysUsageLimit: (state, action: PayloadAction<UpdateUsage>) => {
      state.loading = true;
    },
    onUpdateHsmKeysUsageLimitSuccess: (state) => {
      state.loading = false;
    },
    onUpdateHsmKeysUsageLimitError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while updating Storage Tranfer limit';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    keysTransactionsUsage: (state) => {
      state.loading = true;
    },
    onKeysTransactionsUsageSuccess: (
      state,
      action: PayloadAction<ResponseKeysTransactionsUsage>
    ) => {
      state.loading = false;
      state.keysTransactionsUsage = {
        amountConsumed: action.payload.transactions,
        amountIncluded: action.payload.amount_included,
        limit: action.payload.transactions_limit,
        limitPrice: action.payload.transactions_limit_price,
        unitPrice: action.payload.unit_price,
        updatedAt: new Date(action.payload.last_updated),
        details: []
      };
    },
    onKeysTransactionsUsageError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    updateKeysTransactionsUsageLimit: (state, action: PayloadAction<UpdateUsage>) => {
      state.loading = true;
    },
    onUpdateKeysTransactionsUsageLimitSuccess: (state) => {
      state.loading = false;
    },
    onUpdateKeysTransactionsUsageLimitError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while updating keys transactions limit';
          break;
        default:
          state.error = 'Unknown error';
      }
    }
  }
});
