import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { certifierActions } from '../actions';
import { AppState } from '../reducers';

import {
  AccessControlType,
  BloockClient,
  ManagedCertificate,
  ManagedKey,
  Proof
} from '@bloock/sdk';
import { convertAnchorNetworkToNetwork } from '../../utils/anchor-network-to-network';
import { getAuthUserId } from '../auth';
import {
  CertifierForm,
  GetCertificationsResponse,
  IntegrityNetwork,
  Process,
  SendCertificationResponse,
  requestGetCertificationDetail,
  requestGetCertificationsList,
  requestGetProof,
  requestLoadManagedCertificate,
  requestLoadManagedKey,
  requestSendDataToCertify
} from './requests';

function* certificationsList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const userId: string = yield select(getAuthUserId);

    const response: GetCertificationsResponse = yield call(
      requestGetCertificationsList,
      userId,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10
    );

    yield put(certifierActions.onCertificationsListSuccess(response));
  } catch (e: any) {
    yield put(certifierActions.onCertificationsListError({ error: e }));
  }
}

function* certificationDetail(action: PayloadAction<{ id: string }>) {
  let response: Process;
  try {
    response = yield call(requestGetCertificationDetail, action.payload.id);
  } catch (e: any) {
    yield put(certifierActions.onCertificationDetailError({ error: e }));
    return;
  }

  if (response.integrity?.enabled) {
    const proof: Proof = yield call(requestGetProof, response.hash);
    const { token } = yield select((state: AppState) => state.auth);

    const bloockClient = new BloockClient(token);
    const root: string = yield bloockClient.IntegrityClient.verifyProof(proof);
    const networks: IntegrityNetwork[] = [];
    for (const network of proof.anchor.networks) {
      console.log(network);
      const timestamp: number = yield bloockClient.IntegrityClient.validateRoot(
        network.root || root,
        convertAnchorNetworkToNetwork(network)
      );

      networks.push({
        name: network.name,
        status: network.state,
        timestamp: timestamp,
        txHash: network.txHash,
        anchor: {
          id: proof.anchor.anchorID,
          root: network.root || root,
          status: proof.anchor.status
        },
        proof: {
          root: proof.anchor.root,
          leaves: proof.leaves,
          nodes: proof.nodes,
          bitmap: proof.bitmap,
          depth: proof.depth
        }
      });
    }

    response.integrity.networks = networks;
  }
  yield put(certifierActions.onCertificationDetailSuccess(response));
}

function* sendDataToCertify(action: PayloadAction<CertifierForm>) {
  const { token } = yield select((state: AppState) => state.auth);
  const { selectedFiles } = yield select((state: AppState) => state.certifier);
  let haveAccessControl = false;

  try {
    if (action.payload.authenticity.managedKey || action.payload.authenticity.managedCertificate) {
      if (action.payload.authenticity.managedKey) {
        const loadedKey: ManagedKey = yield call(
          requestLoadManagedKey,
          action.payload.authenticity.managedKey,
          token
        );

        if (
          loadedKey.accessControlType !== AccessControlType.NONE &&
          !action.payload.authenticity.accessCode
        ) {
          haveAccessControl = true;
          yield put(certifierActions.upadateAuthAccessControlType(loadedKey.accessControlType));
        }
      } else if (action.payload.authenticity.managedCertificate) {
        const loadedCertificate: ManagedCertificate = yield call(
          requestLoadManagedCertificate,
          action.payload.authenticity.managedCertificate,
          token
        );
        if (
          loadedCertificate.accessControlType !== AccessControlType.NONE &&
          !action.payload.authenticity.accessCode
        ) {
          haveAccessControl = true;

          yield put(
            certifierActions.upadateAuthAccessControlType(loadedCertificate.accessControlType)
          );
        }
      }
    }
    if (action.payload.encryption.managedKey || action.payload.encryption.managedCertificate) {
      if (action.payload.encryption.managedKey) {
        const loadedKey: ManagedKey = yield call(
          requestLoadManagedKey,
          action.payload.encryption.managedKey,
          token
        );

        if (
          loadedKey.accessControlType !== AccessControlType.NONE &&
          !action.payload.encryption.accessCode
        ) {
          haveAccessControl = true;

          yield put(
            certifierActions.upadateEncryptionAccessControlType(loadedKey.accessControlType)
          );
        }
      } else if (action.payload.encryption.managedCertificate) {
        const loadedCertificate: ManagedCertificate = yield call(
          requestLoadManagedCertificate,
          action.payload.encryption.managedCertificate,
          token
        );
        if (
          loadedCertificate.accessControlType !== AccessControlType.NONE &&
          !action.payload.encryption.accessCode
        ) {
          haveAccessControl = true;

          yield put(
            certifierActions.upadateEncryptionAccessControlType(loadedCertificate.accessControlType)
          );
        }
      }
    }
  } catch {}

  if (haveAccessControl) {
    return;
  }

  for (let i = 0; i < selectedFiles.length; ++i) {
    try {
      const _: SendCertificationResponse = yield call(
        requestSendDataToCertify,
        token,
        selectedFiles[i],
        action.payload
      );
    } catch (e: any) {
      yield put(
        certifierActions.onSendDataToCertifyError({
          error: e
        })
      );
      return;
    }
  }

  yield put(certifierActions.onSendDataToCertifySuccess());
  yield put(certifierActions.closeCertifierSteps());
  yield put(certifierActions.resetSelectedFiles());
  yield put(certifierActions.certificationsList({}));
  haveAccessControl = false;
}

export default function* certifyDataSaga() {
  yield takeLatest(certifierActions.certificationsList.type, certificationsList);
  yield takeLatest(certifierActions.certificationDetail.type, certificationDetail);
  yield takeLatest(certifierActions.sendDataToCertify.type, sendDataToCertify);
}
