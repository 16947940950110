import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { routes } from '../../routes';
import { authActions, userProductActions } from '../actions';
import { getAuthUserId, getToken } from '../auth';
import {
  ResponseSubscriptionStatus,
  ResponseUpdateProduct,
  ResponseUserProduct,
  requestCancelSubscription,
  requestReactivateSubscription,
  requestSubscriptionStatus,
  requestUpdateProduct,
  requestUserProduct
} from './requests';

function* getUserProduct() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseUserProduct = yield call(requestUserProduct, userId);
    yield put(userProductActions.onGetUserProductSuccess(response));
  } catch (e: any) {
    yield put(
      userProductActions.onGetUserProductError({
        error: e
      })
    );
  }
}

function* updateProduct(
  action: PayloadAction<{ product_base_id: string[]; product_optional_id: string[] }>
) {
  const token: string = yield select(getToken);
  try {
    const { product_base_id, product_optional_id } = action.payload;
    const userId: string = yield select(getAuthUserId);
    const updatePlan: ResponseUpdateProduct = yield call(
      requestUpdateProduct as any,
      userId,
      product_base_id,
      product_optional_id
    );

    yield delay(2000);

    yield put(authActions.refreshToken(token));
    yield put(userProductActions.onUpdateProductSuccess(updatePlan));
    yield put(userProductActions.userProduct());
    yield put(push(routes.plansAndUsage.url));

    const response: ResponseUserProduct = yield call(requestUserProduct, userId);

    yield put(authActions.refreshToken(token));

    yield put(userProductActions.onGetUserProductSuccess(response));
  } catch (e: any) {
    yield put(
      userProductActions.onUpdateProductError({
        error: e
      })
    );
  }
}

function* cancelSubscription(action: PayloadAction<string>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const token: string = yield select(getToken);

    const response: string = yield call(requestCancelSubscription, userId);

    yield put(userProductActions.onCancelSubscriptionSuccess(response as any));
    yield put(authActions.refreshToken(token));
    yield put(userProductActions.userProduct());
    yield put(push(routes.plansAndUsage.url));
  } catch (e: any) {
    yield put(
      userProductActions.onGetUserProductError({
        error: e
      })
    );
  }
}

function* reactivateSubscription(action: PayloadAction<string>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const token: string = yield select(getToken);

    const response: string = yield call(requestReactivateSubscription, userId);

    yield delay(2000);

    yield put(authActions.refreshToken(token));
    yield put(userProductActions.onReactivateSubscriptionSuccess(response as any));
    yield put(userProductActions.userProduct());
    yield put(push(routes.plansAndUsage.url));
  } catch (e: any) {
    yield put(
      userProductActions.onGetUserProductError({
        error: e
      })
    );
  }
}

function* getSubscriptionStatus() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseSubscriptionStatus = yield call(requestSubscriptionStatus, userId);
    yield put(userProductActions.onSubscriptionStatusSuccess(response));
  } catch (e: any) {
    yield put(
      userProductActions.onSubscriptionStatusError({
        error: e
      })
    );
  }
}

export default function* userProductSaga() {
  yield takeLatest(userProductActions.userProduct.type, getUserProduct);
  yield takeLatest(userProductActions.updateProduct.type, updateProduct);
  yield takeLatest(userProductActions.cancelSubscription.type, cancelSubscription);
  yield takeLatest(userProductActions.reactivateSubscription.type, reactivateSubscription);
  yield takeLatest(userProductActions.subscriptionStatus.type, getSubscriptionStatus);
}
