import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { HttpClient } from '../http/client';
import { authActions } from './actions';
import createRootReducer, { AppState } from './reducers';
import rootSaga from './sagas';

// export default function configureStore(preloadedState?: AppState) {
//   const sagaMiddleware = createSagaMiddleware();

//   const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
//     history: createBrowserHistory()
//   });

//   const middlewares = [sagaMiddleware, routerMiddleware];
//   if (devMode) {
//     middlewares.push(logger);
//   }

//   const store = createStore(
//     createRootReducer(routerReducer), // root reducer with router reducer
//     preloadedState,
//     compose(applyMiddleware(...middlewares))
//   );

//   sagaMiddleware.run(rootSaga);

//   HttpClient.setResponseInterceptor('unauthorized', (response) => {
//     if (
//       response &&
//       response.status === 401 &&
//       response.config.headers &&
//       !!response.config.headers['Authorization']
//     ) {
//       store.dispatch(authActions.logout());
//     }

//     return response;
//   });

//   return store;
// }

const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});

const middlewares = [sagaMiddleware, routerMiddleware];

export const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: (_) => middlewares
});

sagaMiddleware.run(rootSaga);

HttpClient.setResponseInterceptor('unauthorized', (response) => {
  if (
    response &&
    response.status === 401 &&
    response.config.headers &&
    !!response.config.headers['Authorization']
  ) {
    store.dispatch(authActions.logout());
  }

  return response;
});

export const history = createReduxHistory(store);

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
