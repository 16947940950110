import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  filterSubscriptionProducts,
  mapToSubscriptionProducts,
  SubscriptionProduct
} from '../metadata';
import { AppState } from '../reducers';
import {
  ICoupon,
  ResponseSubscriptionStatus,
  ResponseUpdateProduct,
  ResponseUserProduct
} from './requests';

export default createSlice({
  name: 'userProduct',
  initialState: {
    coupon: null as ICoupon | null,
    cancel_at: 0 as number,
    userProducts: [] as SubscriptionProduct[],
    productId: '' as string,
    loading: false,
    error: '' as string,
    errorUpdate: 0 as number,
    updateLoading: false,
    updateError: null as string | null,
    isEditingPlan: false,
    success: false,
    subscriptionId: '' as string,
    clientSecret: null as string | null,
    paymentSuccess: false,
    cancelLoading: false,
    isSubscriptionCancelled: false,
    reactivateLoading: false,
    subscriptionStatus: {} as ResponseSubscriptionStatus
  },

  reducers: {
    userProduct: (state) => {
      state.loading = true;
    },
    onGetUserProductSuccess: (state, action: PayloadAction<ResponseUserProduct>) => {
      const products = mapToSubscriptionProducts(action.payload?.products);
      const interval = products.length > 0 ? products[0].interval : 'monthly';

      state.userProducts = mapToSubscriptionProducts(
        filterSubscriptionProducts(action.payload?.products, interval)
      );

      state.coupon = action.payload?.coupon !== undefined ? action.payload?.coupon : null;
      state.cancel_at = action.payload?.cancel_at;
      if (state.cancel_at !== 0) {
        state.isSubscriptionCancelled = true;
      } else if (state.cancel_at === 0) {
        state.isSubscriptionCancelled = false;
      }
      state.loading = false;
    },

    onGetUserProductError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    toggleIsEditingProduct: (state) => {
      state.isEditingPlan = !state.isEditingPlan;
    },

    updateProduct: (state, _: PayloadAction<{ product_base_id: string[] }>) => {
      state.updateLoading = true;
    },
    onUpdateProductSuccess: (state, action: PayloadAction<ResponseUpdateProduct>) => {
      state.clientSecret = action.payload?.client_secret ? action.payload.client_secret : null;
      state.subscriptionId = action.payload?.subscription_id;
      state.paymentSuccess = true;
      state.isEditingPlan = false;
      state.updateLoading = false;
      toast.success('Your plan was successfully updated', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onUpdateProductError: (state, action: PayloadAction<{ error: number }>) => {
      state.updateLoading = false;
      switch (action.payload.error) {
        case 500:
          state.updateError = 'Error while saving plan information';
          break;
        default:
          state.updateError = 'Error while saving data, please try again';
      }
      toast.error(state.updateError, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    resetUpdateError: (state) => {
      state.updateError = null;
    },
    cancelSubscription: (state) => {
      state.cancelLoading = true;
    },
    onCancelSubscriptionSuccess: (state, _: PayloadAction<{ userId: string }>) => {
      state.cancelLoading = false;

      /*  toast.success('Your subscription has been cancelled', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      }); */
    },
    onCancelSubscriptionError: (state, action: PayloadAction<{ error: number }>) => {
      state.cancelLoading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while cancelling subscription ';
          break;
        default:
          state.error = 'Error while cancelling subscription, please try again';
      }
    },
    reactivateSubscription: (state) => {
      state.reactivateLoading = true;
    },
    onReactivateSubscriptionSuccess: (state, _: PayloadAction<{ userId: string }>) => {
      state.reactivateLoading = false;

      toast.success('Your subscription has been reactivated', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onReactivateSubscriptionError: (state, action: PayloadAction<{ error: number }>) => {
      state.reactivateLoading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while reactivating subscription ';
          break;
        default:
          state.error = 'Error while reactivating subscription, please try again';
      }
    },
    subscriptionStatus: (state) => {
      state.loading = true;
    },
    onSubscriptionStatusSuccess: (state, action: PayloadAction<ResponseSubscriptionStatus>) => {
      state.loading = false;
      state.subscriptionStatus = action.payload;
    },
    onSubscriptionStatusError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    }
  }
});

export const getLicense = (state: AppState): string | undefined =>
  state.userProduct.userProducts[0].license;
