import { Button as AntdButton, Spin } from 'antd';
import React from 'react';

export type ButtonProps = {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  negative?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  icon?: JSX.Element;
};

const Button: React.FC<ButtonProps> = ({
  className = '',
  type = 'button',
  negative = true,
  disabled = false,
  loading = false,
  children,
  icon,
  ...rest
}) => {
  return (
    <AntdButton
      className={`text-center font-medium rounded disabled:opacity-50 ${className}`}
      disabled={loading === true ? true : disabled}
      style={{
        height: '40px',
        borderRadius: '5px',
        backgroundColor: negative ? 'var(--secondary-color)' : '#fbfbfb',
        fontSize: 'Medium' ? '16px' : '14px',
        border: negative ? 'none' : '1px solid var(--tertiary-text-color)',
        color: negative ? 'var(--secondary-text-color)' : 'var(--primary-text-color)',
        padding: '0 20px'
      }}
      icon={icon}
      htmlType={type}
      onClick={() => {
        if (!loading && !!rest.onClick) {
          rest.onClick();
        }
      }}
      {...rest}
    >
      {loading ? (
        <div className="spinner">
          <Spin size="small" className={!negative ? 'negative' : ''} />
        </div>
      ) : (
        children
      )}
    </AntdButton>
  );
};

export default Button;
