import { BloockClient } from '@bloock/sdk';
import { HttpClient } from '../../http/client';
import { Meta } from '../explorer';

export type ValidationQueriesListResponse = {
  verifications: ValidationQuery[] | null;
  pagination: {
    meta: Meta;
  };
};

export type ValidationQuery = {
  name: string;
  description: string;
  query_id: string;
  user_id: string;
  query: Record<string, any>;
  created_at: string;
};

export async function requestGetValidationQueriesList(
  page: number,
  pageSize: number
): Promise<ValidationQueriesListResponse> {
  const url = `/identityV2/v1/verifications?page=${page}&per_page=${pageSize}`;
  return await HttpClient.get(url);
}

export async function requestCreateQuery(
  name: string,
  description: string,
  query: Record<string, any>
): Promise<void> {
  const url = `/identityV2/v1/verifications`;
  return await HttpClient.post(url, {
    name,
    description,
    query
  });
}

export async function requestQueryVerificationQr(
  query: string,
  token: string,
  env: string,
  apiHost?: string
): Promise<string> {
  const bloockClient = new BloockClient(
    token,
    apiHost ? apiHost : process.env.REACT_APP_IDENTITY_MANAGED_API
  );
  const identityClient = bloockClient.IdentityClient;

  const verification = await identityClient.createVerification(query);

  return verification.verificationRequest;
}
