import { UserData } from '.';
import { HttpClient } from '../../http/client';

export async function requestRefreshToken(token: string): Promise<{ token: string }> {
  const body = token;
  return await HttpClient.post(`/credentials/v1/refresh`, { token: body });
}

export async function requestUserData(userId: string): Promise<UserData> {
  return await HttpClient.get(`/users/v1/users/${userId}`);
}
