import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { routes } from '../../routes';
import { identityValidationQueriesActions } from '../actions';
import { AppState } from '../reducers';
import {
  ValidationQueriesListResponse,
  requestCreateQuery,
  requestGetValidationQueriesList,
  requestQueryVerificationQr
} from './requests';

function* getValidationQueriesList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const response: ValidationQueriesListResponse = yield call(
      requestGetValidationQueriesList,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10
    );
    yield put(identityValidationQueriesActions.onGetValidationQueriesListSuccess(response));
  } catch (e: any) {
    yield put(
      identityValidationQueriesActions.onGetValidationQueriesListError({
        error: e
      })
    );
  }
}

function* createQuery(
  action: PayloadAction<{
    name: string;
    description: string;
    query: Record<string, any>;
  }>
) {
  try {
    const _: void = yield call(
      requestCreateQuery,
      action.payload.name,
      action.payload.description,
      action.payload.query
    );
    yield put(identityValidationQueriesActions.onCreateQuerySuccess());
    yield put(push({ pathname: routes.identity.url }));
  } catch (e: any) {
    yield put(
      identityValidationQueriesActions.onCreateQueryError({
        error: e
      })
    );
  }
}

function* validationQueryQR(action: PayloadAction<{ query: string; apiHost?: string }>) {
  const { token } = yield select((state: AppState) => state.auth);
  const env = 'live';
  try {
    const response: string = yield call(
      requestQueryVerificationQr,
      action.payload.query,
      token,
      env,
      action.payload.apiHost
    );
    yield put(identityValidationQueriesActions.onGetValidationQueriesQrSuccess(response));
  } catch (e: any) {
    yield put(
      identityValidationQueriesActions.onGetValidationQueriesQrError({
        error: e
      })
    );
  }
}

export default function* validationQuerySaga() {
  yield takeLatest(
    identityValidationQueriesActions.getValidationQueriesList.type,
    getValidationQueriesList
  );
  yield takeLatest(identityValidationQueriesActions.createQuery.type, createQuery);
  yield takeLatest(identityValidationQueriesActions.getValidationQueriesQr.type, validationQueryQR);
}
