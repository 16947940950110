import { HttpClient } from '../../http/client';

export type TypeMetricsResponse = {
  date: string;
  records: number;
  validations: number;
}[];
export type StatusMetricsResponse = {
  date: string;
  success: number;
  error: number;
}[];

export async function requestStatusDashboard(
  userId: string,
  time: number,
  interval: number
): Promise<StatusMetricsResponse> {
  return await HttpClient.get(
    `/events/v1/activities/statistics/status?user=${userId}&granularity=${time}&window=${interval}`
  );
}

export async function requestTypeDashboard(
  userId: string,
  time: number,
  interval: number
): Promise<TypeMetricsResponse> {
  return await HttpClient.get(
    `/events/v1/activities/statistics/type?user=${userId}&granularity=${time}&window=${interval}`
  );
}

export async function requestMonthlyTypeDashboard(
  userId: string,
  consumedPeriod: number,
  accChartInterval: number
): Promise<TypeMetricsResponse> {
  return await HttpClient.get(
    `/events/v1/activities/statistics/type?user=${userId}&granularity=${consumedPeriod}&window=${accChartInterval}`
  );
}
