import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiKeysActions, modalActions } from '../actions';
import { getAuthUserId } from '../auth';
import {
  ResponseCreateApiKey,
  ResponseListApiKey,
  requestCreateApiKey,
  requestDeleteApiKey,
  requestListApiKey
} from './requests';

function* listApiKeysLive() {
  try {
    const userId: string = yield select(getAuthUserId);

    const response: ResponseListApiKey = yield call(requestListApiKey, userId);
    yield put(apiKeysActions.onApiKeysProdSuccess(response));
  } catch (e: any) {
    yield put(
      apiKeysActions.onApiKeysProdError({
        error: e
      })
    );
  }
}

function* createLiveApiKey(
  action: PayloadAction<{
    expiration: Date;
    name: string;
    scopes: string[];
  }>
) {
  try {
    const { expiration, name, scopes } = action.payload;
    const userId: string = yield select(getAuthUserId);
    const response: ResponseCreateApiKey = yield call(
      requestCreateApiKey,
      userId,
      name,
      expiration,
      scopes
    );

    yield put(apiKeysActions.onCreateProdApikeysSuccess(response.key));
  } catch (e: any) {
    yield put(
      apiKeysActions.onCreateProdApikeysError({
        error: e
      })
    );
  }
}

function* deleteApiKey(action: PayloadAction<string>) {
  try {
    const userId: string = yield select(getAuthUserId);
    yield call(requestDeleteApiKey, action.payload);
    yield put(apiKeysActions.onApiKeyDeleteSuccess());
    yield put(apiKeysActions.listApiKeyLive());
    yield put(modalActions.close());
  } catch (e: any) {
    yield put(
      apiKeysActions.onApiKeyDeleteError({
        error: e
      })
    );
  }
}

export default function* apiKeysSaga() {
  yield takeLatest(apiKeysActions.listApiKeyLive.type, listApiKeysLive);
  yield takeLatest(apiKeysActions.deleteApiKey.type, deleteApiKey);
  yield takeLatest(apiKeysActions.createApiKeyLive.type, createLiveApiKey);
}
