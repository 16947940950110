import moment from 'moment';
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { dashboardActions } from '../actions';
import { getAuthUserId } from '../auth';
import { requestAccRecords } from '../explorer/requests';
import { AppState } from '../reducers';
import {
  StatusMetricsResponse,
  TypeMetricsResponse,
  requestMonthlyTypeDashboard,
  requestStatusDashboard,
  requestTypeDashboard
} from './requests';

function* changeFilter() {
  yield put(dashboardActions.fetchStatistics());
}

function* changeBillingFilter() {
  yield put(dashboardActions.fetchStatistics());
}

function* fetchStatistics(): any {
  yield put(dashboardActions.dashboardStatus());
  yield put(dashboardActions.dashboardType());

  const [, statusError, typeError] = yield race([
    all([
      take(dashboardActions.onDashboardStatusSuccess),
      take(dashboardActions.onDashboardTypeSuccess)
    ]),
    take(dashboardActions.onDashboardStatusError),
    take(dashboardActions.onDashboardTypeError)
  ]);

  if (statusError || typeError) {
    yield put(dashboardActions.onFetchStatisticsError());
  } else {
    yield put(dashboardActions.onFetchStatisticsSuccess());
  }
}

function* dashboardStatus() {
  try {
    const userId: string = yield select(getAuthUserId);
    const { time, interval } = yield select((state: AppState) => state.dashboard);

    const response: StatusMetricsResponse = yield call(
      requestStatusDashboard,
      userId,
      time,
      interval
    );

    yield put(dashboardActions.setRequestsStatistics(response));
    yield put(dashboardActions.setApiErrorsStatistics(response));
    yield put(dashboardActions.onDashboardStatusSuccess());
  } catch (e: any) {
    yield put(
      dashboardActions.onDashboardStatusError({
        error: e
      })
    );
  }
}

function* dashboardType() {
  try {
    const userId: string = yield select(getAuthUserId);
    const { time, interval } = yield select((state: AppState) => state.dashboard);

    const response: TypeMetricsResponse = yield call(requestTypeDashboard, userId, time, interval);

    yield put(dashboardActions.setCertificationsStatistics(response));
    yield put(dashboardActions.setVerificationsStatistics(response));

    yield put(dashboardActions.onDashboardTypeSuccess());
  } catch (e: any) {
    yield put(
      dashboardActions.onDashboardTypeError({
        error: e
      })
    );
  }
}

function* monthlyDashboardType() {
  try {
    const userId: string = yield select(getAuthUserId);
    const { StartCurrentPeriod, BillingInterval } = yield call(requestAccRecords, userId);

    const accChartInterval = BillingInterval == 'month' ? 345600 : 2628000;
    const given = StartCurrentPeriod && moment(StartCurrentPeriod, 'YYYY-MM-DD');
    const current = moment().startOf('minutes');

    const consumedPeriod: number =
      current && given && moment.duration(current.diff(given)).asSeconds();

    const response: TypeMetricsResponse = yield call(
      requestMonthlyTypeDashboard,
      userId,
      consumedPeriod,
      accChartInterval
    );

    yield put(dashboardActions.setMontlhyDashboardStatitstics(response));
    yield put(dashboardActions.onMontlhyDashboardTypeSuccess());
  } catch (e: any) {
    yield put(
      dashboardActions.onMontlhyDashboardTypeError({
        error: e
      })
    );
  }
}

export default function* dashboardSaga() {
  yield takeLatest(dashboardActions.dashboardStatus.type, dashboardStatus);
  yield takeLatest(dashboardActions.dashboardType.type, dashboardType);
  yield takeLatest(dashboardActions.setCertificationsStatistics.type, monthlyDashboardType);

  yield takeLatest(dashboardActions.fetchStatistics.type, fetchStatistics);
  yield takeLatest(dashboardActions.changeFilter.type, changeFilter);
  yield takeLatest(dashboardActions.changeBillingFilter.type, changeBillingFilter);
}
