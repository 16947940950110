import { BloockClient, Managed, TotpAccessControlReceipt } from '@bloock/sdk';
import { AccessControlInfo, EditAccessControl } from '.';
import { HttpClient } from '../../http/client';

export type ResetAccessControl = {
  certificateId?: string;
  email?: string;
  secret?: string;
  code?: number;
};

export async function requestAddTotpAccessControl(
  certificateId: string,
  token: string
): Promise<TotpAccessControlReceipt> {
  const bloockClient = new BloockClient(token, '');

  const keyClient = bloockClient.KeyClient;

  const key = await keyClient.loadManagedKey(certificateId);

  return await keyClient.setupTotpAccessControl(new Managed(key));
}

export async function requestAddSecretAccessControl(
  certificateId: string,
  password: string,
  email: string,
  token: string
): Promise<void> {
  const bloockClient = new BloockClient(token, '');

  const keyClient = bloockClient.KeyClient;

  const key = await keyClient.loadManagedKey(certificateId);

  return await keyClient.setupSecretAccessControl(new Managed(key), password, email);
}

export async function requestResetSecretAccessControl(
  certificateId: string,
  email: string,
  secret: string,
  code: string
): Promise<ResetAccessControl> {
  const body = {
    email,
    secret,
    code
  };
  return await HttpClient.post(`/keys/v1/keys/${certificateId}/access/secret/reset`, body);
}

export async function requestRecoverySecretAccessControl(
  certificateId: string
): Promise<{ certificateId: string }> {
  const body = {};
  return await HttpClient.post(`/keys/v1/keys/${certificateId}/access/secret/recovery`, body);
}

export type GetAccessControlDetails = {
  keyId: string;
};

export async function requestAccessControlInfo(keyId: string): Promise<AccessControlInfo> {
  const url = `/keys/v1/keys/${keyId}/access`;
  return await HttpClient.get(url);
}

export async function requestEditAccessControl(
  keyId: string,
  email: string,
  secret: string,
  access_code: string
): Promise<EditAccessControl> {
  const body = {
    email,
    secret,
    access_code
  };

  return await HttpClient.put(`/keys/v1/keys/${keyId}/access`, body);
}
