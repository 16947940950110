import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export default createSlice({
  name: 'login',
  initialState: {
    loading: false,
    error: '' as string
  },
  reducers: {
    login: (state, action: PayloadAction<{ email: string; password: string }>) => {
      state.loading = true;
    },
    onLoginSuccess: (state) => {
      state.loading = false;
    },
    onLoginError: (state, action: PayloadAction<{ code: number }>) => {
      state.loading = false;
      switch (action.payload.code) {
        case 4012:
          state.error = 'Incorrect email/password';
          break;
        case 4025:
          state.error = 'You must verify your email before logging in';
          break;
        default:
          state.error = 'Error while login, please try again';
      }
    }
  }
});
