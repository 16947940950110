import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseLicenses } from './requests';

export type License = {
  id: string;
  user_id: string;
  key: string;
  brand: string;
  expiration: string;
  scopes: string[];
  created_at: string;
};

export default createSlice({
  name: 'licenses',
  initialState: {
    loading: false,
    error: '' as string,
    license_keys: [] as License[]
  },
  reducers: {
    licenses: (state) => {
      state.loading = true;
    },

    onGetLicensesSuccess: (state, action: PayloadAction<ResponseLicenses>) => {
      state.license_keys = action.payload.license_keys;
      state.loading = false;
    },
    onGetLicensesError: (state, action: PayloadAction<{ error: string }>) => {
      state.error = action.payload.error;
      state.loading = false;
    }
  }
});
