import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Event, Invocation, Webhook } from '.';
import { modalActions, webhookActions } from '../actions';
import { getAuthUserId } from '../auth';
import { AppState } from '../reducers';
import {
  ResponseInvocation,
  requestCreateWebhook,
  requestDeleteWebhook,
  requestEventsList,
  requestGetInvocation,
  requestInvocationsList,
  requestWebhookDetails,
  requestWebhooks
} from './requests';

function* webhooksList() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: Webhook[] = yield call(requestWebhooks, userId);
    yield put(webhookActions.onWebhooksListSuccess(response));
  } catch (e: any) {
    yield put(
      webhookActions.onWebhooksListError({
        error: e
      })
    );
  }
}

function* eventsList() {
  try {
    const response: Event = yield call(requestEventsList);
    yield put(webhookActions.onEventsListSuccess(response));
  } catch (e: any) {
    yield put(
      webhookActions.onEventListError({
        error: e
      })
    );
  }
}

function* createWebhook(
  action: PayloadAction<{
    url: string;
    event_types: string[];
  }>
) {
  try {
    const { url, event_types } = action.payload;
    const userId: string = yield select(getAuthUserId);
    const response: { webhook_secret: string } = yield call(
      requestCreateWebhook,
      userId,
      url,
      event_types
    );

    yield put(webhookActions.onCreateWebhookSuccess(response));
    yield put(webhookActions.webhooksList());
  } catch (e: any) {
    yield put(
      webhookActions.onCreateWebhookError({
        error: e
      })
    );
  }
}

function* deleteWebhook(action: PayloadAction<{ webhook_id: string }>) {
  try {
    yield call(requestDeleteWebhook, action.payload.webhook_id);
    yield put(webhookActions.onDeleteWebhookSuccess());
    yield put(webhookActions.webhooksList());
    yield put(modalActions.close());
  } catch (e: any) {
    yield put(
      webhookActions.onDeleteWebhookError({
        error: e
      })
    );
  }
}

function* webhookDetail(action: PayloadAction<{ webhook_id: string }>) {
  try {
    const response: Webhook = yield call(requestWebhookDetails, action.payload.webhook_id);
    yield put(webhookActions.onWebhookDetailsSuccess(response));
  } catch (e: any) {
    yield put(
      webhookActions.onWebhookDetailsError({
        error: e
      })
    );
  }
}

function* invocations(
  action: PayloadAction<{ webhook_id: string; pageSize?: number; page?: number; status?: string }>
) {
  try {
    const response: ResponseInvocation = yield call(
      requestInvocationsList,
      action.payload.webhook_id,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 20,
      action.payload.status
    );
    yield put(webhookActions.onWebhookInvocationsSuccess(response));
  } catch (e: any) {
    yield put(
      webhookActions.onWebhookInvocationsError({
        error: e
      })
    );
  }
}

function* selectInvocation(action: PayloadAction<{ id: string }>) {
  try {
    const response: Invocation = yield call(requestGetInvocation, action.payload.id);
    yield put(webhookActions.onSelectInvocationSuccess(response));
  } catch (e: any) {
    yield put(
      webhookActions.onSelectInvocationError({
        error: e
      })
    );
  }
}

function* changeStatusFilter() {
  const { page, status, webhook_id, pageSize } = yield select((state: AppState) => state.webhooks);
  yield put(
    webhookActions.webhookInvocationsList({
      webhook_id: webhook_id,
      page: page ? page : 1,
      pageSize: pageSize ? pageSize : 10,
      status: status
    })
  );
}

export default function* webhookSaga() {
  yield takeLatest(webhookActions.webhooksList.type, webhooksList);
  yield takeLatest(webhookActions.eventList.type, eventsList);
  yield takeLatest(webhookActions.createWebhook.type, createWebhook);
  yield takeLatest(webhookActions.deleteWebhook.type, deleteWebhook);
  yield takeLatest(webhookActions.webhookDetails.type, webhookDetail);
  yield takeLatest(webhookActions.webhookInvocationsList.type, invocations);
  yield takeLatest(webhookActions.changeStatusFilter.type, changeStatusFilter);
  yield takeLatest(webhookActions.onSelectInvocation.type, selectInvocation);
}
