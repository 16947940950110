export class HttpRequestException implements Error {
  name = 'HttpRequestException';
  code: number;
  message = 'HttpClient response was not successful: unknown error.';
  messageCode: string | undefined = undefined;
  stack?: string | undefined;

  constructor(code?: number, message?: string) {
    this.code = code ? code : 0;
    this.messageCode = message;
    if (this.message) {
      this.message = `HttpClient response was not successful: ${message}. Code: ${code}`;
    }
  }
}
