import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseStatistics } from './requests';

export default createSlice({
  name: 'statistics',
  initialState: {
    statistics: {
      avg_certs_per_anchor: 0,
      avg_certs_per_sec: 0,
      avg_time_per_anchor: 0
    },
    loading: false,
    error: '' as string
  },

  reducers: {
    statistics: (state) => {
      state.loading = true;
    },
    onStatisticsSuccess: (state, action: PayloadAction<ResponseStatistics>) => {
      state.loading = false;
      state.statistics = action.payload;
    },
    onStatisticsError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    }
  }
});
