import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Meta } from '../explorer';
import { CreateKeyResponse, DetailKeyResponse, Keys, KeysResponse } from './requests';

export type CreateKeyData = {
  name: string;
  key_type: string;
  protection_level: number;
  expiration: number | undefined;
  type?: 'ac_secret_based' | 'ac_time_based_otp';
  args?: Record<string, string> | undefined;
};

export type ICheckoutResponse = {
  checkout_id: string;
};

export default createSlice({
  name: 'keys',
  initialState: {
    loading: false,
    error: '' as string,
    list: [] as Keys[],
    meta: {} as Meta,
    keyDetail: null as DetailKeyResponse | null,
    keysTab: '1',
    keyId: '' as string,
    args: '' as unknown as Record<string, string>,
    type: '' as string | undefined
  },
  reducers: {
    switchKeysTab: (state, action: PayloadAction<string>) => {
      state.keysTab = action.payload;
    },
    getKeysList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onGetKeysListSuccess: (state, action: PayloadAction<KeysResponse>) => {
      state.loading = false;
      state.list = action.payload.keys;
      state.meta = action.payload.Pagination.meta;
    },
    onGetKeysListError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },

    deleteKey: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    onDeleteKeySuccess: (state) => {
      state.loading = false;
      toast.success('The key has been successfully deleted', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onDeleteKeyError: (state, action: PayloadAction<{ error: any }>) => {
      state.loading = false;
      switch (action.payload.error.code) {
        case 401:
          state.error = 'Error while deleting the key';
          break;
        case 400:
          state.error = 'Error deleting key. This key has an associated issuer';
          break;
        default:
          state.error = 'Error while deleting the key';
      }
      toast.error(state.error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    createKey: (state, _action: PayloadAction<CreateKeyData>) => {
      state.loading = true;
      state.error = '';
    },
    onCreateKeySuccess: (state, action: PayloadAction<CreateKeyResponse>) => {
      state.keyId = action.payload.key_id;
      state.loading = false;
      state.error = '';
      toast.success('Your key was successfully created', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onCreateKeyError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while creating new key';
          break;
        default:
          state.error = 'Error while creating new key';
      }
    },
    detailKey: (
      state,
      _action: PayloadAction<{
        keyId: string;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    onDetailKeySuccess: (state, action: PayloadAction<DetailKeyResponse>) => {
      state.loading = false;
      state.keyDetail = action.payload;
      state.error = '';
    },

    onDetailKeyError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while loading key';
          break;
        default:
          state.error = 'Error while loading key';
      }
    }
  }
});
