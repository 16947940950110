import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/store';

const GuideThru = () => {
  const { t } = useTranslation('guide-thru');
  const location = useLocation();
  const currentRoute = location.pathname;
  const guideStorage = localStorage.getItem('guide');

  const { name } = useAppSelector((state) => state.profile);
  const { role } = useAppSelector((state) => state.auth);

  const stepsFreeTial = [
    {
      intro: `<div>
								<h3>${t('greet-title')} ${name} </h3>
								<br></br>
								<p>${t('first-paragraph')}</p>
								<br></br>
                 <ul>
                <li>
                ${t('features-list-one')}
                </li>
                <li>
                ${t('features-list-two')}
                </li>
                <li>
                ${t('features-list-three')}
                </li>
                <li>
                ${t('features-list-four')}
                </li>
                <li>
                ${t('features-list-five')}
                </li>
                </ul>
								<p>${t('second-paragraph')}</p> 
								<br></br>
								<p>${t('four-paragraph')}</p> 
							</div>`,
      tooltipClass: 'firstTooltip',
      nextLabel: 'Next >'
    }
  ];

  const onExit = () => {
    localStorage.setItem('guide', 'done');
    return false;
  };

  const stepsEnabledConditions = () => {
    if (currentRoute === '/home' && window.innerWidth > 1000 && guideStorage !== 'done') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {role === 'free_plan' && (
        <Steps
          enabled={stepsEnabledConditions()}
          steps={stepsFreeTial}
          initialStep={0}
          onExit={onExit}
          options={{
            nextLabel: t('next-button'),
            prevLabel: t('previous-button'),
            showBullets: false
          }}
        />
      )}
    </>
  );
};

export default GuideThru;
