import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Icon, Icons } from 'bloock-ui-components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../redux/actions';
import Button from '../button';

export type IDefaultModal = {
  headerLabel: string;
  headerIcon?: Icons;
  headerIconColor?: string;
  body?: JSX.Element;
  success?: boolean;
  successDisabled?: boolean;
  successLabel?: string;
  successCallback?: () => void;
  dismiss?: boolean;
  dismissDisabled?: boolean;
  dismissLabel?: string;
  dismissCallback?: () => void;
  isLoading?: boolean;
  width?: number;
  height?: number | string;
  onBackdropClick?: () => void;
};

export const DefaultModal: React.FC<IDefaultModal> = ({
  success = true,
  dismiss = true,
  headerIconColor = 'var(--success-color)',
  ...rest
}) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        position: 'absolute',
        width: rest.width ? rest.width : 400,
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        boxShadow: theme.shadows[4],
        textAlign: 'center',
        borderRadius: '5px',
        padding: '10px 40px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'scroll',
        display: 'block',
        height: rest.height ? rest.height : 'fit-content'
      }
    })
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultDismissCallback = () => dispatch(modalActions.close());
  const dismissCallback = rest.dismissCallback || defaultDismissCallback;

  const defaultSuccessCallback = () => dispatch(modalActions.close());
  const successCallback = rest.successCallback || defaultSuccessCallback;

  return (
    <div className={classes.paper}>
      {rest.headerIcon && (
        <Icon className="pt-5" icon={rest.headerIcon} size={45} color={headerIconColor}></Icon>
      )}

      {rest.headerLabel && <h3 className="pt-5 pb-4">{rest.headerLabel}</h3>}
      {rest.children}
      <div className="mt-2 mb-3 overflow-scroll	">
        {dismiss && (
          <Button
            className="w-5/12 m-y-5"
            disabled={rest.dismissDisabled}
            negative={false}
            onClick={() => dismissCallback()}
          >
            {rest.dismissLabel}
          </Button>
        )}

        {success && (
          <Button
            type="submit"
            className="w-5/12 my-5 m-3"
            negative={true}
            disabled={rest.successDisabled}
            loading={rest.isLoading}
            onClick={() => {
              successCallback();
            }}
          >
            {rest.successLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
