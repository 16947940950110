import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Log } from '.';
import { logsActions } from '../actions';
import { getAuthUserId } from '../auth';
import { AppState } from '../reducers';
import { ResponseLogs, requestGetLog, requestLogs } from './requests';

function* logs(action: PayloadAction<{ page?: number; pageSize?: number }>) {
  try {
    const { status } = yield select((state: AppState) => state.logs);
    const userId: string = yield select(getAuthUserId);

    const response: ResponseLogs = yield call(
      requestLogs,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 20,
      status,
      userId
    );
    yield put(logsActions.onLogsSuccess(response));
  } catch (e: any) {
    yield put(
      logsActions.onLogsError({
        error: e
      })
    );
  }
}

function* selectLog(action: PayloadAction<{ id: string }>) {
  try {
    const response: Log = yield call(requestGetLog, action.payload.id);
    yield put(logsActions.onSelectLogSuccess(response));
  } catch (e: any) {
    yield put(
      logsActions.onSelectLogError({
        error: e
      })
    );
  }
}

function* changeStatusFilter() {
  yield put(logsActions.logs({}));
}

export default function* logsSaga() {
  yield takeLatest(logsActions.logs.type, logs);
  yield takeLatest(logsActions.selectLog.type, selectLog);
  yield takeLatest(logsActions.changeStatusFilter.type, changeStatusFilter);
}
