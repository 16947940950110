import { HttpClient } from '../../http/client';
import { Meta } from '../explorer';
import { RecordsResponse } from '../records/requests';

export type AnchorsResponse = {
  anchors: Anchors[];
  meta: Meta;
};

export type Anchors = {
  id: number;
  root: string;
  status: string;
  message_count: number;
  created_at: string;
  network: {
    root: string;
    name: string;
    tx_hash: string;
    status: string;
    created_at: string;
  }[];
};

export type ResponseAnchorDetails = {
  id: number;
  root: string;
  created_at: string;
  block_roots: string[];
  network: {
    name: string;
    tx_hash: string;
    state: string;
    created_at: string;
  }[];
  status: string;
};

export type ResponseAnchorNames = {
  names: string[];
};

export type ResponseAccRecords = {
  PlanRecord: number;
  BillingInterval: string;
  StartCurrentPeriod: string;
  EndCurrentPeriod: string;
};

export async function getAnchorList(
  page: number,
  pageSize: number,
  userId?: string,
  anchorId?: number
): Promise<AnchorsResponse> {
  let url = `/anchors/v1/anchors?page=${page}&per_page=${pageSize}`;

  if (userId) {
    url += `&user_id=${userId}`;
  }
  if (anchorId) {
    url += `&filter=${anchorId}`;
  }
  return await HttpClient.get(url);
}

export async function requestAnchorRecords(
  page: number,
  pageSize: number,
  anchorId: number
): Promise<RecordsResponse> {
  const url = `/records/v1/records?page=${page}&per_page=${pageSize}&anchor_id=${anchorId}`;

  return await HttpClient.get(url);
}

export type ResponseRecorsUsage = {
  record_usage_id: string;
  usage_provider_id: string;
  usage_record: number;
};

export async function requestAnchorNetworkNames(): Promise<ResponseAnchorNames> {
  const url = `/anchors/v1/anchors/networks`;
  return await HttpClient.get(url);
}

export async function requestAccRecords(userId: string): Promise<ResponseAccRecords> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/record`);
}

export async function requestRecordsUsage(userId: string): Promise<ResponseRecorsUsage> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/record_usage`);
}

export async function requestDetailAnchor(anchorId: string): Promise<ResponseAnchorDetails> {
  return await HttpClient.get(`/keys/v1/keys/${anchorId}`, {
    headers: { API_VERSION: '2023-11-03' }
  });
}
