import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { identityAuditTrailActions } from '../actions';
import { AuditTrailListResponse, requestGetAuditTrailList } from './requests';

function* getAuditTrailList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const response: AuditTrailListResponse = yield call(
      requestGetAuditTrailList,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10
    );
    yield put(identityAuditTrailActions.onGetAuditTrailListSuccess(response));
  } catch (e: any) {
    yield put(
      identityAuditTrailActions.onGetAuditTrailListError({
        error: e
      })
    );
  }
}

export default function* auditTrailSaga() {
  yield takeLatest(identityAuditTrailActions.getAuditTrailList.type, getAuditTrailList);
}
