import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export default createSlice({
  name: 'change-password',
  initialState: {
    loading: false,
    error: '' as string
  },
  reducers: {
    changePassword: (
      state,
      _action: PayloadAction<{
        current_password: string;
        new_password: string;
        new_password_confirmation: string;
      }>
    ) => {
      state.loading = true;
    },
    onChangePasswordSuccess: (state) => {
      state.loading = false;
      toast.success('Password successfully changed ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onChangePasswordError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Passwords do not match';
          break;
        default:
          state.error = 'Error while changing the password, please try again.';
      }
    }
  }
});
