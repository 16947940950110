import { Log, Meta } from '.';
import { HttpClient } from '../../http/client';

export type ResponseLogs = {
  activities: Log[];
  meta: Meta;
};

export async function requestLogs(
  page: number,
  pageSize: number,
  status: number,
  user_id: string
): Promise<ResponseLogs> {
  let url = `/events/v1/activities?page=${page}&user=${user_id}&per_page=${pageSize}`;
  if (status) {
    url += `&status=${status}`;
  }
  return await HttpClient.get(url);
}

export async function requestGetLog(id: string): Promise<Log> {
  const url = `/events/v1/activities/${id}`;
  return await HttpClient.get(url);
}
