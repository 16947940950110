import React from 'react';
import { FieldError } from 'react-hook-form';

export type IFormError = {
  error: FieldError;
};

const Error: React.VFC<IFormError> = ({ error }) => {
  return (
    <div style={{ paddingTop: '8px', position: 'relative' }}>
      <span className="font-normal" style={{ color: 'var(--danger-color)' }}>
        {error.message}
      </span>
    </div>
  );
};

export default Error;
