import { License } from '.';
import { HttpClient } from '../../http/client';

export type ResponseLicenses = {
  license_keys: License[];
};

export async function requestGetLicenses(userId: string): Promise<ResponseLicenses> {
  return await HttpClient.get(`/credentials/v1/licenses?user_id=${userId}`);
}
