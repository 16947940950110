import { HttpClient } from '../../http/client';

export async function requestSendFeedback(userId: string, body: string, rating: number) {
  const bodyReq = {
    body,
    user_id: userId
  };

  return await HttpClient.post(`/notifications/v1/feedback`, bodyReq);
}
