import jwt_decode from 'jwt-decode';

export const decodeJWT = (token: string): any => {
  return jwt_decode(token);
};

export const isJWTValid = (token: string | undefined): boolean => {
  if (!token) return false;

  const jwt = decodeJWT(token);
  const expiration = jwt.exp;
  if (!expiration || expiration * 1000 <= new Date().getTime()) {
    return false;
  }

  return true;
};
