import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, put, takeLatest } from 'redux-saga/effects';
import { HttpRequestException } from '../../http/exception/http.exception';
import { routes } from '../../routes';
import { setCookie } from '../../utils/cookie';
import { authActions, loginActions } from '../actions';
import { LoginResponse, requestLogin } from './requests';

function* login(action: PayloadAction<{ email: string; password: string }>) {
  try {
    const { email, password } = action.payload;
    const response: LoginResponse = yield call(requestLogin, email, password);
    setCookie('token', response.token);

    yield put(authActions.setAuthToken(response.token));
    yield put(loginActions.onLoginSuccess());
    yield put(push(routes.home.url));
  } catch (e: any) {
    if (e instanceof HttpRequestException) {
      yield put(loginActions.onLoginError({ code: e.code }));
    } else {
      yield put(loginActions.onLoginError({ code: 0 }));
    }
  }
}

export default function* loginSaga() {
  yield takeLatest(loginActions.login.type, login);
}
