import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from '../explorer';
import {
  CreateCredential,
  CreateSchemaRespone,
  CredentialSchema,
  CredentialSchemasDetail,
  CredentialSchemasListResponse,
  Schema
} from './requests';

export default createSlice({
  name: 'credentialSchemas',
  initialState: {
    CredentialId: '',
    loading: false,
    error: '' as string,
    list: [] as CredentialSchema[],
    credentialSchemasDetail: {} as CredentialSchemasDetail,
    meta: {} as Meta,
    testQrCode: '' as string
  },
  reducers: {
    resetError: (state, _action: PayloadAction<void>) => {
      state.error = '';
    },
    changeCredentialFilter: (state, action: PayloadAction<string>) => {
      state.CredentialId = action.payload;
    },
    getCredentialSchemaList: (
      state,
      action: PayloadAction<{ page?: number; pageSize?: number }>
    ) => {
      state.loading = true;
    },
    onGetCredentialSchemaListSuccess: (
      state,
      action: PayloadAction<CredentialSchemasListResponse>
    ) => {
      state.loading = false;
      state.list = action.payload.schemas;
      state.meta = action.payload.pagination.meta;
    },
    onGetCredentialSchemaListError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },

    getCredentialSchemasDetail: (state, action: PayloadAction<{ schema_json_cid: string }>) => {
      state.loading = true;
    },
    getCredentialSchemasDetailSuccess: (state, action: PayloadAction<CredentialSchemasDetail>) => {
      state.loading = false;
      state.credentialSchemasDetail = action.payload;
    },
    getCredentialSchemasDetailError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },

    createSchema: (state, _action: PayloadAction<Schema>) => {
      state.loading = true;
    },
    onCreateSchemaSuccess: (state, _action: PayloadAction<CreateSchemaRespone>) => {
      state.loading = false;
    },
    onCreateSchemaError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    deleteSchema: (state, _action: PayloadAction<string>) => {
      state.loading = true;
    },
    onDeleteSchemaSuccess: (state, _action: PayloadAction<void>) => {
      state.loading = false;
    },
    onDeleteSchemaError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    testSchema: (state, _action: PayloadAction<CreateCredential>) => {
      state.loading = true;
      state.error = '';
    },
    onTestSchemaSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.testQrCode = action.payload;
    },
    onTestSchemaError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while generating credential';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    resetTestSchema: (state, _action: PayloadAction<void>) => {
      state.testQrCode = '';
    }
  }
});
