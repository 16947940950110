import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from '../explorer';
import { CountOperationsResponse, Operation, OperationsResponse } from './requests';

export default createSlice({
  name: 'keyOperations',
  initialState: {
    loading: false,
    error: '' as string,
    list: [] as Operation[],
    operationTypeFilter: '' as string,
    count: {} as {
      signOperations: number;
      encryptOperations: number;
    },
    meta: {} as Meta
  },
  reducers: {
    getOperationsList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onGetOperationsListSuccess: (state, action: PayloadAction<OperationsResponse>) => {
      state.loading = false;
      state.list = action.payload.operations;
      state.meta = action.payload.Pagination.meta;
    },
    onGetOperationsListError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    getOperationsByKey: (
      state,
      action: PayloadAction<{ keyId: string; page?: number; pageSize?: number }>
    ) => {
      state.loading = true;
    },
    onGetOperationsByKeySuccess: (state, action: PayloadAction<OperationsResponse>) => {
      state.loading = false;
      state.list = action.payload.operations;
      state.meta = action.payload.Pagination.meta;
    },
    onGetOperationsByKeyError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    changeOperationTypeFilter: (state, action: PayloadAction<string>) => {
      state.operationTypeFilter = action.payload;
    },
    changeOperationByKeyTypeFilter: (
      state,
      action: PayloadAction<{ keyId: string; filter: string }>
    ) => {
      state.operationTypeFilter = action.payload.filter;
    },
    getCountOperationsByDate: (state, action: PayloadAction<{ date: string }>) => {
      state.loading = true;
    },
    onGetCountOperationsByDateSuccess: (state, action: PayloadAction<CountOperationsResponse>) => {
      state.loading = false;
      state.count = {
        signOperations: action.payload.sign_operations,
        encryptOperations: action.payload.encrypt_operations
      };
    },
    onGetCountOperationsByDateError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    }
  }
});
