import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { routes } from '../../routes';
import { identityCredentialSchemasActions, modalActions } from '../actions';
import { AppState } from '../reducers';
import {
  CreateCredential,
  CreateSchemaRespone,
  CredentialSchemasDetail,
  CredentialSchemasListResponse,
  Schema,
  requestCreateSchema,
  requestDeleteCredentialSchema,
  requestGetCredentialSchemasDetail,
  requestGetCredentialSchemasList,
  requestTestSchema
} from './requests';

function* getCredentialSchemaList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const response: CredentialSchemasListResponse = yield call(
      requestGetCredentialSchemasList,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10
    );
    yield put(identityCredentialSchemasActions.onGetCredentialSchemaListSuccess(response));
  } catch (e: any) {
    yield put(
      identityCredentialSchemasActions.onGetCredentialSchemaListError({
        error: e
      })
    );
  }
}

function* getCredentialSchemasDetail(action: PayloadAction<{ schema_json_cid: string }>) {
  try {
    const response: CredentialSchemasDetail = yield call(
      requestGetCredentialSchemasDetail,
      action.payload.schema_json_cid
    );
    yield put(identityCredentialSchemasActions.getCredentialSchemasDetailSuccess(response));
  } catch (e: any) {
    yield put(
      identityCredentialSchemasActions.getCredentialSchemasDetailError({
        error: e
      })
    );
  }
}

function* createSchema(action: PayloadAction<Schema>) {
  try {
    const { token } = yield select((state: AppState) => state.auth);

    const response: CreateSchemaRespone = yield call(requestCreateSchema, action.payload, token);
    yield put(identityCredentialSchemasActions.onCreateSchemaSuccess(response));
    yield put(push({ pathname: routes.identity.url }));
  } catch (e: any) {
    yield put(
      identityCredentialSchemasActions.onCreateSchemaError({
        error: e
      })
    );
  }
}

function* deleteSchema(action: PayloadAction<string>) {
  try {
    const _: void = yield call(requestDeleteCredentialSchema, action.payload);
    yield put(identityCredentialSchemasActions.onDeleteSchemaSuccess());
    yield put(modalActions.close());
    yield put(identityCredentialSchemasActions.getCredentialSchemaList({}));
  } catch (e: any) {
    yield put(
      identityCredentialSchemasActions.onDeleteSchemaError({
        error: e
      })
    );
  }
}

function* testSchema(action: PayloadAction<CreateCredential>) {
  try {
    const qr: string = yield call(requestTestSchema, action.payload);
    yield put(identityCredentialSchemasActions.onTestSchemaSuccess(qr));
  } catch (e: any) {
    yield put(
      identityCredentialSchemasActions.onTestSchemaError({
        error: e
      })
    );
  }
}

export default function* credentialsSchemasSaga() {
  yield takeLatest(
    identityCredentialSchemasActions.getCredentialSchemaList.type,
    getCredentialSchemaList
  );
  yield takeLatest(
    identityCredentialSchemasActions.getCredentialSchemasDetail.type,
    getCredentialSchemasDetail
  );
  yield takeLatest(identityCredentialSchemasActions.createSchema.type, createSchema);
  yield takeLatest(identityCredentialSchemasActions.deleteSchema.type, deleteSchema);
  yield takeLatest(identityCredentialSchemasActions.testSchema.type, testSchema);
}
