import { HttpClient } from '../../http/client';

export type LoginResponse = {
  token: string;
};

export async function requestLogin(email: string, password: string): Promise<LoginResponse> {
  const body = {
    email,
    password
  };

  return await HttpClient.post('/credentials/v1/login', body);
}
