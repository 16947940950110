import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Meta } from '../explorer';
import {
  HostedFile,
  HostedListResponse,
  HostedUploadFile,
  IpfsFile,
  IpfsListResponse,
  IpfsUploadFile
} from './requests';

export default createSlice({
  name: 'availability',
  initialState: {
    loading: false,
    error: '' as string,
    hostedList: [] as HostedFile[],
    ipfsList: [] as IpfsFile[],
    hostingType: '' as string,
    hostedMeta: {} as Meta,
    ipfsMeta: {} as Meta,
    hostingId: '',
    progress: 0,
    availabilityTab: '1'
  },
  reducers: {
    switchAvailabilityTab: (state, action: PayloadAction<string>) => {
      state.availabilityTab = action.payload;
    },
    changeHostingFilter: (state, action: PayloadAction<string>) => {
      state.hostingId = action.payload;
    },
    getHostedList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onGetHostedListSuccess: (state, action: PayloadAction<HostedListResponse>) => {
      state.loading = false;
      state.hostedList = action.payload.data || [];
      state.hostedMeta = action.payload.pagination?.meta;
    },
    onGetHostedListError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    getIpfsList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onGetIpfsListSuccess: (state, action: PayloadAction<IpfsListResponse>) => {
      state.loading = false;
      state.ipfsList = action.payload.data || [];
      state.ipfsMeta = action.payload.pagination?.meta;
    },
    onGetIpfsListError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    deleteHostedFile: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    onDeleteHostedFileSuccess: (state) => {
      state.loading = false;
      toast.success('The file has been successfully deleted', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onDeleteHostedFileError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while deleting the file';
          break;
        default:
          state.error = 'Error while deleting the file';
      }
      toast.error("The file couldn't be deleted", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    deleteIpfsFile: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    onDeleteIpfsFileSuccess: (state) => {
      state.loading = false;
      toast.success('The file has been successfully deleted', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onDeleteIpfsFileError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while deleting the file';
          break;
        default:
          state.error = 'Error while deleting the file';
      }
      toast.error("The file couldn't be deleted", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    uploadHostedFile: (state, action: PayloadAction<HostedUploadFile>) => {
      state.loading = true;
      state.progress = 0;
    },
    onUploadHostedFileSuccess: (state) => {
      state.progress = 0;
      state.loading = false;
      toast.success('The file has been successfully uploaded', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onUploadHostedFileError: (state, action: PayloadAction<{ error: number }>) => {
      state.progress = 0;
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while uploading the file';
          break;
        default:
          state.error = 'Error while uploading the file';
      }
      toast.error("The file couldn't be uploaded", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    uploadIpfsFile: (state, action: PayloadAction<IpfsUploadFile>) => {
      state.loading = true;
      state.progress = 0;
    },
    onUploadIpfsFileSuccess: (state) => {
      state.progress = 0;
      state.loading = false;
      toast.success('The file has been successfully uploaded', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onUploadIpfsFileError: (state, action: PayloadAction<{ error: number }>) => {
      state.progress = 0;
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while uploading the file';
          break;
        default:
          state.error = 'Error while uploading the file';
      }
      toast.error("The file couldn't be uploaded", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    uploadIpfsDirectory: (state, action: PayloadAction<IpfsUploadFile[]>) => {
      state.loading = true;
      state.progress = 0;
    },
    onUploadIpfsDirectorySuccess: (state) => {
      state.progress = 0;
      state.loading = false;
      toast.success('The directory has been successfully uploaded', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onUploadIpfsDirectoryError: (state, action: PayloadAction<{ error: number }>) => {
      state.progress = 0;
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while uploading the directory';
          break;
        default:
          state.error = 'Error while uploading the directory';
      }
      toast.error("The directory couldn't be uploaded", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onUploadProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    resetProgress: (state, action: PayloadAction) => {
      state.progress = 0;
    }
  }
});
