import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { verifyAccountActions } from '../actions';
import { requestVerifyAccount } from './requests';

function* verifyAccount(action: PayloadAction<{ token: string }>) {
  try {
    yield call(requestVerifyAccount, action.payload.token) || [];

    yield put(verifyAccountActions.onVerifyAccountSuccess());
  } catch (e: any) {
    yield put(
      verifyAccountActions.onVerifyAccountError({
        error: e
      })
    );
  }
}

export default function* verifyAccountSaga() {
  yield takeLatest(verifyAccountActions.verifyAccount.type, verifyAccount);
}
