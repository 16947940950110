import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export default createSlice({
  name: 'feedback',
  initialState: {
    loading: false,
    error: '' as string
  },
  reducers: {
    feedback: (state, action: PayloadAction<{ body: string; rating: number }>) => {
      state.loading = true;
    },
    onFeedbackSuccess: (state) => {
      state.loading = false;
      toast.success('Feedback successfully sent ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onFeedbackError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while sending information';
          break;
        default:
          state.error = 'Error while sending the information';
      }
      toast.error(state.error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    }
  }
});
