import { AccessControlType } from '@bloock/sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RcFile } from 'antd/lib/upload';
import { toast } from 'react-toastify';
import { Meta } from '../explorer';
import { CertifierForm, GetCertificationsResponse, Process } from './requests';

export default createSlice({
  name: 'certifier',
  initialState: {
    loading: false,
    error: '' as string,
    getListError: '' as string,
    certificationsList: [] as Process[],
    certificationsListMeta: {} as Meta,
    certificationDetail: null as Process | null,
    selectedFiles: [] as RcFile[],
    showCertifierSteps: false,
    showVerifyAccessControlModal: false,
    haveAccessControlAuth: AccessControlType.NONE,
    haveAccessControlEncryption: AccessControlType.NONE,
    processData: {} as CertifierForm
  },
  reducers: {
    openCertifierSteps: (state, _action: PayloadAction) => {
      state.showCertifierSteps = true;
    },
    closeCertifierSteps: (state, _action: PayloadAction) => {
      state.showCertifierSteps = false;
    },
    setSelectedFiles: (state, action: PayloadAction<RcFile[]>) => {
      state.selectedFiles = action.payload;
    },
    resetSelectedFiles: (state, _action: PayloadAction) => {
      state.selectedFiles = [];
    },
    sendDataToCertify: (state, action: PayloadAction<CertifierForm>) => {
      state.processData = {
        integrity: { ...action.payload.integrity },
        authenticity: { ...action.payload.authenticity },
        encryption: { ...action.payload.encryption },
        availability: { ...action.payload.availability }
      };
      state.loading = true;
    },
    onSendDataToCertifySuccess: (state) => {
      state.loading = false;
      state.showVerifyAccessControlModal = false;
      state.error = '';
    },
    onSendDataToCertifyError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while sending file, please try again in a few seconds.';
          break;
        default:
          state.error = 'Error while sending file, please try again in a few seconds.';
      }
      toast.error(state.error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    certificationsList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
      state.getListError = '';
    },
    onCertificationsListSuccess: (state, action: PayloadAction<GetCertificationsResponse>) => {
      state.certificationsList = action.payload.processes || [];
      state.certificationsListMeta = action.payload.meta;
      state.getListError = '';
      state.loading = false;
    },
    onCertificationsListError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.getListError = 'Error while getting the user information';
          break;
        default:
          state.getListError = 'Unknown error';
      }
    },
    certificationDetail: (state, action: PayloadAction<{ id: string }>) => {
      state.loading = true;
      state.error = '';
    },
    onCertificationDetailSuccess: (state, action: PayloadAction<Process>) => {
      state.loading = false;
      state.certificationDetail = action.payload || [];
      state.error = '';
    },
    onCertificationDetailError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the process detail';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    onShowVerifyAccessControlModal: (state, action: PayloadAction<boolean>) => {
      state.showVerifyAccessControlModal = action.payload;
      if (!state.showVerifyAccessControlModal) {
        state.haveAccessControlAuth = AccessControlType.NONE;
        state.haveAccessControlEncryption = AccessControlType.NONE;
      }
    },
    upadateAuthAccessControlType: (state, action: PayloadAction<AccessControlType>) => {
      state.haveAccessControlAuth = action.payload;
      state.showVerifyAccessControlModal = true;
      state.loading = true;
    },
    upadateEncryptionAccessControlType: (state, action: PayloadAction<AccessControlType>) => {
      state.haveAccessControlEncryption = action.payload;
      state.showVerifyAccessControlModal = true;
    }
  }
});
