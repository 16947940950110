import { Icons } from 'bloock-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { push } from 'redux-first-history';
import * as yup from 'yup';
import { Form } from '..';
import { modalActions } from '../../redux/actions';
import { routes } from '../../routes';
import { EllipsisMiddle } from '../ellipsis-middle';
import { CheckBoxInput } from '../form/checkbox-input';
import { DefaultModal } from './default';

export type ICreateApiKeyModal = {
  apiKey: string;
};

export const CreateApiKeyModal: React.FC<ICreateApiKeyModal> = ({ apiKey }) => {
  const { t } = useTranslation('apikeys-create');
  const [isKeySaved, toggleKeySaved] = useToggle(false);

  const dispatch = useDispatch();

  const schema = yup.object({
    'is-saved': yup.mixed()
  });

  const defaultModalProps = {
    headerIcon: Icons.SuccessIcon,
    headerIconColor: 'var(--success-color)',
    headerLabel: t('created'),
    dismiss: false,
    success: true,
    successDisabled: !isKeySaved,
    successLabel: t('continue'),
    successCallback: () => {
      dispatch(modalActions.close());
      dispatch(push(routes.apikeys.url));
    }
  };

  return (
    <DefaultModal {...defaultModalProps}>
      <div className="pt-5 ">
        <span className="font-bold">{t('token')}</span>
        <div className="py-5 justify-center">
          <EllipsisMiddle>{apiKey}</EllipsisMiddle>
        </div>

        <span className="pb-10 text-xs font-normal "> {t('copy')}</span>

        <div className="pt-5 flex justify-center flex flex-row align-center">
          <div className="flex flex-row items-center">
            <Form id="save-key-form" schema={schema}>
              <CheckBoxInput
                name="is-saved"
                onChange={(value) => {
                  toggleKeySaved();
                }}
              ></CheckBoxInput>
            </Form>

            <label>{t('saved')}</label>
          </div>
          <br></br>
        </div>
      </div>
    </DefaultModal>
  );
};
