import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { routes } from '../../routes';
import { accessControlActions, keysActions, modalActions } from '../actions';
import { getAuthUserId } from '../auth';
import {
  CreateKeyResponse,
  DetailKeyResponse,
  KeysResponse,
  requestCreateKey,
  requestDeleteKey,
  requestDetailKey,
  requestGetKeys
} from './requests';

function* getKeysList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const userId: string = yield select(getAuthUserId);

    const response: KeysResponse = yield call(
      requestGetKeys as any,
      userId,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10
    );
    yield put(keysActions.onGetKeysListSuccess(response));
  } catch (e: any) {
    yield put(
      keysActions.onGetKeysListError({
        error: e
      })
    );
  }
}

function* deleteKey(action: PayloadAction<string>) {
  try {
    yield call(requestDeleteKey, action.payload);
    yield put(keysActions.onDeleteKeySuccess());
    yield put(modalActions.close());
    yield put(keysActions.getKeysList({}));
  } catch (e: any) {
    yield put(
      keysActions.onDeleteKeyError({
        error: e
      })
    );

    yield put(modalActions.close());
    yield put(keysActions.getKeysList({}));
  }
}

function* createKey(
  action: PayloadAction<{
    name: string;
    key_type: string;
    protection_level: number;
    expiration: number;
    type?: 'ac_secret_based' | 'ac_time_based_otp' | undefined;
    args?: any;
  }>
) {
  try {
    const { name, key_type, protection_level, expiration, args, type } = action.payload;
    const response: CreateKeyResponse = yield call(
      requestCreateKey,
      name,
      key_type,
      protection_level,
      expiration
    );

    const actionType = 'create-key';

    yield put(keysActions.onCreateKeySuccess(response));
    if (type) {
      yield put(
        accessControlActions.addAccessControl({ args, type, keyId: response.key_id, actionType })
      );
    } else {
      yield put(push(routes.keys.url));
    }
  } catch (e: any) {
    yield put(
      keysActions.onCreateKeyError({
        error: e
      })
    );
  }
}

function* detailKey(
  action: PayloadAction<{
    keyId: string;
  }>
) {
  try {
    const response: DetailKeyResponse = yield call(requestDetailKey, action.payload.keyId);

    yield put(keysActions.onDetailKeySuccess(response));
  } catch (e: any) {
    yield put(
      keysActions.onDetailKeyError({
        error: e
      })
    );
  }
}

export default function* keysSaga() {
  yield takeLatest(keysActions.getKeysList.type, getKeysList);
  yield takeLatest(keysActions.deleteKey.type, deleteKey);
  yield takeLatest(keysActions.createKey.type, createKey);
  yield takeLatest(keysActions.detailKey.type, detailKey);
}
