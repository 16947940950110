import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { routes } from '../../routes';
import { changePasswordActions } from '../actions';
import { getAuthUserId } from '../auth';
import { requestChangePassword } from './requests';

function* changePassword(
  action: PayloadAction<{
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
  }>
) {
  try {
    const { current_password, new_password, new_password_confirmation } = action.payload;
    const userId: string = yield select(getAuthUserId);
    yield call(
      requestChangePassword,
      userId,
      current_password,
      new_password,
      new_password_confirmation
    );

    yield put(changePasswordActions.onChangePasswordSuccess());

    yield put(push({ pathname: routes.profile.url }));
  } catch (e: any) {
    yield put(
      changePasswordActions.onChangePasswordError({
        error: e
      })
    );
  }
}

export default function* changePasswordSaga() {
  yield takeLatest(changePasswordActions.changePassword.type, changePassword);
}
