import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import STRIPE_ERRORS from '../../utils/stripeErrors.json';
import { ResponseCreateSubscription } from './requests';

export type IPaymentData = {
  product_id: string[];
  success_url: string;
  cancel_url: string;
  country: string | undefined;
  vat_id: string;
  email: string | undefined;
  name: string | undefined;
};

export type IErrorManagment = {
  code: number;
  message: string;
};

export default createSlice({
  name: 'payment',
  initialState: {
    edit: {
      loading: false,
      error: '' as string,
      isChecked: true
    },
    card_validation: {
      client_secret: '' as string,
      subscription_id: '' as string
    },
    subscription_checkout_id: '' as string,
    subscription_checkout_url: '' as string,
    subscription_checkout_mode: '' as string,
    loading: false,
    last_invoice_id: '' as string,
    subscription_status: '',
    payment_status: '',
    loadingCreateSubscription: false,
    error: 0 as number,
    errorStripe: {
      code: 0 as number,
      message: '' as string
    },

    product_id: '',
    success_url: '',
    cancel_url: '',

    loadingButton: false as boolean
  },

  reducers: {
    toggleCheckboxFields: (state) => {
      state.edit.isChecked = !state.edit.isChecked;
    },

    loadingState: (state, action: PayloadAction<boolean>) => {
      state.loadingButton = action.payload;
    },

    createSubscription: (state, action: PayloadAction<IPaymentData>) => {
      state.loadingCreateSubscription = true;
    },
    onCreateSubscriptionSuccess: (state, action: PayloadAction<ResponseCreateSubscription>) => {
      state.subscription_checkout_id = action.payload.id;
      state.subscription_checkout_mode = action.payload.mode;
      state.subscription_checkout_url = action.payload.url;
      state.loading = false;
      state.loadingCreateSubscription = false;
    },

    onCreateSubscriptionError: (state, action: PayloadAction<IErrorManagment>) => {
      state.error = action.payload.code;
      switch (action.payload.code) {
        case 402:
          const message = STRIPE_ERRORS.errors.find((o) => o.key === action.payload.message);
          if (message) {
            state.errorStripe.message = message.label;
            break;
          }
        default:
          state.errorStripe.message = 'Error while creating subscription';
      }
      toast.error(state.errorStripe.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
      state.loadingCreateSubscription = false;
      state.loadingButton = false;
      state.loading = false;
    }
  }
});
