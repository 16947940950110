import { HttpClient } from '../../http/client';
import { Meta } from '../explorer';

export type IpfsListResponse = {
  data: IpfsFile[] | null;
  pagination: {
    meta: Meta;
  };
};

export type IpfsFile = {
  ipfs_hash: string;
  content_type: string;
  parent: string;
  file_path: string;
  unix_fs_type: string;
  unix_fs_name: string;
  file_size: number;
  created_at: string;
};

export type HostedListResponse = {
  data: HostedFile[] | null;
  pagination: {
    meta: Meta;
  };
};

export type HostedFile = {
  userID: string;
  dataID: string;
  mimeType: string;
  filename: string;
  filesize: number;
  createdAt: string;
};

export async function requestDeleteHostedFile(hostingId: string) {
  return await HttpClient.delete(`/hosting/v1/hosted/${hostingId}`);
}

export async function requestDeleteIpfsFile(hostingId: string) {
  return await HttpClient.delete(`/hosting/v1/ipfs/${hostingId}`);
}

export async function requestGetHostedList(
  page: number,
  pageSize: number,
  hostingId: string
): Promise<HostedListResponse> {
  let url = `/hosting/v1/hosted?page=${page}&per_page=${pageSize}`;

  if (hostingId && hostingId !== '') {
    url += `&q=${hostingId}`;
  }
  return await HttpClient.get(url);
}

// export async function requestGetHostedList(
//   page: number,
//   pageSize: number,
//   hostingId: string
// ): Promise<HostedListResponse> {
//   return {
//     data: [
//       {
//         userID: 'string',
//         dataID: 'string',
//         mimeType: 'string',
//         filename: 'string',
//         filesize: 1,
//         createdAt: 'string'
//       }
//     ],
//     pagination: {
//       meta: {
//         current_page: 1,
//         per_page: 1,
//         from: 1,
//         to: 2,
//         total: 3,
//         last_page: 2
//       }
//     }
//   };
// }

export async function requestGetDistributedList(
  page: number,
  pageSize: number,
  hostingId: string
): Promise<IpfsListResponse> {
  let url = `/hosting/v1/ipfs?page=${page}&per_page=${pageSize}`;
  if (hostingId && hostingId !== '') {
    url += `&q=${hostingId}`;
  }
  return await HttpClient.get(url);
}

export type IpfsUploadFile = {
  file: Blob;
  name: string;
};

export type IpfsUploadResponse = {
  cid: string;
};

export async function requestUploadIpfs(
  files: IpfsUploadFile[],
  onUploadProgress: (progress: any) => void
): Promise<IpfsUploadResponse> {
  const formData = new FormData();

  for (let i = 0; i < files.length; ++i) {
    let filename = files[i].name;
    if (filename.includes('/')) {
      filename = `/${filename}`;
    }
    filename = filename.replaceAll('/', '%2F');

    let name = 'file';
    if (i > 0) {
      name += `-${i}`;
    }

    formData.append(name, files[i].file, filename);
  }

  return await HttpClient.post('/hosting/v1/ipfs/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  });
}

export type HostedUploadFile = {
  file: Blob;
  name: string;
};

export type HostedUploadResponse = {
  id: string;
};

export async function requestUploadHosted(
  files: IpfsUploadFile[],
  onUploadProgress: (progress: any) => void
): Promise<HostedUploadResponse> {
  const formData = new FormData();

  for (let i = 0; i < files.length; ++i) {
    let filename = files[i].name;
    if (filename.includes('/')) {
      filename = `/${filename}`;
    }
    filename = filename.replaceAll('/', '%2F');

    let name = 'file';
    if (i > 0) {
      name += `-${i}`;
    }

    formData.append('payload', files[i].file, filename);
  }

  return await HttpClient.post('/hosting/v1/hosted/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  });
}
