import { HttpClient } from '../../http/client';
import { Meta } from '../explorer';

export type RecordsResponse = {
  records: Records[];
  meta: Meta;
};

export type Records = {
  record: string;
  anchor_id: number;
  created_at: string;
  anchor_status: string;
  network: {
    root: string;
    name: string;
    tx_hash: string;
    status: string;
    created_at: string;
  }[];
};

export async function getRequestRecord(
  page: number,
  pageSize: number,
  recordFilter?: number
): Promise<RecordsResponse> {
  let url = `/records/v1/records?page=${page}&per_page=${pageSize}`;

  if (recordFilter) {
    url += `&filter=${recordFilter}`;
  }

  return await HttpClient.get(url);
}

export type DetailRecordsResponse = {
  record: string;
  anchor_id: number;
  created_at: string;
  anchor_status: string;
  networks: {
    root: string;
    name: string;
    tx_hash: string;
    status: string;
    created_at: string;
  }[];
};

export async function requestDetailRecords(anchorId: string): Promise<DetailRecordsResponse> {
  return await HttpClient.get(`/records/v1/records/${anchorId}`, {
    headers: {
      API_VERSION: '2023-11-03'
    }
  });
}
