import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { metadataActions } from '../actions';
import { AppState } from '../reducers';
import {
  ResponseCountries,
  ResponseMetadata,
  ResponseSubscriptionProducts,
  requestGetCountries,
  requestMetadata,
  requestSubscriptionProducts
} from './requests';

function* metadata() {
  try {
    const response: ResponseMetadata = yield call(requestMetadata) || [];
    yield put(metadataActions.onMetadataSuccess(response));
  } catch (e: any) {
    yield put(
      metadataActions.onMetadataError({
        error: e
      })
    );
  }
}

function* countries() {
  try {
    const response: ResponseCountries = yield call(requestGetCountries) || [];

    yield put(metadataActions.onCountriesSuccess(response));
  } catch (e: any) {
    yield put(
      metadataActions.onCountriesError({
        error: e
      })
    );
  }
}

function* subscriptionProducts(action: PayloadAction<string | undefined>) {
  try {
    const license = action.payload;
    const { privateCode } = yield select((state: AppState) => state.metadata);
    const response: ResponseSubscriptionProducts = yield call(
      requestSubscriptionProducts,
      license,
      privateCode
    ) || [];
    yield put(metadataActions.onSubscriptionProductsSuccess(response));
  } catch (e: any) {
    yield put(
      metadataActions.onSubscriptionProductsError({
        error: e
      })
    );
  }
}

export default function* metadataSaga() {
  yield takeLatest(metadataActions.metadata.type, metadata);
  yield takeLatest(metadataActions.countries.type, countries);
  yield takeLatest(metadataActions.subscriptionProducts.type, subscriptionProducts);
}
