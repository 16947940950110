import { Meta } from '.';
import { HttpClient } from '../../http/client';

export type ResponseExplorer = {
  anchors: ResponseAnchorList[];
  meta: Meta;
};

export type ResponseAnchorList = {
  id: number;
  root: string;
  status: string;
  message_count: number;
  created_at: string;
  networks: {
    root: string;
    name: string;
    tx_hash: string;
    status: string;
    created_at: string;
  }[];
};
export type ResponseAnchorDetails = {
  id: number;
  root: string;
  created_at: string;
  block_roots: string[];
  networks: {
    name: string;
    tx_hash: string;
    state: string;
    created_at: string;
  }[];
  status: string;
};

export type ResponseRecordList = {
  record: string;
  anchor_id: number;
  created_at: string;
  networks: {
    root: string;
    name: string;
    tx_hash: string;
    status: string;
    created_at: string;
  }[];
  status: string;
};

export type ResponseRecordDetails = {
  record: string;
  anchor_id: number;
  created_at: string;
  anchor_status: string;
  network: {
    root: string;
    name: string;
    tx_hash: string;
    status: string;
    created_at: string;
  }[];
};

export type ResponseAnchorNames = {
  names: string[];
};

export type ResponseAccRecords = {
  PlanRecord: number;
  BillingInterval: string;
  StartCurrentPeriod: string;
  EndCurrentPeriod: string;
};

export async function getRequestRecord(
  page: number,
  pageSize: number,
  network: string
): Promise<ResponseExplorer> {
  const url = `/records/v1/records?network=${network}&page=${page}&per_page=${pageSize}`;
  return await HttpClient.get(url);
}

export type ResponseRecorsUsage = {
  record_usage_id: string;
  usage_provider_id: string;
  usage_record: number;
};
export async function requestExplorer(
  page: number,
  pageSize: number,
  anchorId: string,
  network: string,
  userId: string | null
): Promise<ResponseExplorer> {
  let url = `/anchors/v1/anchors?page=${page}&per_page=${pageSize}`;
  if (network && anchorId) {
    url += `&network=${network}&filter=${anchorId}`;
  } else if (anchorId) {
    url += `&filter=${anchorId}`;
  } else if (network) {
    url += `&network=${network}`;
  } else if (userId) {
    url += `&user_id=${userId}`;
  }
  return await HttpClient.get(url);
}

export async function requestAnchorDetails(anchorId: number): Promise<ResponseAnchorDetails> {
  const url = `/anchors/v1/anchor/${anchorId}`;
  return await HttpClient.get(url);
}

export async function requestAnchorNetworkNames(): Promise<ResponseAnchorNames> {
  const url = `/anchors/v1/anchors/networks`;
  return await HttpClient.get(url);
}

export async function requestAccRecords(userId: string): Promise<ResponseAccRecords> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/record`);
}

export async function requestRecordsUsage(userId: string): Promise<ResponseRecorsUsage> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/record_usage`);
}
export async function requestDetailRecords(recordId: string): Promise<ResponseRecordDetails> {
  return await HttpClient.get(`/records/v1/records/${recordId}`, {
    headers: {
      API_VERSION: '2023-11-03'
    }
  });
}
