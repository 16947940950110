import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { HttpRequestException } from './exception/http.exception';

export type HttpOptions = {
  host?: string;
  headers?: { [key: string]: string };
  onDownloadProgress?: (progressEvent: any) => void;
  onUploadProgress?: (progressEvent: any) => void;
};

type Interceptor = (response: AxiosResponse) => AxiosResponse;

export class HttpClient {
  static token: string;
  static interceptors: Map<string, Interceptor> = new Map();

  static setResponseInterceptor(id: string, interceptor: Interceptor) {
    this.interceptors.set(id, interceptor);
  }

  static async get<T>(path: string, options?: HttpOptions): Promise<T> {
    return HttpClient.request('GET', path, null, options);
  }

  static async post<T>(path: string, body: unknown, options?: HttpOptions): Promise<T> {
    return HttpClient.request('POST', path, body, options);
  }

  static async put<T>(path: string, body?: unknown, options?: HttpOptions): Promise<T> {
    return HttpClient.request('PUT', path, body, options);
  }

  static async patch<T>(path: string, body?: unknown, options?: HttpOptions): Promise<T> {
    return HttpClient.request('PATCH', path, body, options);
  }

  static async delete<T>(path: string, options?: HttpOptions): Promise<T> {
    return HttpClient.request('DELETE', path, null, options);
  }

  private static async request<T>(
    method: Method,
    path: string,
    body?: unknown,
    options?: HttpOptions
  ) {
    const headers = options && options.headers;
    const config: AxiosRequestConfig = {
      method,
      url: `${options && options.host ? options.host : process.env.REACT_APP_API_HOST}${path}`,
      data: body ? body : null,
      headers: {
        ...(headers ? headers : {}),
        Authorization: HttpClient.token ? `Bearer ${HttpClient.token}` : ''
      },
      onDownloadProgress: options && options.onDownloadProgress,
      onUploadProgress: options && options.onUploadProgress
    };

    try {
      let response = await axios.request<T>(config);
      response = this.runResponseInterceptors(response);
      return response.data;
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;

        let response = error.response;
        if (response) {
          response = this.runResponseInterceptors(response);
        }

        const responseError = response?.data;
        throw new HttpRequestException(responseError?.code, responseError?.message);
      } else {
        throw new HttpRequestException();
      }
    }
  }

  private static runResponseInterceptors(response: AxiosResponse): AxiosResponse {
    if (response != undefined) {
      this.interceptors.forEach((interceptor) => {
        response = interceptor(response);
      });
    }

    return response;
  }
}
