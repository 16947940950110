import { IBillingInfo, ICheckoutResponse, IPaymentMethodInfo } from '.';
import { HttpClient } from '../../http/client';

export async function requestBillingInfo(userId: string): Promise<IBillingInfo> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/billing`);
}

export async function requestGetCardInfo(userId: string): Promise<IPaymentMethodInfo> {
  return await HttpClient.get(`/subscriptions/v1/users/${userId}/payment`);
}

export async function requestDeleteAccount(userId: string) {
  const body = {
    userId
  };

  return await HttpClient.put(`/users/v1/users/${userId}/delete`, body);
}

export async function requestEditBillingDetails(
  city: string,
  country: {
    value: string;
    label: string;
  },
  line1: string,
  line2: string,
  name: string,
  payment_method_id: string,
  postal_code: string,
  user_email: string,
  vat_id: string,
  userId: string
) {
  const body = {
    city,
    country,
    line1,
    line2,
    name,
    payment_method_id,
    postal_code,
    user_email,
    vat_id
  };

  return await HttpClient.put(`/subscriptions/v1/users/${userId}/subscription`, body);
}

export async function requestEditPaymentMethod(
  userId: string,
  successURL: string,
  cancelURL: string
): Promise<ICheckoutResponse> {
  const body = {
    success_url: successURL,
    cancel_url: cancelURL
  };

  return await HttpClient.post(`/subscriptions/v1/users/${userId}/setup/checkout`, body);
}
