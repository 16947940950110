import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Country } from '../metadata';
import { AppState } from '../reducers';

export type ProductData = {
  id: string;
  metadata: {
    interval: string;
    license?: string;
    name: string;
    optional: string;
    plan: string;
    private: string;
    product_type: string;
  };
};
export type UserData = {
  name: string;
  surname: string;
  email: string;
  country: Country;
  user_role: string;
};

export type Scopes = { [key: string]: string[] };

export type JWTData = {
  client_id: string;
  exp: number;
  nbf: number;
  iat: number;
  product?: ProductData[];
  user: UserData;
  scopes: Scopes;
  role?: string;
};

export default createSlice({
  name: 'auth',
  initialState: {
    token: undefined as undefined | string,
    userId: '' as string | undefined,
    switchStateDisabled: false,
    jwtData: undefined as JWTData | undefined,
    userScopes: undefined as Scopes | undefined,
    errorRefreshing: undefined as undefined | string,
    userData: undefined as UserData | undefined,
    loadingUserData: undefined as boolean | undefined,
    errorUserData: undefined as string | undefined,
    role: undefined as string | undefined
  },
  reducers: {
    logout: () => {},
    setAuthToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
    },
    setAuth: (state, action: PayloadAction<{ data: JWTData | undefined; token: string }>) => {
      state.userScopes = action.payload.data?.scopes;
      state.userId = action.payload.data?.client_id;
      state.jwtData = action.payload.data;
      state.role = action.payload.data?.user.user_role;
    },
    removeAuthToken: (state) => {
      state.token = undefined;
    },
    removeUserInfo: (state) => {
      state.token = undefined;
      state.userId = undefined;
      state.jwtData = undefined;
      state.userScopes = undefined;
    },

    refreshToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
    },

    refreshTokenLoop: (
      state,
      action: PayloadAction<{
        scope: Scopes;
      }>
    ) => {},

    onSuccessRefreshToken: () => {},
    onErrorRefreshToken: (state, action: PayloadAction<{ error: string }>) => {
      state.errorRefreshing = action.payload.error;
      toast.error(state.errorRefreshing, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },

    userData: (state) => {
      state.loadingUserData = true;
    },

    onUserDataSuccess: (state, action: PayloadAction<UserData>) => {
      state.loadingUserData = false;
      state.userData = action.payload;
    },
    onUserDataError: (state, action: PayloadAction<{ error: number }>) => {
      state.loadingUserData = false;
      switch (action.payload.error) {
        case 401:
          state.errorUserData = 'Error while getting the information';
          break;
        default:
          state.errorUserData = 'Unknown error';
      }
    },

    setSwitchState: (state, action: PayloadAction<{ isDisabled: boolean }>) => {
      state.switchStateDisabled = action.payload.isDisabled;
    }
  }
});

export const getAuthUserId = (state: AppState): string | undefined => state.auth.userId;
export const getToken = (state: AppState): string | undefined => state.auth.token;
export const getUserJWTData = (state: AppState): JWTData | undefined => state.auth.jwtData;
