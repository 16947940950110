import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { END, EventChannel, eventChannel } from 'redux-saga';
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { routes } from '../../routes';
import { availabilityActions, modalActions } from '../actions';
import { AppState } from '../reducers';
import {
  HostedListResponse,
  HostedUploadFile,
  IpfsListResponse,
  IpfsUploadFile,
  IpfsUploadResponse,
  requestDeleteHostedFile,
  requestDeleteIpfsFile,
  requestGetDistributedList,
  requestGetHostedList,
  requestUploadHosted,
  requestUploadIpfs
} from './requests';

function* getHostedList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const { hostingId } = yield select((state: AppState) => state.availability);

    const response: HostedListResponse = yield call(
      requestGetHostedList,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10,
      hostingId
    );
    yield put(availabilityActions.onGetHostedListSuccess(response));
  } catch (e: any) {
    yield put(
      availabilityActions.onGetHostedListError({
        error: e
      })
    );
  }
}

function* getIpfsList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const { hostingId } = yield select((state: AppState) => state.availability);

    const response: IpfsListResponse = yield call(
      requestGetDistributedList,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10,
      hostingId
    );
    yield put(availabilityActions.onGetIpfsListSuccess(response));
  } catch (e: any) {
    yield put(
      availabilityActions.onGetIpfsListError({
        error: e
      })
    );
  }
}

function* deleteHostedFile(action: PayloadAction<string>) {
  try {
    yield call(requestDeleteHostedFile, action.payload);
    yield put(availabilityActions.onDeleteHostedFileSuccess());
    yield put(availabilityActions.getHostedList({}));
    yield put(modalActions.close());
  } catch (e: any) {
    yield put(
      availabilityActions.onDeleteHostedFileError({
        error: e
      })
    );
    yield put(modalActions.close());
  }
}

function* deleteIpfsFile(action: PayloadAction<string>) {
  try {
    yield call(requestDeleteIpfsFile, action.payload);
    yield put(availabilityActions.onDeleteIpfsFileSuccess());
    yield put(availabilityActions.getIpfsList({}));
    yield put(modalActions.close());
  } catch (e: any) {
    yield put(availabilityActions.onDeleteIpfsFileError({ error: e }));
    yield put(modalActions.close());
  }
}

function* uploadHostedFile(action: PayloadAction<HostedUploadFile>) {
  try {
    const [uploadProgressCb, chan]: [
      ({ total, loaded }: { total: number; loaded: number }) => void,
      EventChannel<number>
    ] = yield call(createUploadChannel);

    const uploadPromise = requestUploadHosted([action.payload], uploadProgressCb);
    yield fork(uploadProgressWatcher, chan);
    yield call(() => uploadPromise);

    yield put(availabilityActions.onUploadHostedFileSuccess());
    yield put(push({ pathname: routes.availability.url }));
  } catch (e: any) {
    yield put(
      availabilityActions.onUploadHostedFileError({
        error: e
      })
    );
  }
}

function* uploadIpfsFile(action: PayloadAction<IpfsUploadFile>) {
  try {
    const [uploadProgressCb, chan]: [
      ({ total, loaded }: { total: number; loaded: number }) => void,
      EventChannel<number>
    ] = yield call(createUploadChannel);

    const uploadPromise = requestUploadIpfs([action.payload], uploadProgressCb);

    yield fork(uploadProgressWatcher, chan);
    yield call(() => uploadPromise);

    yield put(availabilityActions.onUploadIpfsFileSuccess());
    yield put(push({ pathname: routes.availability.url }));
  } catch (e: any) {
    yield put(
      availabilityActions.onUploadIpfsFileError({
        error: e
      })
    );
  }
}

function createUploadChannel(): [
  ({ total, loaded }: { total: number; loaded: number }) => void,
  EventChannel<number>
] {
  let emit: any;
  const chan: EventChannel<number> = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }: { total: number; loaded: number }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  return [uploadProgressCb, chan];
}

function* uploadProgressWatcher(chan: EventChannel<number>) {
  while (true) {
    const progress: number = yield take(chan);
    yield put(availabilityActions.onUploadProgress(progress));
  }
}

function* uploadIpfsDirectory(action: PayloadAction<IpfsUploadFile[]>) {
  try {
    const [uploadProgressCb, chan]: [
      ({ total, loaded }: { total: number; loaded: number }) => void,
      EventChannel<number>
    ] = yield call(createUploadChannel);

    const uploadPromise = requestUploadIpfs(action.payload, uploadProgressCb);
    yield fork(uploadProgressWatcher, chan);
    const _: IpfsUploadResponse = yield call(() => uploadPromise);

    yield put(availabilityActions.onUploadIpfsDirectorySuccess());
    yield put(push({ pathname: routes.availability.url }));
  } catch (e: any) {
    yield put(
      availabilityActions.onUploadIpfsDirectoryError({
        error: e
      })
    );
  }
}

function* changeHostingFilter() {
  yield put(availabilityActions.getHostedList({}));
  yield put(availabilityActions.getIpfsList({}));
}

export default function* availabilitySaga() {
  yield takeLatest(availabilityActions.getHostedList.type, getHostedList);
  yield takeLatest(availabilityActions.getIpfsList.type, getIpfsList);
  yield takeLatest(availabilityActions.deleteHostedFile.type, deleteHostedFile);
  yield takeLatest(availabilityActions.deleteIpfsFile.type, deleteIpfsFile);
  yield takeLatest(availabilityActions.uploadHostedFile.type, uploadHostedFile);
  yield takeLatest(availabilityActions.uploadIpfsFile.type, uploadIpfsFile);
  yield takeLatest(availabilityActions.uploadIpfsDirectory.type, uploadIpfsDirectory);
  yield takeLatest(availabilityActions.changeHostingFilter.type, changeHostingFilter);
}
