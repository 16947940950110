import { yupResolver } from '@hookform/resolvers/yup';
import { FormHTMLAttributes, useEffect } from 'react';
import { DefaultValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import Error from './error';

interface IForm<T> {
  id: string;
  defaultValues?: DefaultValues<T>;
  mode?: 'onSubmit' | 'onChange' | 'onBlur';
  onValid?: (valid: boolean) => void;
  onDirty?: (dirty: boolean) => void;
  onSubmit?: (data: T) => void;
  onChange?: (data: T) => void;
  error?: string;
  schema: yup.AnyObjectSchema;
}

export const Form = <T extends Record<string, unknown>>({
  id,
  defaultValues,
  schema,
  mode = 'onChange',
  onValid = () => {},
  onDirty = () => {},
  onSubmit = () => {},
  onChange = () => {},
  children,
  ...props
}: IForm<T> & Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit' | 'onChange'>) => {
  const methods = useForm<T>({ defaultValues, resolver: yupResolver(schema), mode });
  const { handleSubmit, reset, formState, control } = methods;
  const data = useWatch({ control });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    onValid(formState.isValid);
  }, [id, formState.isValid]);

  useEffect(() => {
    onDirty(formState.isDirty);
  }, [formState.isDirty]);

  useEffect(() => {
    onChange(data as T);
  }, [data]);

  return (
    <FormProvider key={id} {...methods}>
      <form
        onSubmit={handleSubmit(
          (data) => onSubmit(data as T),
          (error) => console.log(error)
        )}
        {...props}
      >
        {children}
      </form>
      {props.error && <Error error={{ type: '', message: props.error }}></Error>}
    </FormProvider>
  );
};

export default Form;
