import { Icon, Icons } from 'bloock-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Form } from '..';
import { feedbackActions, modalActions } from '../../redux/actions';
import { useAppSelector } from '../../redux/store';
import Button from '../button';
import { Textarea } from '../form/textarea';
import { DefaultModal } from './default';

export type IFeedbackModal = Record<string, unknown>;

export const FeedbackModal: React.FC<IFeedbackModal> = () => {
  const { t: t } = useTranslation();
  const { loading, error } = useAppSelector((state) => state.feedback);

  const dispatch = useDispatch();

  const defaultModalProps = {
    headerLabel: '',
    success: false,
    dismiss: false
  };

  const schema = yup.object({
    body: yup.string().required(t('messageError'))
  });

  return (
    <DefaultModal {...defaultModalProps}>
      <div className="text-right mt-3">
        <Icon
          icon={Icons.Close}
          size={11}
          color={'var(--tertiary-text-color)'}
          onClick={() => dispatch(modalActions.close())}
        ></Icon>
        <div className="pt-2 text-center" id="simple-modal-description">
          <Form
            id="send-feedback-form"
            schema={schema}
            onSubmit={(data: { body: string; rating: number }) => {
              dispatch(feedbackActions.feedback(data));
            }}
            error={error}
          >
            <div className="text-left">
              <Textarea name="body" label={t('message')} placeholder={t('placeholder')}></Textarea>

              <span className="text-xs font-normal text-gray-500">
                {t('issues')}{' '}
                <a
                  className="text-secondary "
                  href="mailto:support@bloock.com?subject=Bloock Feedback"
                >
                  {t('support')}
                </a>{' '}
                {t('issues2')}{' '}
                <a
                  className="text-secondary "
                  onClick={() => window.open('https://docs.bloock.com')}
                >
                  {t('docs')}
                </a>
              </span>
            </div>

            <div className="mt-5 mb-3">
              <Button className="w-7/12 m-y-5" type="submit" negative={true} loading={loading}>
                {t('send')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </DefaultModal>
  );
};
