import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ResponseAnchorDetails,
  ResponseAnchorList,
  ResponseExplorer,
  ResponseRecordDetails,
  ResponseRecordList,
  ResponseRecorsUsage
} from './requests';

export type Anchor = {
  id: number;
  created_at: string;
  message_count: number;
  status: string;
  root: string;
  networks: {
    anchor_id: number;
    created_at: string;
    name: string;
    status: string;
    tx_hash: string;
  }[];
};

export type Meta = {
  current_page: number;
  per_page: number;
  from: number;
  to: number;
  total: number;
  last_page: number;
};

export type Record = {
  PlanRecord: number;
  BillingInterval: string;
  StartCurrentPeriod: string;
  EndCurrentPeriod: string;
};

export default createSlice({
  name: 'explorer',
  initialState: {
    anchorId: '',
    showMyAnchors: true,
    network: '',
    meta: {} as Meta,
    anchorsList: [] as ResponseAnchorList[],
    recordsList: [] as ResponseRecordList[],
    anchorDetail: {} as ResponseAnchorDetails,
    recordDetail: {} as ResponseRecordDetails,
    networkNames: [] as string[],
    planRecords: {} as Record,
    recordsUsage: {} as ResponseRecorsUsage,
    loading: false,
    error: '' as string
  },

  reducers: {
    changeAnchorFilter: (state, action: PayloadAction<string>) => {
      state.anchorId = action.payload;
    },

    toggleShowMyAnchors: (state) => {
      state.showMyAnchors = !state.showMyAnchors;
    },

    explorer: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onExplorerSuccess: (state, action: PayloadAction<ResponseExplorer>) => {
      state.loading = false;
      state.anchorsList = action.payload.anchors;
      state.meta = action.payload.meta;
    },
    onExplorerError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    anchorNetworkNames: () => {},
    onAnchorNetworkNamesSuccess: (state, action: PayloadAction<{ names: string[] }>) => {
      state.networkNames = action.payload.names;
    },
    onAnchorNetworkNamesError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    accRecords: (state) => {
      state.loading = true;
    },
    onAccRecordsSuccess: (state, action: PayloadAction<Record>) => {
      state.loading = false;
      state.planRecords = action.payload;
    },
    onAccRecordsError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    recordsUsage: (state) => {
      state.loading = true;
    },
    onRecordsUsageSuccess: (state, action: PayloadAction<ResponseRecorsUsage>) => {
      state.loading = false;
      state.recordsUsage = action.payload;
    },
    onRecordsUsageError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    anchorDetails: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    onAnchorDetailsSuccess: (state, action: PayloadAction<ResponseAnchorDetails>) => {
      state.loading = false;
      state.anchorDetail = action.payload;
    },
    onAnchorDetailsError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    }
  }
});
