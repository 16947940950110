import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IProfile = {
  name: string;
  surname: string;
  email: string;
  deleted: boolean;
};

export default createSlice({
  name: 'profile',
  initialState: {
    edit: {
      loading: false,
      error: '' as string
    },
    name: '',
    surname: '',
    email: '',
    deleted: false,
    isEditing: false,
    loading: false,
    error: '' as string
  },
  reducers: {
    profile: (state) => {
      state.loading = true;
    },
    onProfileSuccess: (state, action: PayloadAction<IProfile>) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.email = action.payload.email;
      state.deleted = action.payload.deleted;
      state.loading = false;
    },
    onProfileError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    toggleIsEditing: (state) => {
      state.isEditing = !state.isEditing;
    },
    profileEdit: (
      state,
      action: PayloadAction<{ name: string; surname: string; email: string }>
    ) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.email = action.payload.email;
      state.edit.loading = true;
    },
    onProfileEditSuccess: (state) => {
      state.edit.loading = false;
    },
    onProfileEditError: (state, action: PayloadAction<{ error: number }>) => {
      state.edit.loading = false;
      switch (action.payload.error) {
        case 401:
          state.edit.error = 'Error while saving profile information';
          break;
        default:
          state.edit.error = 'Error while saving data, please try again';
      }
    }
  }
});
