import {
  AccessControl,
  AccessControlType,
  AnchorNetwork,
  BloockClient,
  Encrypter,
  ManagedCertificate,
  ManagedKey,
  Proof,
  ProofAnchor,
  Signer
} from '@bloock/sdk';
import { RcFile } from 'antd/lib/upload';
import { HttpClient } from '../../http/client';
import { keyTypeFromString } from '../../utils/key_type_from_string';
import { readBlob } from '../../utils/read-blob';
import { Meta } from '../explorer';
export type GetCertificationsResponse = {
  processes: Process[];
  meta: Meta;
};

export type IntegrityNetwork = {
  name: string;
  status: string;
  timestamp: number;
  txHash: string;
  anchor: {
    id: number;
    root: string;
    status: string;
  };
  proof: {
    root: string;
    leaves: string[];
    nodes: string[];
    bitmap: string;
    depth: string;
  };
};

export type AuthenticitySignature = {
  signature: string;
  alg: string;
  kid: string;
  message_hash: string;
  subject: string;
};

export type Process = {
  id: string;
  user_id: string;
  status: boolean;
  filename: string;
  created_at: string;
  success: boolean;
  hash: string;
  integrity?: {
    enabled: boolean;
    anchor_id: number;
    networks?: IntegrityNetwork[];
  };
  authenticity?: {
    enabled: boolean;
    signatures: AuthenticitySignature[];
  };
  availability?: {
    enabled: boolean;
    content_type: string;
    id: string;
    url: string;
    type: string;
    size: number;
  };
  encryption?: {
    enabled: boolean;
    alg: string;
    key: string;
    subject: string;
    access_control_type: number;
  };
};
export async function requestGetCertificationsList(
  user_id: string,
  page: number,
  pageSize: number
): Promise<GetCertificationsResponse> {
  const url = `/certifier/v1/process?user_id=${user_id}&page=${page}&per_page=${pageSize}`;
  return await HttpClient.get(url);
}

export async function requestGetCertificationDetail(id: string): Promise<Process> {
  const url = `/certifier/v1/process/${id}`;
  return await HttpClient.get(url);
}

export async function requestGetProof(hash: string): Promise<Proof> {
  const url = `/proof/v1/proof`;
  const body = {
    messages: [hash]
  };
  const proof: any = await HttpClient.post(url, body, {
    headers: {
      api_version: '2023-11-16'
    }
  });

  const networks: AnchorNetwork[] = proof.anchor.networks.map(
    (network: any) => new AnchorNetwork(network.name, network.state, network.tx_hash, network.root)
  );
  const anchor: ProofAnchor = new ProofAnchor(
    proof.anchor.anchor_id,
    networks,
    proof.anchor.root,
    proof.anchor.status
  );
  return new Proof(proof.leaves, proof.nodes, proof.depth, proof.bitmap, anchor);
}

export type CertifierForm = {
  authenticity: {
    enabled: boolean;
    keySource: string;
    localKey?: string;
    localKeyType?: string;
    managedKey?: string;
    localCertificate?: RcFile[];
    localCertificatePassword?: string;
    managedCertificate?: string;
    accessCode?: AccessControl;
    accessControlType?: AccessControlType;
    accessControlEnabled?: boolean;
  };
  availability: {
    enabled: boolean;
    type: string;
  };
  encryption: {
    enabled: boolean;
    keySource: string;
    localKey?: string;
    localKeyType?: string;
    managedKey?: string;
    localCertificate?: RcFile[];
    localCertificatePassword?: string;
    managedCertificate?: string;
    accessCode?: AccessControl;
    accessControlType?: AccessControlType;
    accessControlEnabled?: boolean;
  };

  integrity: {
    enabled: boolean;
  };
};

export type SendCertificationResponse = {
  success: boolean;
};

export async function requestSendDataToCertify(
  token: string,
  file: RcFile,
  form: CertifierForm
): Promise<never> {
  const url = `/certifier/v1/process`;

  const bloockClient = new BloockClient(token);

  const fileBytes = await readBlob(file);

  let record = await bloockClient.RecordClient.fromFile(fileBytes).build();

  const isLocalAuthenticity = ['LOCAL_KEY', 'LOCAL_CERTIFICATE'].includes(
    form.authenticity.keySource
  );

  const isLocalEncryption = ['LOCAL_KEY', 'LOCAL_CERTIFICATE'].includes(form.encryption.keySource);

  const formData = new FormData();

  // ---------------
  // AUTHENTICITY
  // ---------------

  if (form.authenticity.enabled && isLocalAuthenticity) {
    switch (form.authenticity.keySource) {
      case 'LOCAL_KEY':
        const keyType = keyTypeFromString(form.authenticity.localKeyType);
        if (keyType !== undefined && form.authenticity.localKey) {
          const localKey = await bloockClient.KeyClient.loadLocalKey(
            keyType,
            form.authenticity.localKey
          );
          record = await bloockClient.RecordClient.fromRecord(record)
            .withSigner(new Signer(localKey))
            .build();
        }
        break;
      case 'LOCAL_CERTIFICATE':
        if (form.authenticity.localCertificate && form.authenticity.localCertificate.length > 0) {
          const certificate = await readBlob(form.authenticity.localCertificate[0]);
          const localCertificate = await bloockClient.KeyClient.loadLocalCertificate(
            certificate,
            form.authenticity.localCertificatePassword || ''
          );
          record = await bloockClient.RecordClient.fromRecord(record)
            .withSigner(new Signer(localCertificate))
            .build();
        }
        break;
    }

    const authenticityMetadata = await bloockClient.RecordClient.fromFile(
      record.retrieve()
    ).getDetails();

    if (authenticityMetadata.authenticity) {
      const signature = authenticityMetadata.authenticity.signatures.pop();
      if (signature) {
        formData.append('authenticity_metadata.enabled', form.authenticity.enabled.toString());
        formData.append('authenticity_metadata.signature.signature', signature.signature);
        formData.append('authenticity_metadata.signature.alg', signature.alg);
        formData.append('authenticity_metadata.signature.kid', signature.kid);
        formData.append('authenticity_metadata.signature.message_hash', signature.messageHash);
        formData.append('authenticity_metadata.signature.subject', signature.subject || '');
      }
    }
  } else if (form.authenticity.enabled && isLocalEncryption) {
    switch (form.authenticity.keySource) {
      case 'MANAGED_KEY':
        if (form.authenticity.managedKey) {
          const managedKey = await bloockClient.KeyClient.loadManagedKey(
            form.authenticity.managedKey
          );

          record = await bloockClient.RecordClient.fromRecord(record)
            .withSigner(new Signer(managedKey, undefined, form.authenticity.accessCode))
            .build();
        }
        break;
      case 'MANAGED_CERTIFICATE':
        if (form.authenticity.managedCertificate) {
          const managedCertificate = await bloockClient.KeyClient.loadManagedCertificate(
            form.authenticity.managedCertificate
          );
          record = await bloockClient.RecordClient.fromRecord(record)
            .withSigner(new Signer(managedCertificate, undefined, form.authenticity.accessCode))
            .build();
        }
        break;
    }

    const authenticityMetadata = await bloockClient.RecordClient.fromFile(
      record.retrieve()
    ).getDetails();

    if (authenticityMetadata.authenticity) {
      const signature = authenticityMetadata.authenticity.signatures.pop();
      if (signature) {
        formData.append('authenticity_metadata.enabled', form.authenticity.enabled.toString());
        formData.append('authenticity_metadata.signature.signature', signature.signature);
        formData.append('authenticity_metadata.signature.alg', signature.alg);
        formData.append('authenticity_metadata.signature.kid', signature.kid);
        formData.append('authenticity_metadata.signature.message_hash', signature.messageHash);
        formData.append('authenticity_metadata.signature.subject', signature.subject || '');
      }
    }
  } else {
    let accessControlType = 'NONE';
    switch (form.authenticity.accessControlType) {
      case 0: {
        accessControlType = 'NONE';
        break;
      }
      case 1: {
        accessControlType = 'TOTP';
        break;
      }
      case 2: {
        accessControlType = 'SECRET';
        break;
      }
      default: {
        accessControlType = 'NONE';
      }
    }

    formData.append('authenticity.enabled', form.authenticity.enabled.toString());
    formData.append('authenticity.keySource', form.authenticity.keySource);

    if (form.authenticity.accessControlEnabled) {
      formData.append(
        'authenticity.accessEnabled',
        form.authenticity.accessControlEnabled
          ? form.authenticity.accessControlEnabled.toString()
          : 'false'
      );
      formData.append(
        'authenticity.accessCode',
        form.authenticity.accessCode
          ? form.authenticity.accessControlType === AccessControlType.SECRET
            ? form.authenticity.accessCode.accessControlSecret
              ? form.authenticity.accessCode.accessControlSecret.secret
              : ''
            : form.authenticity?.accessCode.accessControlTotp
            ? form.authenticity?.accessCode.accessControlTotp.code
            : ''
          : ''
      );
      formData.append('authenticity.accessType', accessControlType);
    }

    switch (form.authenticity.keySource) {
      case 'MANAGED_KEY':
        if (form.authenticity.managedKey)
          formData.append('authenticity.key', form.authenticity.managedKey);

        break;
      case 'MANAGED_CERTIFICATE':
        if (form.authenticity.managedCertificate)
          formData.append('authenticity.key', form.authenticity.managedCertificate);
        break;
    }
  }

  // ---------------
  // INTEGRITY
  // ---------------

  if (!isLocalEncryption) {
    formData.append('integrity.enabled', form.integrity.enabled.toString());
  } else {
    const receipt = await bloockClient.IntegrityClient.sendRecords([record]);
    formData.append('integrity_metadata.enabled', form.integrity.enabled.toString());
    formData.append('integrity_metadata.anchor_id', receipt[0].anchor.toString());
    formData.append('integrity_metadata.hash', receipt[0].record);
  }

  // ---------------
  // ENCRYPTION
  // ---------------

  let accessControlType = 'NONE';
  switch (form.encryption.accessControlType) {
    case 0: {
      accessControlType = 'NONE';
    }
    case 1: {
      accessControlType = 'TOTP';
    }
    case 2: {
      accessControlType = 'SECRET';
    }
  }
  if (form.encryption.enabled && isLocalEncryption) {
    switch (form.encryption.keySource) {
      case 'LOCAL_KEY':
        const keyType = keyTypeFromString(form.encryption.localKeyType);
        if (keyType && form.encryption.localKey) {
          const localKey = await bloockClient.KeyClient.loadLocalKey(
            keyType,
            form.encryption.localKey
          );
          record = await bloockClient.RecordClient.fromRecord(record)
            .withEncrypter(new Encrypter(localKey))
            .build();
        }
        break;
      case 'LOCAL_CERTIFICATE':
        if (form.encryption.localCertificate && form.encryption.localCertificate.length > 0) {
          const certificate = await readBlob(form.encryption.localCertificate[0]);
          const localCertificate = await bloockClient.KeyClient.loadLocalCertificate(
            certificate,
            form.encryption.localCertificatePassword || ''
          );
          record = await bloockClient.RecordClient.fromRecord(record)
            .withEncrypter(new Encrypter(localCertificate))
            .build();
        }
        break;
    }

    const encryptionMetadata = await bloockClient.RecordClient.fromFile(
      record.retrieve()
    ).getDetails();

    if (encryptionMetadata.encryption) {
      formData.append('encryption_metadata.enabled', form.encryption.enabled.toString());
      formData.append('encryption_metadata.key', encryptionMetadata.encryption.key || '');
      formData.append('encryption_metadata.alg', encryptionMetadata.encryption.alg || '');
      formData.append('encryption_metadata.subject', encryptionMetadata.encryption.subject || '');
    }
  } else {
    formData.append('encryption.enabled', form.encryption.enabled.toString());
    formData.append('encryption.keySource', form.encryption.keySource);

    if (form.encryption.accessControlEnabled) {
      formData.append(
        'encryption.accessEnabled',
        form.encryption.accessControlEnabled
          ? form.encryption.accessControlEnabled.toString()
          : 'false'
      );
      formData.append(
        'encryption.accessCode',
        form.encryption.accessCode
          ? form.encryption.accessControlType === AccessControlType.SECRET
            ? form.encryption.accessCode.accessControlSecret
              ? form.encryption.accessCode.accessControlSecret.secret
              : ''
            : form.encryption?.accessCode.accessControlTotp
            ? form.encryption?.accessCode.accessControlTotp.code
            : ''
          : ''
      );
      formData.append('encryption.accessType', accessControlType);
    }

    switch (form.encryption.keySource) {
      case 'MANAGED_KEY':
        if (form.encryption.managedKey)
          formData.append('encryption.key', form.encryption.managedKey);
        break;
      case 'MANAGED_CERTIFICATE':
        if (form.encryption.managedCertificate)
          formData.append('encryption.key', form.encryption.managedCertificate);
        break;
    }
  }

  // ---------------
  // AVAILABILITY
  // ---------------
  formData.append('availability.enabled', form.availability.enabled.toString());
  formData.append('availability.type', form.availability.type);

  const blob = new Blob([record.retrieve()], { type: file.type });
  formData.append('file', blob, file.name);

  return await HttpClient.post(url, formData);
}

export type KeyToCheck = {
  authenticity?: {
    keyId: string;
    keySource?: string;
    keyType?: string | RcFile[];
  };
  encryption?: {
    keyId: string;
    keySource: string;
    keyType: string | RcFile[];
  };
};

export async function requestLoadManagedKey(id: string, token: string): Promise<ManagedKey> {
  const bloockClient = new BloockClient(token);

  return await bloockClient.KeyClient.loadManagedKey(id);
}

export async function requestLoadManagedCertificate(
  id: string,
  token: string
): Promise<ManagedCertificate> {
  const bloockClient = new BloockClient(token);

  return await bloockClient.KeyClient.loadManagedKey(id);
}
