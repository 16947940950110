import {
  BloockClient,
  DidMethod,
  Key,
  KeyProtectionLevel,
  KeyType,
  ManagedKeyParams,
  PublishIntervalParams
} from '@bloock/sdk';
import { RcFile } from 'antd/lib/upload';
import { Buffer } from 'buffer';
import { HttpClient } from '../../http/client';
import { readBlob } from '../../utils/read-blob';
import { Meta } from '../explorer';

export type IssuerListResponse = {
  issuers: IssuerFile[] | null;
  pagination: {
    meta: Meta;
  };
};

export type IssuerFile = {
  user_id: string;
  did: string;
  key: string;
  name?: string;
  description?: string;
  image?: string;
  publish_interval: number;
  created_at: string;
};

export type IssuerDetailResponse = {
  user_id: string;
  did: string;
  key: string;
  state: {
    claims_tree_root: string;
    state: string;
    status: string;
  };
  name?: string;
  description?: string;
  image?: string;
  publish_interval: number;
  created_at: string;
};

export async function requestIssuerDetail(did: string): Promise<IssuerDetailResponse> {
  const url = `/identityV2/v1/issuers/${did}`;
  return await HttpClient.get(url);
}

export type ResponseIdentityRevocationUsage = {
  total_count: number;
};

export async function requestIdentityRevocationUsage(): Promise<ResponseIdentityRevocationUsage> {
  return await HttpClient.get(`/identityV2/v1/revocation/count`);
}

export type ResponseIdentityClaimUsage = {
  total_count: number;
};

export async function requestIdentityClaimUsage(): Promise<ResponseIdentityClaimUsage> {
  return await HttpClient.get(`/identityV2/v1/claim/count`);
}

export async function requestGetIssuerList(
  page: number,
  pageSize: number
): Promise<IssuerListResponse> {
  const url = `/identityV2/v1/issuers?page=${page}&per_page=${pageSize}`;
  return await HttpClient.get(url);
}

export type IssuerCreate = {
  did_metadata: {
    method: string;
    blockchain: string;
    network: string;
  };
  name: string;
  description: string;
  image: string;
  publish_interval: number;
  bn_128_public_key: string;
};

// export async function requestIssuerCreate(token: string) {
//   return await HttpClient.post('/identityV2/v1/issuers');
// }

export type IssuerCreateResponse = {
  id: string;
};

export async function requestCreateIssuer(
  name: string,
  description: string,
  did: string,
  interval: string,
  token: string,
  file?: RcFile
): Promise<IssuerCreateResponse> {
  const bloockClient = new BloockClient(token, '');

  const keyBjj = await bloockClient.KeyClient.newManagedKey(
    new ManagedKeyParams(KeyProtectionLevel.SOFTWARE, KeyType.Bjj, name)
  );
  const issuerKey = new Key(keyBjj);

  let fileBase64Url;
  if (file) {
    const fileBytes = await readBlob(file);
    const fileBase64 = Buffer.from(fileBytes).toString('base64');
    fileBase64Url = fileBase64.replace(/\+/g, '-').replace(/\//g, '_');
  }

  let publishInterval = PublishIntervalParams.Interval5;
  switch (interval) {
    case '5':
      publishInterval = PublishIntervalParams.Interval5;
      break;
    case '15':
      publishInterval = PublishIntervalParams.Interval15;
      break;
    case '60':
      publishInterval = PublishIntervalParams.Interval60;
      break;
  }

  let didParams;
  if (process.env.REACT_APP_ENV === 'production') {
    didParams = DidMethod.PolygonID;
  } else {
    didParams = DidMethod.PolygonIDTest;
  }
  const issuer = await bloockClient.IdentityClient.createIssuer(
    issuerKey,
    publishInterval,
    didParams,
    name,
    description,
    fileBase64Url
  );

  return {
    id: issuer.did.did
  };
}
