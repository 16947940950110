import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, delay, put, race, select, takeLatest } from 'redux-saga/effects';
import { JWTData, Scopes, UserData, getToken, getUserJWTData } from '.';
import { hasScope } from '../../auth/scopes';
import { HttpClient } from '../../http/client';
import { routes } from '../../routes';
import { removeCookie, setCookie } from '../../utils/cookie';
import { decodeJWT, isJWTValid } from '../../utils/jwt';
import { authActions } from '../actions';
import { getAuthUserId } from '../auth';
import { requestRefreshToken, requestUserData } from './requests';

function* setAuthToken(action: PayloadAction<string | undefined>) {
  const token = action.payload;

  const data: JWTData | null = null;
  if (token && isJWTValid(token)) {
    const jwt = decodeJWT(token);
    yield put(
      authActions.setAuth({
        data: jwt,
        token
      })
    );
    HttpClient.token = token;
    setCookie('token', token);
  }
}

function* refreshToken(action: PayloadAction<string>) {
  const token = action.payload;
  const response: { token: string; success: boolean } = yield call(requestRefreshToken, token);
  yield put(authActions.setAuthToken(response.token));
}

function* loopTokenRefresh(scopeAllowed: boolean, scope: Scopes) {
  while (!scopeAllowed) {
    const token: string = yield select(getToken);

    yield put(authActions.refreshToken(token));
    const userScopes: JWTData = yield select(getUserJWTData);
    scopeAllowed = hasScope(userScopes, scope);
    yield delay(5000);
  }
}

function* refreshTokenLoop(action: PayloadAction<{ scope: Scopes }>) {
  const scope = action.payload.scope;
  const scopeAllowed = false;

  const { posts, timeout } = yield race({
    posts: loopTokenRefresh(scopeAllowed, scope),
    timeout: delay(180000, true)
  });
  if (!timeout) yield put(authActions.onSuccessRefreshToken());
  else yield put(authActions.onErrorRefreshToken({ error: 'Error fetching your new information' }));
}

function* fetchUserData() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: UserData = yield call(requestUserData, userId);
    yield put(authActions.onUserDataSuccess(response));
  } catch (e: any) {
    yield put(
      authActions.onUserDataError({
        error: e
      })
    );
  }
}

function* logout() {
  removeCookie('token');
  yield put(authActions.removeUserInfo());
  yield put(push({ pathname: routes.login.url }));
}

export default function* authSaga() {
  yield takeLatest(authActions.refreshToken.type, refreshToken);
  yield takeLatest(authActions.setAuthToken.type, setAuthToken);
  yield takeLatest(authActions.refreshTokenLoop.type, refreshTokenLoop);
  yield takeLatest(authActions.logout.type, logout);
  yield takeLatest(authActions.userData.type, fetchUserData);
}
