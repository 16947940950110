import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Meta } from '../explorer';
import {
  Certificate,
  CertificatesResponse,
  CreateCertificateResponse,
  DetailCertificateResponse
} from './requests';

export default createSlice({
  name: 'certificates',
  initialState: {
    loading: false,
    error: '' as string,
    list: [] as Certificate[],
    meta: {} as Meta,
    certificateDetail: null as DetailCertificateResponse | null,
    certificateId: '' as string,
    args: '' as unknown as Record<string, string>,
    type: '' as string | undefined
  },
  reducers: {
    getCertificateList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onGetCertificateListSuccess: (state, action: PayloadAction<CertificatesResponse>) => {
      state.loading = false;
      state.list = action.payload.keys;
      state.meta = action.payload.pagination.meta;
    },
    onGetCertificateListError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },

    deleteCertificate: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    onDeleteCertificateSuccess: (state) => {
      state.loading = false;
      toast.success('The key has been successfully deleted', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onDeleteCertificateError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while deleting the key';
          break;
        default:
          state.error = 'Error while deleting the key';
      }
      toast.error("The key couldn't be deleted", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    createCertificate: (state, _action: PayloadAction<CreateCertificateResponse>) => {
      state.loading = true;
      state.error = '';
    },
    onCreateCertificateSuccess: (state, action: PayloadAction<CreateCertificateResponse>) => {
      state.certificateId = action.payload.certificate_id;
      state.loading = false;
      state.loading = false;
      state.error = '';
      toast.success('Your certificate was successfully created', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onCreateCertificateError: (
      state,
      action: PayloadAction<{ code: number; message?: string }>
    ) => {
      state.loading = false;
      switch (action.payload.code) {
        case 0:
          state.error = action.payload.message!;
          break;
        case 401:
          state.error = 'Error while creating new certificate';
          break;
        default:
          state.error = 'Error while creating new certificate';
      }
    },

    detailCertificate: (
      state,
      _action: PayloadAction<{
        certificateId: string;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },

    onDetailCertificateSuccess: (state, action: PayloadAction<DetailCertificateResponse>) => {
      state.loading = false;
      state.error = '';
      state.certificateDetail = action.payload;
    },
    onDetailCertificateError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while loading certificate';
          break;
        default:
          state.error = 'Error while loading certificate';
      }
    },
    importCertificate: (
      state,
      _action: PayloadAction<{
        file: string;
        password: string | null;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    onImportCertificateSuccess: (state, action: PayloadAction<CreateCertificateResponse>) => {
      state.loading = false;
      state.error = '';
      toast.success('Your certificate was successfully imported', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onImportCertificateError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while importing certificate';
          break;
        default:
          state.error = 'Error while importing new certificate';
      }
    }
  }
});
