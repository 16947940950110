import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from '../explorer';
import { AuditTrailFile, AuditTrailListResponse } from './requests';

export default createSlice({
  name: 'auditTrail',
  initialState: {
    loading: false,
    error: '' as string,
    auditTrailList: [] as AuditTrailFile[],
    hostingType: '' as string,
    hostedMeta: {} as Meta,
    ipfsMeta: {} as Meta,
    hostingId: '',
    progress: 0
  },
  reducers: {
    getAuditTrailList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onGetAuditTrailListSuccess: (state, action: PayloadAction<AuditTrailListResponse>) => {
      state.loading = false;
      state.auditTrailList = action.payload.data || [];
      state.hostedMeta = action.payload.pagination?.meta;
    },
    onGetAuditTrailListError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    }
  }
});
