import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseListApiKey } from './requests';

export type ApiKey = {
  name: string;
  id: string;
  created_at: string;
  deleted_at: string;
  expiration: string;
  scopes: string[];
};

export default createSlice({
  name: 'api-keys',
  initialState: {
    delete: {
      loading: false,
      error: '' as string
    },

    listProd: [] as ApiKey[],
    loading: false,
    loadingProdApikeys: false,
    error: '' as string,
    createdKey: '' as string,
    isKeySaved: false
  },

  reducers: {
    listApiKeyLive: (state) => {
      state.loadingProdApikeys = true;
    },
    onApiKeysProdSuccess: (state, action: PayloadAction<ResponseListApiKey>) => {
      state.loadingProdApikeys = false;
      state.listProd = action.payload.api_keys;
    },
    onApiKeysProdError: (state, action: PayloadAction<{ error: number }>) => {
      state.loadingProdApikeys = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    deleteApiKey: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    onApiKeyDeleteSuccess: (state) => {
      state.loading = false;
    },
    onApiKeyDeleteError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the data';
          break;
        default:
          state.error = 'Error while getting the data';
      }
    },
    createApiKeyLive: (
      state,
      _action: PayloadAction<{
        name: string;
        expiration: Date;
        scopes: string[];
      }>
    ) => {
      state.loading = true;
      state.createdKey = '';
    },
    toggleIsKeySaved: (state) => {
      state.isKeySaved = !state.isKeySaved;
    },
    onCreateProdApikeysSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.createdKey = action.payload;
      state.isKeySaved = false;
      state.error = '';
    },
    onCreateProdApikeysError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while creating new API key';
          break;
        default:
          state.error = 'Error while creating new API key';
      }
    }
  }
});
