import cookie from 'js-cookie';

export const setCookie = (key: string, value: string) => {
  cookie.set(key, value, {
    expires: 1,
    path: '/'
  });
};

export const removeCookie = (key: string) => {
  cookie.remove(key, {
    expires: 1
  });
};

export const getCookie = (key: string) => {
  if (cookie.get(key) !== undefined) {
    return cookie.get(key);
  } else {
    return '';
  }
};
