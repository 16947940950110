import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { IBillingInfo, ICheckoutResponse, IPaymentMethodInfo } from '.';
import { configurationActions } from '../actions';
import { getAuthUserId } from '../auth';
import {
  requestBillingInfo,
  requestEditBillingDetails,
  requestEditPaymentMethod,
  requestGetCardInfo
} from './requests';

function* billingInfo() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: IBillingInfo = yield call(requestBillingInfo, userId);
    yield put(configurationActions.onBillingInfoSuccess(response));
  } catch (e: any) {
    yield put(
      configurationActions.onBillingInfoError({
        error: e
      })
    );
  }
}

function* billingEdit(
  action: PayloadAction<{
    city: string;
    country: {
      id: string;
    };
    line1: string;
    line2: string;
    name: string;
    payment_method_id: string;
    postal_code: string;
    vat_id: string;
    user_email: string;
  }>
) {
  try {
    const {
      city,
      country,
      line1,
      line2,
      name,
      payment_method_id,
      postal_code,
      user_email,
      vat_id
    } = action.payload;
    const userId: string = yield select(getAuthUserId);
    yield call(
      requestEditBillingDetails as any,
      city,
      country,
      line1,
      line2,
      name,
      payment_method_id,
      postal_code,
      user_email,
      vat_id,
      userId
    );
    yield put(configurationActions.toggleIsEditingBilling());
    yield put(configurationActions.onBillingEditSuccess());
    yield put(configurationActions.billingInfo());
  } catch (e: any) {
    yield put(
      configurationActions.onBillingEditError({
        error: e
      })
    );
  }
}

function* paymentMethodInfo() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: IPaymentMethodInfo = yield call(requestGetCardInfo, userId);
    yield put(configurationActions.onPaymentMethodInfoSuccess(response));
  } catch (e: any) {
    yield put(
      configurationActions.onPaymentMethodInfoError({
        error: e
      })
    );
  }
}

function* paymentMethodInfoEdit(
  action: PayloadAction<{
    successURL: string;
    cancelURL: string;
  }>
) {
  try {
    const { successURL, cancelURL } = action.payload;
    const userId: string = yield select(getAuthUserId);
    const response: ICheckoutResponse = yield call(
      requestEditPaymentMethod,
      userId,
      successURL,
      cancelURL
    );
    yield put(configurationActions.toggleIsEditingPaymentMethod());
    yield put(configurationActions.onPaymentMethodInfoEditSuccess(response));
  } catch (e: any) {
    yield put(
      configurationActions.onPaymentMethodInfoEditError({
        error: e
      })
    );
  }
}

export default function* configurationSaga() {
  yield takeLatest(configurationActions.billingEdit.type, billingEdit);
  yield takeLatest(configurationActions.billingInfo.type, billingInfo);
  yield takeLatest(configurationActions.paymentMethodInfo.type, paymentMethodInfo);
  yield takeLatest(configurationActions.paymentMethodInfoEdit.type, paymentMethodInfoEdit);
}
