import React, { TextareaHTMLAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Error from '../error';

export type ITextarea = {
  name: string;
  label?: string;
  icon?: JSX.Element;
};

const Textarea: React.VFC<ITextarea & TextareaHTMLAttributes<HTMLElement>> = ({
  name,
  icon = null,
  ...rest
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div className="flex items-center justify-between">
        <div>
          <span>{rest.label}</span>
          {rest.required && (
            <span className="pl-1" style={{ color: 'var(--danger-color)' }}>
              *
            </span>
          )}
        </div>
      </div>

      <div style={{ position: 'relative', display: 'flex' }}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <textarea
              {...rest}
              {...field}
              className={`input ${rest.className} w-full px-3 pt-2 mt-3 outline-none`}
              style={{
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                height: '75px',
                textAlign: 'left',
                resize: 'vertical'
              }}
            ></textarea>
          )}
        />
      </div>

      {errors[name] && <Error error={errors[name]}></Error>}
    </div>
  );
};

export { Textarea };
