import { RocketOutlined } from '@ant-design/icons';
import { Card, Skeleton } from 'antd';
import React from 'react';

export type IOverviewnBox = {
  title?: string;
  metric?: number | string;
  description?: string;
  loading?: boolean;
  emptyState?: boolean;
};

export const OverviewBox: React.FC<IOverviewnBox> = ({
  title = '',
  metric = '',
  description = '',
  loading = false,
  emptyState = false,
  ...rest
}) => {
  return emptyState ? (
    <Card title={title} className="w-full m-2" style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
      <div className="flex flex-col items-center">
        <RocketOutlined className="text-center text-5xl" />
        <p className="text-md text-base" style={{ color: 'gray' }}>
          Coming Soon!
        </p>
      </div>
    </Card>
  ) : (
    <Card title={title} className="w-full m-2">
      <Skeleton loading={loading} active>
        <div className="font-medium py-2 pr-3">
          <p style={{ fontSize: '20px' }}>{metric}</p>
        </div>
        <br />
        <p className="text-base" style={{ color: 'gray', fontSize: '14px' }}>
          {description}
        </p>
      </Skeleton>
    </Card>
  );
};
