import { IPaymentData } from '.';
import { HttpClient } from '../../http/client';

export type ResponseCreateSubscription = {
  id: string;
  url: string;
  mode: string;
};

export async function requestCreateSubscription(
  paymentData: IPaymentData,
  userId: string
): Promise<ResponseCreateSubscription> {
  const body = {
    product_id: paymentData.product_id,
    success_url: paymentData.success_url,
    cancel_url: paymentData.cancel_url,
    country: paymentData.country,
    vat_id: paymentData.vat_id,
    email: paymentData.email,
    name: paymentData.name
  };
  return await HttpClient.post(`/subscriptions/v1/users/${userId}/subscription`, body);
}
