import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from '../explorer';
import { ValidationQueriesListResponse, ValidationQuery } from './requests';

export default createSlice({
  name: 'validationQueries',
  initialState: {
    loading: false,
    error: '' as string,
    validationQueriesList: [] as ValidationQuery[],
    hostingType: '' as string,
    hostedMeta: {} as Meta,
    validationQueriesMeta: {} as Meta,
    validationQueriesQr: '' as string,
    progress: 0
  },
  reducers: {
    createQuery: (
      state,
      _action: PayloadAction<{ name: string; description?: string; query: Record<string, any> }>
    ) => {
      state.loading = true;
    },
    onCreateQuerySuccess: (state, action: PayloadAction<void>) => {
      state.loading = false;
    },
    onCreateQueryError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while creating query';
      }
    },
    getValidationQueriesList: (
      state,
      action: PayloadAction<{ page?: number; pageSize?: number }>
    ) => {
      state.loading = true;
    },
    onGetValidationQueriesListSuccess: (
      state,
      action: PayloadAction<ValidationQueriesListResponse>
    ) => {
      state.loading = false;
      state.validationQueriesList = action.payload.verifications || [];
      state.validationQueriesMeta = action.payload.pagination?.meta;
    },
    onGetValidationQueriesListError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    getValidationQueriesQr: (
      state,
      _action: PayloadAction<{
        query: string;
        apiHost?: string;
      }>
    ) => {
      state.loading = true;
      state.validationQueriesQr = '';
    },
    onGetValidationQueriesQrSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.validationQueriesQr = action.payload;
    },
    onGetValidationQueriesQrError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while creating query QR';
      }
    }
  }
});
