import { IProfile } from '.';
import { HttpClient } from '../../http/client';

export async function requestProfile(userId: string): Promise<IProfile> {
  return await HttpClient.get(`/users/v1/users/${userId}`);
}

export async function requestProfileEdit(
  userId: string,
  name: string,
  surname: string,
  email: string,
  deleted: boolean
) {
  const body = {
    name,
    surname,
    email,
    deleted
  };

  return await HttpClient.put(`/users/v1/users/${userId}`, body);
}
