import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from '../explorer';
import { Records, RecordsResponse } from '../records/requests';
import { Anchors, AnchorsResponse, ResponseAnchorDetails } from './requests';

export default createSlice({
  name: 'anchors',
  initialState: {
    anchorId: '',
    showMyAnchors: true,
    network: '',
    loading: false,
    error: '' as string,
    list: [] as Anchors[],
    meta: {} as Meta,
    anchorDetail: {} as ResponseAnchorDetails,
    anchorRecords: [] as Records[],
    anchorRecordsMeta: {} as Meta
  },
  reducers: {
    changeAnchorFilter: (state, action: PayloadAction<string>) => {
      state.anchorId = action.payload;
    },
    toggleShowMyAnchors: (state) => {
      state.showMyAnchors = !state.showMyAnchors;
    },

    getAnchorsList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onGetAnchorsListSuccess: (state, action: PayloadAction<AnchorsResponse>) => {
      state.loading = false;
      state.list = action.payload.anchors;
      state.meta = action.payload.meta;
    },
    onGetAnchorsListError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },

    getAnchorRecords: (
      state,
      action: PayloadAction<{ page?: number; pageSize?: number; anchorId: number }>
    ) => {
      state.loading = true;
    },
    onGetAnchorRecordsSuccess: (state, action: PayloadAction<RecordsResponse>) => {
      state.loading = false;
      state.anchorRecords = action.payload.records;
      state.anchorRecordsMeta = action.payload.meta;
    },
    onGetAnchorRecordsError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },

    anchorDetail: (
      state,
      _action: PayloadAction<{
        anchorId: string;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    onDetailAnchorsSuccess: (state, action: PayloadAction<ResponseAnchorDetails>) => {
      state.loading = false;
      state.anchorDetail = action.payload;
      state.error = '';
    },
    onDetailAnchorsError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while loading key';
          break;
        default:
          state.error = 'Error while loading key';
      }
    }
  }
});
