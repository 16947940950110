import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { authActions, metadataActions, profileActions } from '../redux/actions';
import { useAppSelector } from '../redux/store';
import { Route, getRouteFromPath, routes } from '../routes';
import { getCookie } from '../utils/cookie';
import { isJWTValid } from '../utils/jwt';
import { Actions, Resource, hasScope } from './scopes';

export type AuthProps = {
  token: string;
  userId: string;
};

export const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const session = getCookie('token');

  useEffect(() => {
    dispatch(authActions.setAuthToken(session));
  }, [session]);

  return <>{children}</>;
};

export const CheckScope: React.FC<{
  scopes?: {
    [key in Resource]?: Actions[];
  };
  redirectIfNotAllowed?: Route;
}> = ({ redirectIfNotAllowed, children, scopes }) => {
  const dispatch = useDispatch();
  const { token, jwtData } = useAppSelector((state) => state.auth);
  const [redirectTo, setRedirectTo] = useState('');

  const location = useLocation();
  const urlParams = useParams();
  const route = getRouteFromPath(location.pathname, urlParams);

  useEffect(() => {
    if (token && jwtData?.scopes) {
      dispatch(profileActions.profile());
      dispatch(
        metadataActions.subscriptionProducts(
          jwtData?.product && jwtData.product[0].metadata?.license
            ? jwtData.product[0].metadata?.license
            : undefined
        )
      );
    }
  }, [token, jwtData?.scopes]);

  useEffect(() => {
    if (jwtData?.scopes && scopes) {
      if (hasScope(jwtData ? jwtData : undefined, scopes)) {
        return;
      }
    }

    if (token === '') {
      setRedirectTo(routes.login.url);
    } else if (token !== undefined && jwtData?.scopes !== undefined) {
      setRedirectTo(routes.home.url);
    }
  }, [jwtData, scopes, token]);

  if (!route || token === undefined) {
    return <> </>;
  }

  if (redirectTo) {
    return <Redirect to={redirectIfNotAllowed ? redirectIfNotAllowed.url : redirectTo} />;
  } else {
    return <>{children}</>;
  }
};

export const RedirectIfAuthenticated: React.FC<{ redirectTo?: Route }> = ({
  redirectTo,
  children
}) => {
  const { token } = useAppSelector((state) => state.auth);

  if (token && isJWTValid(token)) {
    return <Redirect to={redirectTo ? redirectTo.url : routes.home.url} />;
  } else {
  }

  return <>{children}</>;
};
