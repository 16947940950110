import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { keyOperationsActions } from '../actions';
import { AppState } from '../reducers';
import {
  CountOperationsResponse,
  OperationsResponse,
  requestGetCountOperationsByDate,
  requestGetOperations,
  requestGetOperationsByKey
} from './requests';

function* getOperationsList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const { operationTypeFilter } = yield select((state: AppState) => state.keyOperations);

    const response: OperationsResponse = yield call(
      requestGetOperations as any,
      operationTypeFilter,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10
    );
    yield put(keyOperationsActions.onGetOperationsListSuccess(response));
  } catch (e: any) {
    yield put(
      keyOperationsActions.onGetOperationsListError({
        error: e
      })
    );
  }
}

function* getOperationsByKey(
  action: PayloadAction<{ keyId: string; page: number; pageSize: number }>
) {
  try {
    const { operationTypeFilter } = yield select((state: AppState) => state.keyOperations);

    const response: OperationsResponse = yield call(
      requestGetOperationsByKey as any,
      action.payload.keyId,
      operationTypeFilter,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10
    );
    yield put(keyOperationsActions.onGetOperationsByKeySuccess(response));
  } catch (e: any) {
    yield put(
      keyOperationsActions.onGetOperationsByKeyError({
        error: e
      })
    );
  }
}

function* getCountOperationsByDate(action: PayloadAction<{ date: string }>) {
  if (action.payload.date) {
    try {
      const response: CountOperationsResponse = yield call(
        requestGetCountOperationsByDate,
        action.payload.date
      );
      yield put(keyOperationsActions.onGetCountOperationsByDateSuccess(response));
    } catch (e: any) {
      yield put(
        keyOperationsActions.onGetCountOperationsByDateError({
          error: e
        })
      );
    }
  }
}

function* changeOperationTypeFilter() {
  yield put(keyOperationsActions.getOperationsList({}));
}

function* changeOperationByKeyTypeFilter(action: PayloadAction<{ keyId: string }>) {
  yield put(keyOperationsActions.getOperationsByKey({ keyId: action.payload.keyId }));
}

export default function* keyOperationsSaga() {
  yield takeLatest(keyOperationsActions.getOperationsList.type, getOperationsList);
  yield takeLatest(keyOperationsActions.getOperationsByKey.type, getOperationsByKey);
  yield takeLatest(keyOperationsActions.getCountOperationsByDate.type, getCountOperationsByDate);
  yield takeLatest(keyOperationsActions.changeOperationTypeFilter.type, changeOperationTypeFilter);
  yield takeLatest(
    keyOperationsActions.changeOperationByKeyTypeFilter.type,
    changeOperationByKeyTypeFilter
  );
}
