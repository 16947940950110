import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export default createSlice({
  name: 'reset-password',
  initialState: {
    loading: false,
    error: '' as string
  },
  reducers: {
    resetPassword: (state, _action: PayloadAction<{ password: string; token: string }>) => {
      state.loading = true;
    },
    onResetPasswordSuccess: (state) => {
      state.loading = false;
      toast.success('Your password was successfully reset', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onResetPasswordError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Password could not be saved';
          break;
        default:
          state.error = 'Unknown error';
      }
    }
  }
});
