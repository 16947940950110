import { Modal as MatModal } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../redux/actions';
import { ModalType } from '../../redux/modal';
import { useAppSelector } from '../../redux/store';
import { Icons } from '../../types/icons';
import { AddTOTP } from './add-access-control-totp';
import { ChangePasswordModal } from './change-password';
import { CreateApiKeyModal } from './create-api-key';
import { InfoModal } from './info';
import { resetAccessControlPassword } from './reset-access-control-password';
import { FeedbackModal } from './send-feedback';
import { AdevertiseModal } from './update-plan-advertise';
import { VerifyAccessControl } from './verify-access-control';

export type IModal = {
  headerLabel: string;
  headerIcon?: Icons | JSX.Element;
  headerIconColor?: string;
  body?: JSX.Element;
  success?: boolean;
  successDisabled?: boolean;
  successLabel?: string;
  successCallback?: () => void;
  dismiss?: boolean;
  dismissDisabled?: boolean;
  dismissLabel?: string;
  dismissCallback?: () => void;
};

export const ModalManager: React.FC = () => {
  const dispatch = useDispatch();
  const current = useAppSelector((state) => state.modal.active);

  if (!current) {
    return <></>;
  }

  let modalComponent: JSX.Element;
  switch (current.type) {
    case ModalType.CREATE_API_KEY_MODAL:
      modalComponent = React.createElement(CreateApiKeyModal, current.props);
      break;
    case ModalType.INFO_MODAL:
      modalComponent = React.createElement(InfoModal, current.props);
      break;
    case ModalType.FEEDBACK_MODAL:
      modalComponent = React.createElement(FeedbackModal, current.props);
      break;
    case ModalType.CHANGE_PASSWORD_MODAL:
      modalComponent = React.createElement(ChangePasswordModal, current.props);
      break;
    case ModalType.ADVERTISE_UPDATE_PLAN:
      modalComponent = React.createElement(AdevertiseModal, current.props);
      break;
    case ModalType.CREATE_ACCESS_CONTROL_TOTP:
      modalComponent = React.createElement(AddTOTP, current.props);
      break;
    case ModalType.RESET_ACCESS_CONTROL_PASSWORD:
      modalComponent = React.createElement(resetAccessControlPassword, current.props);
      break;
    case ModalType.VERIFY_ACCESS_CONTROL:
      modalComponent = React.createElement(VerifyAccessControl, current.props);
      break;
    default:
      modalComponent = <></>;
  }

  return (
    <MatModal
      open={true}
      disableScrollLock={false}
      onClose={(e: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (current.type === ModalType.CREATE_API_KEY_MODAL) {
          if (reason === 'backdropClick') {
            return null;
          }
        } else if (current.type === ModalType.CREATE_ACCESS_CONTROL_TOTP) {
          if (reason === 'backdropClick') {
            return current.props.callback();
          }
        } else {
          return dispatch(modalActions.close());
        }
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modalComponent}
    </MatModal>
  );
};
