import { call, put, select, takeLatest } from 'redux-saga/effects';
import { validationActions } from '../actions';
import { getAuthUserId } from '../auth';
import { AppState } from '../reducers';
import {
  HeatmapMetricsResponse,
  LineMetricsResponse,
  requestHeatmapMetrics,
  requestLineErrorMetrics,
  requestLineSuccessMetrics,
  requestTableMetrics,
  TableMetricsResponse
} from './requests';

function* changeFilter() {
  yield put(validationActions.lineSuccessMetrics());
  yield put(validationActions.lineErrorMetrics());
  yield put(validationActions.tableMetrics());
}

function* getLineMetrics() {
  try {
    const userId: string = yield select(getAuthUserId);
    const { window } = yield select((state: AppState) => state.validation);
    const response: LineMetricsResponse = yield call(
      requestLineSuccessMetrics as any,
      userId,
      window
    );
    yield put(validationActions.onLineSuccessMetricsSuccess(response));
  } catch (e: any) {
    yield put(
      validationActions.onLineSuccessMetricsError({
        error: e
      })
    );
  }
}
function* getLineErrorMetrics() {
  try {
    const userId: string = yield select(getAuthUserId);
    const { window } = yield select((state: AppState) => state.validation);
    const response: LineMetricsResponse = yield call(
      requestLineErrorMetrics as any,
      userId,
      window
    );
    yield put(validationActions.onLineErrorMetricsSuccess(response));
  } catch (e: any) {
    yield put(
      validationActions.onLineSuccessMetricsError({
        error: e
      })
    );
  }
}

function* getTableMetrics() {
  try {
    const userId: string = yield select(getAuthUserId);
    const { window } = yield select((state: AppState) => state.validation);
    const response: TableMetricsResponse = yield call(requestTableMetrics as any, userId, window);
    yield put(validationActions.onTableMetricsSuccess(response));
  } catch (e: any) {
    yield put(
      validationActions.onTableMetricsError({
        error: e
      })
    );
  }
}

function* getHeatmapMetrics() {
  try {
    const userId: string = yield select(getAuthUserId);
    const { window } = yield select((state: AppState) => state.validation);
    const response: HeatmapMetricsResponse = yield call(
      requestHeatmapMetrics as any,
      userId,
      window
    );
    yield put(validationActions.onHeatmapMetricsSuccess(response));
  } catch (e: any) {
    yield put(
      validationActions.onHeatmapMetricsError({
        error: e
      })
    );
  }
}

export default function* validationsSaga() {
  yield takeLatest(validationActions.lineSuccessMetrics.type, getLineMetrics);
  yield takeLatest(validationActions.lineErrorMetrics.type, getLineErrorMetrics);
  yield takeLatest(validationActions.tableMetrics.type, getTableMetrics);
  yield takeLatest(validationActions.heatmapMetrics.type, getHeatmapMetrics);
  yield takeLatest(validationActions.changeFilter.type, changeFilter);
}
