import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RcFile } from 'antd/lib/upload';
import { toast } from 'react-toastify';
import { Meta } from '../explorer';
import {
  IssuerCreateResponse,
  IssuerDetailResponse,
  IssuerFile,
  IssuerListResponse,
  ResponseIdentityClaimUsage,
  ResponseIdentityRevocationUsage
} from './requests';

export default createSlice({
  name: 'issuer',
  initialState: {
    loading: false,
    error: '' as string,
    issuerList: [] as IssuerFile[],
    issuerListMeta: {} as Meta,
    issuerDetails: {} as IssuerDetailResponse,
    revocationUsage: {} as ResponseIdentityRevocationUsage,
    claimUsage: {} as ResponseIdentityClaimUsage,
    issuerCreate: {} as IssuerCreateResponse,
    issuerId: '',
    identityTab: '1'
  },
  reducers: {
    switchIdentityTab: (state, action: PayloadAction<string>) => {
      state.identityTab = action.payload;
    },
    changeIssuerFilter: (state, action: PayloadAction<string>) => {
      state.issuerId = action.payload;
    },
    getIssuerList: (
      state,
      action: PayloadAction<{
        page?: number;
        pageSize?: number;
      }>
    ) => {
      state.loading = true;
    },
    onGetIssuerListSuccess: (state, action: PayloadAction<IssuerListResponse>) => {
      state.loading = false;
      state.issuerList = action.payload.issuers || [];
      state.issuerListMeta = action.payload.pagination.meta;
    },
    onGetIssuerListError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },

    issuerDetail: (
      state,
      _action: PayloadAction<{
        did: string;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    onIssuerDetailSuccess: (state, action: PayloadAction<IssuerDetailResponse>) => {
      state.loading = false;
      state.issuerDetails = action.payload;
      state.error = '';
    },
    onIssuerDetailError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while loading key';
          break;
        default:
          state.error = 'Error while loading key';
      }
    },

    deleteIssuerFile: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    onDeleteIssuerFileSuccess: (state) => {
      state.loading = false;
      toast.success('The file has been successfully deleted', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onDeleteIssuerFileError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while deleting the file';
          break;
        default:
          state.error = 'Error while deleting the file';
      }
      toast.error(state.error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },

    identityRevocationUsage: (state) => {
      state.loading = true;
    },
    onIdentityRevocationSuccess: (
      state,
      action: PayloadAction<ResponseIdentityRevocationUsage>
    ) => {
      state.loading = false;
      state.revocationUsage = action.payload;
    },
    onIdentityRevocationUsageError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    identityClaimUsage: (state) => {
      state.loading = true;
    },
    onIdentityClaimSuccess: (state, action: PayloadAction<ResponseIdentityClaimUsage>) => {
      state.loading = false;
      state.claimUsage = action.payload;
    },
    onIdentityClaimUsageError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    issuerCreate: (
      state,
      _action: PayloadAction<{
        name: string;
        description: string;
        did: string;
        interval: string;
        avatar?: RcFile;
      }>
    ) => {
      state.loading = true;
    },
    onIssuerCreateSuccess: (state, action: PayloadAction<IssuerCreateResponse>) => {
      state.loading = false;
      state.issuerCreate = action.payload;
    },
    onIssuerCreateError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Invalid format';
          break;
        default:
          state.error = 'Unknown error';
      }
      toast.error('The issuer creation failed to proceed', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    }

    // getCreateIssuerList: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
    //   state.loading = true;
    // },
    // getCreateIssueristSuccess: (state, action: PayloadAction<IssuerFile[]>) => {
    //   state.loading = false;
    //   state.issuerList = action.payload || [];
    // },
    // getCreateIssuerListError: (state, action: PayloadAction<{ error: number }>) => {
    //   switch (action.payload.error) {
    //     default:
    //       state.error = 'Error while getting the data';
    //   }
    // }
  }
});
