import { HttpClient } from '../../http/client';

export async function requestSignup(
  name: string,
  surname: string,
  email: string,
  password: string,
  country: string
) {
  const body = {
    name,
    surname,
    email,
    password,
    country
  };
  return await HttpClient.post('/users/v1/signup', body);
}
