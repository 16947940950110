import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Meta } from '../explorer';
import { DetailRecordsResponse, Records, RecordsResponse } from './requests';

export default createSlice({
  name: 'records',
  initialState: {
    recordFilter: '',
    loading: false,
    showMyRecords: true,
    network: '',
    error: '' as string,
    list: [] as Records[],
    meta: {} as Meta,
    recordDetail: {} as DetailRecordsResponse,
    integrityTab: '1'
  },
  reducers: {
    switchIntegrityTab: (state, action: PayloadAction<string>) => {
      state.integrityTab = action.payload;
    },
    changeRecordFilter: (state, action: PayloadAction<string>) => {
      state.recordFilter = action.payload;
    },
    toggleShowMyRecords: (state) => {
      state.showMyRecords = !state.showMyRecords;
    },

    getRecordsList: (
      state,
      action: PayloadAction<{
        page?: number;
        pageSize?: number;
        network?: string;
        anchorId?: number;
      }>
    ) => {
      state.loading = true;
    },
    onGetRecordsListSuccess: (state, action: PayloadAction<RecordsResponse>) => {
      state.loading = false;
      state.list = action.payload.records;
      state.meta = action.payload.meta;
    },
    onGetRecordsListError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },

    recordDetail: (state, _action: PayloadAction<string>) => {
      state.loading = true;
    },
    onDetailRecordsSuccess: (state, action: PayloadAction<DetailRecordsResponse>) => {
      state.loading = false;
      state.recordDetail = action.payload;
      state.error = '';
    },
    onDetailRecordsError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while loading key';
          break;
        default:
          state.error = 'Error while loading key';
      }
    }
  }
});
