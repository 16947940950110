import {
  AccessControl,
  AccessControlSecret,
  AccessControlTotp,
  AccessControlType
} from '@bloock/sdk';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Form } from '..';
import { certifierActions, modalActions } from '../../redux/actions';
import { CertifierForm } from '../../redux/certifier/requests';
import { useAppSelector } from '../../redux/store';
import Button from '../button';
import { TextInput } from '../form/text-input';
import { DefaultModal } from './default';

export const VerifyAccessControl: React.FC<any> = ({ callback = () => {} }) => {
  const { t } = useTranslation('keys');
  const dispatch = useDispatch();
  const { loading, haveAccessControlAuth, haveAccessControlEncryption, processData } =
    useAppSelector((state) => state.certifier);
  const [formData, setFormData] = useState<CertifierForm | null>(null);

  const defaultModalProps = {
    headerLabel: t('access_control.verify_access_control'),
    dismiss: false,
    success: false,
    successDisabled: true,

    onclose: () => {
      callback();
    },

    successCallback: () => {}
  };

  return (
    <DefaultModal {...defaultModalProps}>
      <div>
        <div className="justify-center"></div>

        <div className="pt-5 flex justify-center flex flex-row align-center">
          <div className="flex flex-row items-center w-full">
            <Form
              id="add-access-control"
              className="h-full mb-3 mt-4"
              schema={yup.object()}
              onChange={(data: any) => {
                setFormData(data);
              }}
              onSubmit={(data) => {
                dispatch(
                  certifierActions.sendDataToCertify({
                    authenticity: {
                      ...processData?.authenticity,
                      accessCode:
                        haveAccessControlAuth === AccessControlType.SECRET
                          ? new AccessControl(
                              new AccessControlSecret(data.authenticity?.accessControl)
                            )
                          : new AccessControl(
                              new AccessControlTotp(data.authenticity?.accessControl)
                            ),
                      accessControlType: haveAccessControlAuth,
                      accessControlEnabled: AccessControlType.NONE ? false : true
                    },
                    availability: {
                      ...processData?.availability
                    },
                    encryption: {
                      ...processData?.encryption,
                      accessCode:
                        haveAccessControlEncryption === AccessControlType.SECRET
                          ? new AccessControl(
                              new AccessControlSecret(data.encryption?.accessControl)
                            )
                          : new AccessControl(
                              new AccessControlTotp(data.encryption?.accessControl)
                            ),
                      accessControlType: haveAccessControlEncryption,
                      accessControlEnabled: AccessControlType.NONE ? false : true
                    },
                    integrity: {
                      ...processData?.integrity
                    }
                  })
                );

                dispatch(certifierActions.onShowVerifyAccessControlModal(false));
                dispatch(modalActions.close());
              }}
            >
              {haveAccessControlAuth ? (
                <div className="my-4">
                  <TextInput
                    label={
                      haveAccessControlAuth === AccessControlType.SECRET
                        ? `${t('access_control.auth_key_access_code')} (${t(
                            'access_control.edit_access_control_label_password'
                          )})`
                        : `${t('access_control.auth_key_access_code')} (${t(
                            'access_control.edit_access_control_label_totp'
                          )})`
                    }
                    name="authenticity.accessControl"
                    value={''}
                    required
                  ></TextInput>
                </div>
              ) : null}
              {haveAccessControlEncryption ? (
                <div className="my-4">
                  <TextInput
                    label={
                      haveAccessControlEncryption === AccessControlType.SECRET
                        ? `${t('access_control.encrypt_key_access_code')} (${t(
                            'access_control.edit_access_control_label_password'
                          )})`
                        : `${t('access_control.encrypt_key_access_code')} (${t(
                            'access_control.edit_access_control_label_totp'
                          )})`
                    }
                    name="encryption.accessControl"
                    value={''}
                    required
                  ></TextInput>
                </div>
              ) : null}

              <div className="flex justify-end mt-4">
                <Button type="submit">{t('submit')}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </DefaultModal>
  );
};
