import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { IPaymentData } from '.';
import { authActions, paymentActions } from '../actions';
import { getAuthUserId, getToken } from '../auth';
import { requestCreateSubscription, ResponseCreateSubscription } from './requests';

function* createSubscription(action: PayloadAction<IPaymentData>) {
  try {
    const token: string = yield select(getToken);

    const userId: string = yield select(getAuthUserId);
    const paymentInfo = action.payload;

    const response: ResponseCreateSubscription = yield call(
      requestCreateSubscription,
      paymentInfo,
      userId
    );
    yield put(authActions.refreshToken(token));

    yield put(paymentActions.onCreateSubscriptionSuccess(response));
  } catch (e: any) {
    yield put(
      paymentActions.onCreateSubscriptionError({
        code: e.code,
        message: e.messageCode
      })
    );
  }
}

export default function* paymentSaga() {
  yield takeLatest(paymentActions.createSubscription.type, createSubscription);
}
