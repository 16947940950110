import { Icons } from 'bloock-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../redux/actions';
import { useAppSelector } from '../../redux/store';
import { DefaultModal, IDefaultModal } from './default';

export type IAdvertiseModal = {
  headerLabel: string;
  dismiss?: boolean;
  dismissLabel?: string;
  successLabel?: string;
  messageFirstPart?: string;
  messageSecondPart?: string;
  linkToDocs?: string;
  callback: () => void;
};

export const AdevertiseModal: React.FC<IAdvertiseModal> = ({
  callback = () => {},
  dismiss = true,
  ...rest
}) => {
  const { t: t } = useTranslation();
  const { updateLoading } = useAppSelector((state) => state.userProduct);
  const dispatch = useDispatch();

  const defaultModalProps: IDefaultModal = {
    headerLabel: rest.headerLabel,
    dismiss: dismiss,
    dismissLabel: rest.dismissLabel ? rest.dismissLabel : t('cancel'),
    successLabel: rest.successLabel,
    isLoading: updateLoading,
    headerIcon: Icons.InfoIcon,
    width: 500,
    successCallback: () => {
      callback();
    },
    dismissCallback: () => {
      dispatch(modalActions.close());
    }
  };

  return (
    <DefaultModal {...defaultModalProps}>
      <div>
        <p className="text-sm pt-6">{rest.messageFirstPart}</p>
        <p className="mt-8 text-sm pb-4">{rest.messageSecondPart}</p>
        <a
          className="text-sm font-bold "
          href={rest.linkToDocs}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {rest.linkToDocs}
        </a>
      </div>
    </DefaultModal>
  );
};
