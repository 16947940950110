import { HttpClient } from '../../http/client';
import { Meta } from '../explorer';
import { DetailKeyResponse } from '../keys/requests';

export type CertificatesResponse = {
  keys: Certificate[];
  pagination: {
    meta: Meta;
  };
};

export type Certificate = {
  key_id: string;
  name: string;
  key_type: string;
  key_protection: number;
  pub_key: string;
  expiration: number;
  created_at: string;
};

export type ResetAccessControl = {
  certificateId?: string;
  email?: string;
  secret?: string;
  code?: number;
};

export async function requestGetCertificates(
  userId: string,
  page: number,
  pageSize: number
): Promise<CertificatesResponse> {
  const url = `/keys/v1/users/${userId}/certificates?page=${page}&per_page=${pageSize}`;
  return await HttpClient.get(url);
}

export async function requestDeleteCertificate(certificateId: string) {
  const url = `/keys/v1/certificates/${certificateId}`;
  return await HttpClient.delete(url);
}

export type CreateCertificateResponse = {
  certificate_id: string;
  key_type: string;
  key_protection: number;
  pub_key: string;
  expiration: number;
  subject?: {
    common_name?: string;
    organization?: string;
    organization_unit?: string;
    state_of_province?: string;
    country?: string;
    city?: string;
  };

  type?: 'ac_secret_based' | 'ac_time_based_otp';
  args?: any;
};

export async function requestCreateCertificate(
  key_type: string,
  subject: string,
  expiration_months: number
): Promise<CreateCertificateResponse> {
  const body = {
    subject,
    key_type,
    expiration: expiration_months
  };
  return await HttpClient.post('/keys/v1/certificates', body);
}

export type DetailCertificateResponse = {
  certificate_id: string;
  key: DetailKeyResponse;
  certificate: {
    issuer: {
      common_name: string;
      organization: string;
      organization_unit: string;
      state_of_province: string;
      country: string;
      city: string;
    };
    subject: {
      common_name: string;
      organization: string;
      organization_unit: string;
      state_of_province: string;
      country: string;
      city: string;
    };
    serial_number: string;
    not_before: number;
    not_after: string;
  };
  hex_der: string;
};

export async function requestDetailCertificate(
  certificateId: string
): Promise<DetailCertificateResponse> {
  return await HttpClient.get(`/keys/v1/certificates/${certificateId}`, {
    headers: {
      API_VERSION: '2023-11-03'
    }
  });
}

export type ImportCertificateResponse = {
  certificate_id: string;
  key_type: string;
  key_protection: number;
  pub_key: string;
  expiration: number;
};

export async function requestImportCertificate(
  file: string,
  password: string | null
): Promise<ImportCertificateResponse> {
  const body = {
    file,
    password
  };

  const formData = new FormData();
  formData.append('certificate', file);

  if (password) {
    formData.append('password', password);
  }
  return await HttpClient.post('/keys/v1/certificates/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
