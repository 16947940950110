import { HttpClient } from '../../http/client';
import { Meta } from '../explorer';

export type KeysResponse = {
  keys: Keys[];
  Pagination: {
    meta: Meta;
  };
};

export type Keys = {
  key_id: string;
  name: string;
  key_type: string;
  key_curve: string;
  key_size: number;
  key_protection: number;
  pub_key: string;
  expiration: number;
  created_at: string;
};

export async function requestGetKeys(
  userId: string,
  page: number,
  pageSize: number
): Promise<KeysResponse> {
  const url = `/keys/v1/users/${userId}/keys?page=${page}&per_page=${pageSize}`;
  return await HttpClient.get(url);
}

export async function requestDeleteKey(keyId: string) {
  const url = `/keys/v1/keys/${keyId}`;
  return await HttpClient.delete(url);
}

export type CreateKeyResponse = {
  key_id: string;
  name: string;
  key_type: string;
  key_protection: number;
  pub_key: string;
  expiration: Date;
};

export async function requestCreateKey(
  name: string,
  key_type: string,
  protection_level: number,
  expiration: number | null
): Promise<CreateKeyResponse> {
  const body = {
    name,
    key_type,
    protection_level,
    expiration: expiration
  };

  return await HttpClient.post('/keys/v1/keys', body);
}

export type DetailKeyResponse = {
  key_id: string;
  name: string;
  key_type: string;
  key_protection: number;
  pub_key: string;
  expiration: number;
  created_at: string;
  access_control_type?: string;
  args?: Record<string, string>;
};

export async function requestDetailKey(keyId: string): Promise<DetailKeyResponse> {
  return await HttpClient.get(`/keys/v1/keys/${keyId}`, {
    headers: {
      API_VERSION: '2023-11-03'
    }
  });
}
