import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { routes } from '../../routes';
import { accessControlActions, certificatesActions, modalActions } from '../actions';
import { getAuthUserId } from '../auth';
import {
  CertificatesResponse,
  CreateCertificateResponse,
  DetailCertificateResponse,
  ImportCertificateResponse,
  requestCreateCertificate,
  requestDeleteCertificate,
  requestDetailCertificate,
  requestGetCertificates,
  requestImportCertificate
} from './requests';

function* getCertificateList(action: PayloadAction<{ page: number; pageSize: number }>) {
  try {
    const userId: string = yield select(getAuthUserId);

    const response: CertificatesResponse = yield call(
      requestGetCertificates as any,
      userId,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10
    );
    yield put(certificatesActions.onGetCertificateListSuccess(response));
  } catch (e: any) {
    yield put(
      certificatesActions.onGetCertificateListError({
        error: e
      })
    );
  }
}

function* deleteCertificate(action: PayloadAction<string>) {
  try {
    yield call(requestDeleteCertificate, action.payload);
    yield put(certificatesActions.onDeleteCertificateSuccess());
    yield put(modalActions.close());
    yield put(certificatesActions.getCertificateList({}));
  } catch (e: any) {
    yield put(modalActions.close());
    yield put(certificatesActions.getCertificateList({}));
  }
}

function* createCertificate(action: PayloadAction<CreateCertificateResponse>) {
  try {
    let subject = `CN=${action.payload.subject?.common_name}`;
    if (action.payload.subject?.organization_unit) {
      subject += `,OU=${action.payload.subject?.organization_unit}`;
    }
    if (action.payload.subject?.organization) {
      subject += `,O=${action.payload.subject?.organization}`;
    }
    if (action.payload.subject?.city) {
      subject += `,L=${action.payload.subject?.city}`;
    }
    if (action.payload.subject?.state_of_province) {
      subject += `,S=${action.payload.subject?.state_of_province}`;
    }
    if (action.payload.subject?.country) {
      subject += `,C=${action.payload.subject?.country}`;
    }

    const { key_type, expiration, args, type } = action.payload;
    const response: CreateCertificateResponse = yield call(
      requestCreateCertificate,
      key_type,
      subject,
      expiration
    );

    yield put(certificatesActions.onCreateCertificateSuccess(response));

    if (type) {
      yield put(
        accessControlActions.addAccessControl({
          args,
          type,
          keyId: response.certificate_id,
          actionType: 'create-key'
        })
      );
    } else {
      yield put(push(routes.keys.url));
    }
  } catch (e: any) {
    yield put(certificatesActions.onCreateCertificateError(e));
  }
}

function* detailCertificate(
  action: PayloadAction<{
    certificateId: string;
  }>
) {
  try {
    const response: DetailCertificateResponse = yield call(
      requestDetailCertificate,
      action.payload.certificateId
    );

    yield put(certificatesActions.onDetailCertificateSuccess(response));
  } catch (e: any) {
    yield put(certificatesActions.onDetailCertificateError({ error: e }));
  }
}

function* importCertificate(
  action: PayloadAction<{
    file: string;
    password: string | null;
  }>
) {
  try {
    const { file, password } = action.payload;
    const response: ImportCertificateResponse = yield call(
      requestImportCertificate,
      file,
      password
    );

    yield put(certificatesActions.onImportCertificateSuccess(response));
    yield put(push({ pathname: routes.keys.url }));
  } catch (e: any) {
    yield put(
      certificatesActions.onImportCertificateError({
        error: e
      })
    );
  }
}

export default function* certificatesSaga() {
  yield takeLatest(certificatesActions.getCertificateList.type, getCertificateList);
  yield takeLatest(certificatesActions.deleteCertificate.type, deleteCertificate);
  yield takeLatest(certificatesActions.createCertificate.type, createCertificate);
  yield takeLatest(certificatesActions.detailCertificate.type, detailCertificate);
  yield takeLatest(certificatesActions.importCertificate.type, importCertificate);
}
