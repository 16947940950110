import { KeyType } from '@bloock/sdk';

export const keyTypeFromString = (keyType?: string): KeyType | undefined => {
  switch (keyType) {
    case 'Aes128':
      return KeyType.Aes128;
    case 'Aes156':
      return KeyType.Aes256;
    case 'Rsa2048':
      return KeyType.Rsa2048;
    case 'Rsa3072':
      return KeyType.Rsa3072;
    case 'Rsa4096':
      return KeyType.Rsa4096;
    case 'EcP256k':
      return KeyType.EcP256k;
    case 'BJJ':
      return KeyType.Bjj;
  }
};
