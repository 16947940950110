import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export type ResponseConfiguration = {
  name: string;
  website: string;
  line_1: string;
  line_2: string;
  country: {
    code: number;
    name: string;
  };
  city: string;
  postal_code: string;
  vat_id: string;
};
export type ICheckoutResponse = {
  checkout_id: string;
};

export type IPaymentMethodInfo = {
  card: {
    month: number;
    year: number;
    brand: string;
    digits: number;
  };
  payment_sepa: {
    digits: number;
    country: {
      value: string;
      label: string;
    };
  };
};

export type IPaymentMethodInfoEdit = {
  successURL: string;
  cancelURL: string;
};

export type IEditBillingInfo = {
  city: string;
  country: string;
  line1: string;
  line2: string;
  name: string;
  payment_method_id: string;
  postal_code: string;
  user_email: string;
  vat_id: string;
};

export type IBillingInfo = {
  city: string;
  country: {
    label?: string;
    value: string;
  };
  line1: string;
  line2: string;
  name: string;
  payment_method_id: string;
  postal_code: string;
  user_email: string;
  vat_id: string;
};

export default createSlice({
  name: 'configuration',
  initialState: {
    edit: {
      loading: false,
      error: '' as string
    },
    billing_info: {} as IBillingInfo,
    paymentMethodInfo: {} as IPaymentMethodInfo,

    isEditingCompanyDetails: false,
    isEditingOtherCompanyDetails: false,
    loading: true,
    error: '' as string,
    isEditingBillingDetails: false,
    isEditingPaymentMethod: false,
    checkoutId: '',
    isEditingPlan: false
  },
  reducers: {
    configuration: (state) => {
      state.loading = true;
    },
    onConfigurationSuccess: (state, action: PayloadAction<ResponseConfiguration>) => {
      state.loading = false;
      state.error = '';
    },
    onConfigurationError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the user information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    billingInfo: (state) => {
      state.loading = true;
    },
    onBillingInfoSuccess: (state, action: PayloadAction<IBillingInfo>) => {
      state.loading = false;
      state.error = '';
      state.billing_info = action.payload;
    },

    onBillingInfoError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;

      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the billing information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },

    toggleIsEditingBilling: (state) => {
      state.isEditingBillingDetails = !state.isEditingBillingDetails;
    },

    billingEdit: (state, action: PayloadAction<IEditBillingInfo>) => {
      state.loading = true;
    },
    onBillingEditSuccess: (state) => {
      state.loading = false;
      toast.success('Your billing information was successfully updated', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onBillingEditError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.edit.error = 'Error while saving billing information';
          break;
        default:
          toast.error('Error while saving billing information', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: true,
            draggable: true,
            progress: undefined
          });
          state.loading = false;
      }
    },
    toggleIsEditingPaymentMethod: (state) => {
      state.isEditingPaymentMethod = !state.isEditingPaymentMethod;
    },
    paymentMethodInfo: (state) => {
      state.loading = true;
    },
    onPaymentMethodInfoSuccess: (state, action: PayloadAction<IPaymentMethodInfo>) => {
      state.loading = false;
      state.error = '';
      state.paymentMethodInfo = action.payload;
    },

    onPaymentMethodInfoError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while getting the payment method information';
          break;
        default:
          state.error = 'Unknown error';
      }
    },
    paymentMethodInfoEdit: (state, _action: PayloadAction<IPaymentMethodInfoEdit>) => {
      state.loading = true;
    },
    onPaymentMethodInfoEditSuccess: (state, action: PayloadAction<{ checkout_id: string }>) => {
      state.checkoutId = action.payload.checkout_id;
      state.loading = false;
    },
    onPaymentMethodInfoEditError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.edit.error = 'Error while saving payment method information';
          break;
        default:
          state.edit.error = 'Error while saving data, please try again';
      }
    }
  }
});
