import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { InputHTMLAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Error from '../error';

export type ICheckBoxInput = {
  name: string;
  label?: string;
  description?: string;
  checked?: boolean;
};

const CheckBoxInput: React.VFC<ICheckBoxInput & InputHTMLAttributes<HTMLInputElement>> = ({
  name = '',
  label = '',
  description = '',
  checked = false,
  ...rest
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const useStyles = makeStyles({
    root: {
      width: 9,
      height: 9,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    icon: {
      fontWeight: 'lighter',
      borderRadius: 3,
      width: 9,
      height: 9,
      backgroundColor: 'transparent',
      color: 'var(--secondary-color) !important'
    }
  });

  const classes = useStyles();

  return (
    <>
      <div className={`flex align-start ${rest.className}`}>
        <span>
          <Controller
            key={name}
            name={name}
            defaultValue={false}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    size="small"
                    className={classes.icon}
                    color="secondary"
                    onChange={(e: any) => {
                      field.onChange(e.target.checked);
                      rest.onChange && rest.onChange(e);
                    }}
                    defaultChecked={field.value === true ? field.value : checked}
                  ></Checkbox>
                }
                label=""
              />
            )}
          />
        </span>
        <div className="flex flex-col justify-center">
          <label>{label}</label>
          {description ? (
            <p style={{ fontSize: '14px', color: 'var(--tertiary-text-color)', paddingTop: '3px' }}>
              {description}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      {errors[name] && <Error error={errors[name]}></Error>}
    </>
  );
};

export { CheckBoxInput };
