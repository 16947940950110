import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Form } from '..';
import { accessControlActions, modalActions } from '../../redux/actions';
import { useAppSelector } from '../../redux/store';
import { TextInput } from '../form/text-input';
import { DefaultModal } from './default';

export type IResetAccessControlPassowrd = {
  certificateId: string;
  email?: string;
};

export const resetAccessControlPassword: React.FC<IResetAccessControlPassowrd> = ({
  certificateId,
  email
}) => {
  const { t } = useTranslation('apikeys-create');
  const { t: tl } = useTranslation('keys');
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<{ email: string; secret: string; code: any }>();

  const { emailSended } = useAppSelector((state) => state.accessControl);

  const defaultModalProps = {
    headerLabel: tl('access_control.access_control_reset_label'),
    dismiss: false,
    success: true,
    successDisabled: false,
    successLabel: t('continue'),
    onclose: () => dispatch(accessControlActions.onOpenResetSecretAccessControlModal(false)),

    successCallback: () => {
      emailSended
        ? (dispatch(
            accessControlActions.onResetSecretAccessControl({
              certificateId: certificateId,
              email: email,
              code: parseInt(formData?.code),
              secret: formData?.secret
            })
          ),
          dispatch(accessControlActions.onOpenResetSecretAccessControlModal(false)),
          dispatch(modalActions.close()))
        : dispatch(
            accessControlActions.onRecoverySecretAccessControl({
              certificateId: certificateId
            })
          );
    }
  };

  return (
    <DefaultModal {...defaultModalProps}>
      <div className="pt-5 ">
        <div className="justify-center"></div>

        <div className="pt-5 flex justify-center flex flex-row align-center">
          {!emailSended ? (
            <div className="mb-5">
              <h6 className="mb-3">{tl('access_control.advertise_change_secret')}</h6>
              <p>{tl('access_control.advertise_change_secret_email')}</p>
            </div>
          ) : (
            <div className="flex flex-row items-center w-full">
              <Form
                id="add-access-control"
                className="flex-1"
                schema={yup.object()}
                onChange={(data: any) => {
                  setFormData(data);
                }}
              >
                <div className="h-full flex flex-col justify-between">
                  <Controller
                    name="reset-access"
                    render={({ field }) => (
                      <>
                        <TextInput
                          name="secret"
                          className="mb-6"
                          required
                          label={tl('access_control.new_password')}
                        />
                        <TextInput
                          name="code"
                          className="mb-6"
                          required
                          label={tl('access_control.recovery_code')}
                        />
                      </>
                    )}
                  />
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </DefaultModal>
  );
};
