import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export default createSlice({
  name: 'verifyAccount',
  initialState: {
    loading: false,
    error: '' as string
  },
  reducers: {
    verifyAccount: (_, _action: PayloadAction<{ token: string }>) => {},

    onVerifyAccountSuccess: (state) => {
      state.loading = false;
    },

    onVerifyAccountError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 4012:
          state.error = 'Incorrect name/email/password';
          break;
        default:
          state.error = 'Error while verifing account, please try again';
      }
    }
  }
});
