import { call, put, select, takeLatest } from 'redux-saga/effects';
import { licensesActions } from '../actions';
import { getAuthUserId } from '../auth';
import { requestGetLicenses, ResponseLicenses } from './requests';

function* licenses() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseLicenses = yield call(requestGetLicenses, userId);
    yield put(licensesActions.onGetLicensesSuccess(response));
  } catch (e: any) {
    yield put(
      licensesActions.onGetLicensesError({
        error: e
      })
    );
  }
}

export default function* licensesSaga() {
  yield takeLatest(licensesActions.licenses.type, licenses);
}
