import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { UpdateUsage } from '.';
import { usageActions } from '../actions';
import { getAuthUserId } from '../auth';
import {
  ResponseAvailabilityStorageUsage,
  ResponseAvailabilityTransferUsage,
  ResponseInfrastructureRequestUsage,
  ResponseKeysTransactionsUsage,
  ResponseKeysUsage,
  ResponseRecordUsage,
  ResponseUpdateLimit,
  requestAvailabilityStorageUsage,
  requestAvailabilityTransferUsage,
  requestInfrastructureRequestUsage,
  requestKeysTransactionsUsage,
  requestKeysUsage,
  requestRecordUsage,
  requestUpdateAvailabilityStorageLimit,
  requestUpdateAvailabilityTransferLimit,
  requestUpdateHsmKeysLimit,
  requestUpdateInfrastructureRequestLimit,
  requestUpdateKeysTransactionsLimit,
  requestUpdateRecordLimit,
  requestUpdateSoftwareKeysLimit
} from './requests';

function* getRecordUsage() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseRecordUsage = yield call(requestRecordUsage, userId);
    yield put(usageActions.onRecordUsageSuccess(response));
  } catch (e: any) {
    yield put(
      usageActions.onRecordUsageError({
        error: e
      })
    );
  }
}

function* updateRecordUsage(action: PayloadAction<UpdateUsage>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const limit = action.payload.limitType === 'unlimited' ? -1 : action.payload.limitPrice;
    const _: ResponseUpdateLimit = yield call(requestUpdateRecordLimit, userId, limit);
    yield put(usageActions.onUpdateRecordUsageLimitSuccess());
    yield put(usageActions.recordUsage());
  } catch (e: any) {
    yield put(
      usageActions.onUpdateRecordUsageLimitError({
        error: e
      })
    );
  }
}

function* getInfrastructureRequestUsage() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseInfrastructureRequestUsage = yield call(
      requestInfrastructureRequestUsage,
      userId
    );
    yield put(usageActions.onInfrastructureRequestUsageSuccess(response));
  } catch (e: any) {
    yield put(
      usageActions.onInfrastructureRequestUsageError({
        error: e
      })
    );
  }
}

function* updateInfrastructureRequestUsage(action: PayloadAction<UpdateUsage>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const limit = action.payload.limitType === 'unlimited' ? -1 : action.payload.limitPrice;
    const _: ResponseUpdateLimit = yield call(
      requestUpdateInfrastructureRequestLimit,
      userId,
      limit
    );
    yield put(usageActions.onUpdateInfrastructureRequestUsageLimitSuccess());
    yield put(usageActions.infrastructureRequestUsage());
  } catch (e: any) {
    yield put(
      usageActions.onUpdateInfrastructureRequestUsageLimitError({
        error: e
      })
    );
  }
}

function* getAvailabilityStorageUsage() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseAvailabilityStorageUsage = yield call(
      requestAvailabilityStorageUsage,
      userId
    );
    yield put(usageActions.onAvailabilityStorageUsageSuccess(response));
  } catch (e: any) {
    yield put(
      usageActions.onAvailabilityStorageUsageError({
        error: e
      })
    );
  }
}

function* updateAvailabilityStorageUsage(action: PayloadAction<UpdateUsage>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const limit = action.payload.limitType === 'unlimited' ? -1 : action.payload.limitPrice;
    const _: ResponseUpdateLimit = yield call(requestUpdateAvailabilityStorageLimit, userId, limit);
    yield put(usageActions.onUpdateAvailabilityStorageUsageLimitSuccess());
    yield put(usageActions.availabilityStorageUsage());
  } catch (e: any) {
    yield put(
      usageActions.onUpdateAvailabilityStorageUsageLimitError({
        error: e
      })
    );
  }
}

function* getAvailabilityTransferUsage() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseAvailabilityTransferUsage = yield call(
      requestAvailabilityTransferUsage,
      userId
    );
    yield put(usageActions.onAvailabilityTransferUsageSuccess(response));
  } catch (e: any) {
    yield put(
      usageActions.onAvailabilityTransferUsageError({
        error: e
      })
    );
  }
}

function* updateAvailabilityTransferUsage(action: PayloadAction<UpdateUsage>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const limit = action.payload.limitType === 'unlimited' ? -1 : action.payload.limitPrice;
    const _: ResponseUpdateLimit = yield call(
      requestUpdateAvailabilityTransferLimit,
      userId,
      limit
    );
    yield put(usageActions.onUpdateAvailabilityTransferUsageLimitSuccess());
    yield put(usageActions.availabilityTransferUsage());
  } catch (e: any) {
    yield put(
      usageActions.onUpdateAvailabilityTransferUsageLimitError({
        error: e
      })
    );
  }
}

function* getKeysUsage() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseKeysUsage = yield call(requestKeysUsage, userId);
    yield put(usageActions.onKeysUsageSuccess(response));
  } catch (e: any) {
    yield put(
      usageActions.onKeysUsageError({
        error: e
      })
    );
  }
}

function* updateSoftwareKeysUsage(action: PayloadAction<UpdateUsage>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const limit = action.payload.limitType === 'unlimited' ? -1 : action.payload.limitPrice;
    const _: ResponseUpdateLimit = yield call(requestUpdateSoftwareKeysLimit, userId, limit);
    yield put(usageActions.onUpdateSoftwareKeysUsageLimitSuccess());
    yield put(usageActions.keysUsage());
  } catch (e: any) {
    yield put(
      usageActions.onUpdateSoftwareKeysUsageLimitError({
        error: e
      })
    );
  }
}

function* updateHsmKeysUsage(action: PayloadAction<UpdateUsage>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const limit = action.payload.limitType === 'unlimited' ? -1 : action.payload.limitPrice;
    const _: ResponseUpdateLimit = yield call(requestUpdateHsmKeysLimit, userId, limit);
    yield put(usageActions.onUpdateHsmKeysUsageLimitSuccess());
    yield put(usageActions.keysUsage());
  } catch (e: any) {
    yield put(
      usageActions.onUpdateHsmKeysUsageLimitError({
        error: e
      })
    );
  }
}

function* getKeysTransactionsUsage() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: ResponseKeysTransactionsUsage = yield call(
      requestKeysTransactionsUsage,
      userId
    );
    yield put(usageActions.onKeysTransactionsUsageSuccess(response));
  } catch (e: any) {
    yield put(
      usageActions.onKeysTransactionsUsageError({
        error: e
      })
    );
  }
}

function* updateKeysTransactionsUsage(action: PayloadAction<UpdateUsage>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const limit = action.payload.limitType === 'unlimited' ? -1 : action.payload.limitPrice;
    const _: ResponseUpdateLimit = yield call(requestUpdateKeysTransactionsLimit, userId, limit);
    yield put(usageActions.onUpdateKeysTransactionsUsageLimitSuccess());
    yield put(usageActions.keysTransactionsUsage());
  } catch (e: any) {
    yield put(
      usageActions.onUpdateKeysTransactionsUsageLimitError({
        error: e
      })
    );
  }
}

export default function* usageSaga() {
  yield takeLatest(usageActions.recordUsage.type, getRecordUsage);
  yield takeLatest(usageActions.updateRecordUsageLimit.type, updateRecordUsage);
  yield takeLatest(usageActions.infrastructureRequestUsage.type, getInfrastructureRequestUsage);
  yield takeLatest(
    usageActions.updateInfrastructureRequestUsageLimit.type,
    updateInfrastructureRequestUsage
  );
  yield takeLatest(usageActions.availabilityStorageUsage.type, getAvailabilityStorageUsage);
  yield takeLatest(
    usageActions.updateAvailabilityStorageUsageLimit.type,
    updateAvailabilityStorageUsage
  );
  yield takeLatest(usageActions.availabilityTransferUsage.type, getAvailabilityTransferUsage);
  yield takeLatest(
    usageActions.updateAvailabilityTransferUsageLimit.type,
    updateAvailabilityTransferUsage
  );
  yield takeLatest(usageActions.keysUsage.type, getKeysUsage);

  yield takeLatest(usageActions.updateHsmKeysUsageLimit.type, updateHsmKeysUsage);
  yield takeLatest(usageActions.updateSoftwareKeysUsageLimit.type, updateSoftwareKeysUsage);

  yield takeLatest(usageActions.keysTransactionsUsage.type, getKeysTransactionsUsage);
  yield takeLatest(usageActions.updateKeysTransactionsUsageLimit.type, updateKeysTransactionsUsage);
}
