import { HttpClient } from '../../http/client';

export async function requestResetPassword(password: string, token: string) {
  const body = {
    password,
    token
  };

  return await HttpClient.post('/credentials/v1/password/reset', body);
}
