import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { anchorsActions } from '../actions';
import { getAuthUserId } from '../auth';
import { RecordsResponse } from '../records/requests';
import { AppState } from '../reducers';
import { AnchorsResponse, getAnchorList, requestAnchorRecords } from './requests';

function* getAnchorsList(action: PayloadAction<{ page?: number; pageSize?: number }>) {
  try {
    const userId: string = yield select(getAuthUserId);
    const { anchorId, network, showMyAnchors } = yield select((state: AppState) => state.anchors);

    const response: AnchorsResponse = yield call(
      getAnchorList,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10,
      showMyAnchors ? userId : undefined,
      anchorId
    );
    yield put(anchorsActions.onGetAnchorsListSuccess(response));
  } catch (e: any) {
    yield put(
      anchorsActions.onGetAnchorsListError({
        error: e
      })
    );
  }
}

function* getAnchorRecords(
  action: PayloadAction<{ page?: number; pageSize?: number; anchorId: number }>
) {
  try {
    const response: RecordsResponse = yield call(
      requestAnchorRecords as any,
      action.payload.page ? action.payload.page : 1,
      action.payload.pageSize ? action.payload.pageSize : 10,
      action.payload.anchorId
    );
    yield put(anchorsActions.onGetAnchorRecordsSuccess(response));
  } catch (e: any) {
    yield put(
      anchorsActions.onGetAnchorRecordsError({
        error: e
      })
    );
  }
}

function* changeAnchorFilter() {
  yield put(anchorsActions.getAnchorsList({}));
}

function* toggleShowMyAnchors() {
  yield put(anchorsActions.getAnchorsList({}));
}

export default function* keysSaga() {
  yield takeLatest(anchorsActions.getAnchorsList.type, getAnchorsList);
  yield takeLatest(anchorsActions.getAnchorRecords.type, getAnchorRecords);
  yield takeLatest(anchorsActions.changeAnchorFilter.type, changeAnchorFilter);
  yield takeLatest(anchorsActions.toggleShowMyAnchors.type, toggleShowMyAnchors);
}
