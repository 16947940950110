import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Log = {
  id: string;
  type: string;
  user_id: string;
  status: string;
  status_code: number;
  method: string;
  path: string;
  request_id: string;
  created_at: string;
  ip_address: string;
  request_body: Record<string, unknown>;
  response_body: Record<string, unknown>;
};

export type Meta = {
  current_page: number;
  per_page: number;
  from: number;
  to: number;
  total: number;
  last_page: number;
};

export default createSlice({
  name: 'logs',
  initialState: {
    status: '',
    logsList: [] as Log[],
    loading: false,
    selectedLog: undefined as Log | undefined,
    selectedLogLoading: false,
    meta: {} as Meta,
    error: '' as string
  },

  reducers: {
    changeStatusFilter: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    logs: (state, action: PayloadAction<{ page?: number; pageSize?: number }>) => {
      state.loading = true;
    },
    onLogsSuccess: (
      state,
      action: PayloadAction<{
        activities: Log[];
        meta: Meta;
      }>
    ) => {
      state.logsList = action.payload.activities;
      state.meta = action.payload.meta;
      state.loading = false;
    },
    onLogsError: (state, action: PayloadAction<{ error: number }>) => {
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    selectLog: (state, action: PayloadAction<{ id?: string }>) => {
      state.selectedLogLoading = true;
    },
    onSelectLogSuccess: (state, action: PayloadAction<Log>) => {
      state.selectedLog = action.payload;
      state.selectedLogLoading = false;
    },
    onSelectLogError: (state, action: PayloadAction<{ error: number }>) => {
      state.selectedLogLoading = false;
      switch (action.payload.error) {
        default:
          state.error = 'Error while getting the data';
      }
    },
    clearSelectLog: (state, action: PayloadAction) => {
      state.selectedLog = undefined;
    }
  }
});
