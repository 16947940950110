import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectData } from '../../components/form/select-input';

export default createSlice({
  name: 'signup',
  initialState: {
    success: false,
    loading: false,
    error: '' as string,
    areTermsAccepted: false
  },
  reducers: {
    signup: (
      state,
      action: PayloadAction<{
        name: string;
        surname: string;
        email: string;
        password: string;
        country: SelectData;
      }>
    ) => {
      state.loading = true;
    },
    toggleAreTermsAccepted: (state) => {
      state.areTermsAccepted = !state.areTermsAccepted;
    },
    onSignupSuccess: (state) => {
      state.loading = false;
      state.success = true;
    },

    toggleSuccess: (state) => {
      state.success = !state.success;
    },

    onSignupError: (state, action: PayloadAction<{ code: number }>) => {
      state.loading = false;
      state.areTermsAccepted = false;
      switch (action.payload.code) {
        case 4012:
          state.error = 'Incorrect name/email/password';
          break;
        default:
          state.error = 'Error while signup, please try again';
      }
    }
  }
});
