import { TotpAccessControlReceipt } from '@bloock/sdk/dist/entity/key/totp_access_control_receipt';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AccessControlInfo, ResponseEditAccessControl } from '.';
import { routes } from '../../routes';
import { accessControlActions } from '../actions';
import { AppState } from '../reducers';
import {
  ResetAccessControl,
  requestAccessControlInfo,
  requestAddSecretAccessControl,
  requestAddTotpAccessControl,
  requestEditAccessControl,
  requestRecoverySecretAccessControl,
  requestResetSecretAccessControl
} from './requests';

function* addAccessControl(
  action: PayloadAction<{
    type: 'ac_secret_based' | 'ac_time_based_otp';
    args: Record<string, string>;
    keyId: '';
    actionType?: string;
  }>
) {
  try {
    const { token } = yield select((state: AppState) => state.auth);

    if (action.payload.keyId) {
      switch (action.payload.type) {
        case 'ac_secret_based':
          const _: void = yield call(
            requestAddSecretAccessControl,
            action.payload.keyId,
            action.payload.args['password'],
            action.payload.args['email'],
            token
          );

          yield put(accessControlActions.onAddAccessControlSecretSuccess());
          if (action.payload.actionType === 'create-key') {
            yield put(push(routes.keys.url));
          } else {
            null;
          }
          break;
        case 'ac_time_based_otp':
          const response: TotpAccessControlReceipt = yield call(
            requestAddTotpAccessControl,
            action.payload.keyId,
            token
          );
          yield put(accessControlActions.onAddAccessControlTOTPSuccess(response));
          break;
      }
    }
  } catch (e: any) {
    yield put(
      accessControlActions.onAddAccessControlError({
        error: e
      })
    );
  }
}

function* resetSecretAccessControl(action: PayloadAction<any>) {
  try {
    const { certificateId, email, secret, code } = action.payload;
    const response: ResetAccessControl = yield call(
      requestResetSecretAccessControl,
      certificateId,
      email,
      secret,
      code
    );

    yield put(accessControlActions.onResetSecretAccessControlSuccess());
  } catch (e: any) {
    yield put(
      accessControlActions.onResetSecretAccessControlError({
        error: e
      })
    );
  }
}

function* recoverySecretAccessControl(action: PayloadAction<any>) {
  try {
    const { certificateId } = action.payload;
    const response: ResetAccessControl = yield call(
      requestRecoverySecretAccessControl,
      certificateId
    );

    yield put(accessControlActions.onResetSecretAccessControlSuccess());
    yield put(accessControlActions.onResetSecretAccessControlSuccess());
  } catch (e: any) {
    yield put(
      accessControlActions.onResetSecretAccessControlError({
        error: e
      })
    );
  }
}

function* accessControlInfo(
  action: PayloadAction<{
    keyId: string;
  }>
) {
  try {
    const response: AccessControlInfo = yield call(requestAccessControlInfo, action.payload.keyId);

    yield put(accessControlActions.onGetAccessControlInfoSuccess(response));
  } catch (e: any) {
    yield put(
      accessControlActions.onGetAccessControlInfoError({
        error: e
      })
    );
  }
}

function* editAccessControl(action: PayloadAction<any>) {
  try {
    const { keyId, access_code, secret, email } = action.payload;
    const response: ResponseEditAccessControl = yield call(
      requestEditAccessControl,
      keyId,
      email,
      secret,
      access_code
    );

    yield put(accessControlActions.onEditAccessControlSuccess(response));
  } catch (e: any) {
    yield put(
      accessControlActions.onEditAccessControlError({
        error: e
      })
    );
  }
}

export default function* accessControlSaga() {
  yield takeLatest(accessControlActions.addAccessControl.type, addAccessControl);
  yield takeLatest(accessControlActions.onResetSecretAccessControl.type, resetSecretAccessControl);
  yield takeLatest(
    accessControlActions.onRecoverySecretAccessControl.type,
    recoverySecretAccessControl
  );
  yield takeLatest(accessControlActions.onGetAccessControlInfo.type, accessControlInfo);
  yield takeLatest(accessControlActions.onEditAccessControl.type, editAccessControl);
}
