import { HttpClient } from '../../http/client';

export type LineMetricsResponse = {
  date: string;
  validations: number;
  success: boolean;
  record: string;
}[];

export type TableMetricsResponse = {
  hash: string;
  validations: number;
}[];

export type HeatmapMetricsResponse = {
  date: string;
  validations: number;
  month: number;
  day: number;
  week: number;
  hash: string;
  lastWeek: boolean;
  lastDay: boolean;
}[];

export async function requestLineSuccessMetrics(
  _userId: string,
  window: number
): Promise<LineMetricsResponse> {
  return await HttpClient.get(
    `/events/v1/activities/statistics/chart/lines?&window=${window}&success=true`
  );
}

export async function requestLineErrorMetrics(
  userId: string,
  window: number
): Promise<LineMetricsResponse> {
  return await HttpClient.get(
    `/events/v1/activities/statistics/chart/lines?&window=${window}&success=false`
  );
}

export async function requestTableMetrics(
  userId: string,
  window: number
): Promise<TableMetricsResponse> {
  return await HttpClient.get(
    `/events/v1/activities/statistics/count/record?&window=${window}&limit=5`
  );
}

export async function requestHeatmapMetrics(userId: string): Promise<HeatmapMetricsResponse> {
  return await HttpClient.get(`/events/v1/activities/statistics/chart/heatmap?&window=29030400`);
}
