import { TotpAccessControlReceipt } from '@bloock/sdk/dist/entity/key/totp_access_control_receipt';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Meta } from '../explorer';
import { ResetAccessControl } from './requests';

export type CreateAccessControl = {
  keyId?: string;
  type?: 'ac_secret_based' | 'ac_time_based_otp';
  args: Record<string, string>;
  actionType?: string;
};

export type AccessControlInfo = {
  sb_email?: string;
  sb_created_at?: string;
  tb_secret?: string;
  tb_issuer?: string;
  tb_account_name?: string;
  tb_created_at?: string;
};

export type EditAccessControl = {
  secret?: string | undefined;
  email?: string | undefined;
  access_code?: string;
  type?: string;
  keyId?: string;
};

export type ResponseEditAccessControl = {
  secret?: string;
  image?: string;
  recovery_code?: string[];
};

export default createSlice({
  name: 'accessControls',
  initialState: {
    loading: false,
    error: '' as string,
    meta: {} as Meta,
    showSetupAccessControl: false,
    totpDetails: null as TotpAccessControlReceipt | null,
    keyId: '' as string,
    args: '' as unknown as Record<string, string>,
    type: '' as string | undefined,
    emailSended: false as boolean | undefined,
    accessControlInfo: null as AccessControlInfo | null,
    createAccessControl: null as CreateAccessControl | null,
    resetSecret: false as boolean,
    showEditAccessControlDrawer: false as boolean,
    editAccessControl: null as ResponseEditAccessControl | null
  },
  reducers: {
    resetTotpDetails: (state, _action: PayloadAction<void>) => {
      state.totpDetails = null;
    },
    setShowSetupAccessControl: (state, action: PayloadAction<boolean>) => {
      state.showSetupAccessControl = action.payload;
      state.loading = false;
    },
    accessControlParameters: (state, action: PayloadAction<CreateAccessControl>) => {
      state.args = action.payload.args;
      state.type = action.payload.type;
    },
    addAccessControl: (state, _action: PayloadAction<CreateAccessControl>) => {
      state.loading = true;
      state.error = '';
    },
    onAddAccessControlTOTPSuccess: (state, action: PayloadAction<TotpAccessControlReceipt>) => {
      state.loading = false;
      state.error = '';
      state.totpDetails = action.payload;
    },
    onAddAccessControlSecretSuccess: (state, action: PayloadAction<void>) => {
      state.loading = false;
      state.showSetupAccessControl = false;
      state.error = '';
    },
    onAddAccessControlError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while setting up access control';
          break;
        default:
          state.error = 'Error while setting up access control';
      }
    },

    onRecoverySecretAccessControl: (state, _action: PayloadAction<any>) => {
      state.loading = true;
      state.error = '';
    },
    onRecoveryecretAccessControlSuccess: (state, action: PayloadAction) => {
      state.loading = false;
      state.error = '';
    },
    onRecoveryecretAccessControlError: (state, action: PayloadAction<{ error: string }>) => {
      state.loading = false;
      state.error = '';
    },

    onOpenResetSecretAccessControlModal: (state, action: PayloadAction<boolean>) => {
      state.resetSecret = action.payload;
    },

    onResetEmailSendedState: (state) => {
      state.emailSended = false;
    },

    onResetSecretAccessControl: (state, _action: PayloadAction<ResetAccessControl>) => {
      state.loading = true;
      state.error = '';
    },
    onResetSecretAccessControlSuccess: (state, action: PayloadAction) => {
      state.loading = false;
      state.emailSended = true;
      state.error = '';
    },

    onResetSecretAccessControlError: (state, action: PayloadAction<{ error: string }>) => {
      state.loading = false;
      state.error = '';
      toast.error('Update secret error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    onGetAccessControlInfo: (state, action: PayloadAction<{ keyId: string }>) => {
      state.loading = true;
      state.error = '';
    },
    onGetAccessControlInfoSuccess: (state, action: PayloadAction<AccessControlInfo>) => {
      state.loading = false;
      state.accessControlInfo = action.payload;
      state.error = '';
    },
    onGetAccessControlInfoError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while setting up access control';
          break;
        default:
          state.error = 'Error while setting up access control';
      }
    },
    onCloseTOTPDrawer: (state, action: PayloadAction<{ keyId: string }>) => {
      state.showSetupAccessControl = false;
    },
    onOpenEditAccessControlDrawer: (state) => {
      state.showEditAccessControlDrawer = !state.showEditAccessControlDrawer;
    },
    onEditAccessControl: (state, action: PayloadAction<EditAccessControl | null>) => {
      state.loading = true;
      state.error = '';
    },
    onEditAccessControlSuccess: (state, action: PayloadAction<ResponseEditAccessControl>) => {
      state.loading = false;
      if (action.payload.secret) {
        state.editAccessControl = action.payload;
      } else {
        state.showEditAccessControlDrawer = false;
      }

      state.error = '';
      toast.success('Your key was successfully created', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },

    onEditAccessControlError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while setting up access control';
          break;
        default:
          state.error = 'Error while setting up access control';
      }
      toast.error('Update access control error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: true,
        progress: undefined
      });
    },
    resetEditDetails: (state) => {
      state.editAccessControl = null;
    }
  }
});
