import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { forgotPasswordActions } from '../actions';
import { requestForgotPassword } from './requests';

function* forgotPassword(action: PayloadAction<{ email: string }>) {
  try {
    const { email } = action.payload;
    yield call(requestForgotPassword, email);

    yield put(forgotPasswordActions.onForgotPasswordSuccess());
  } catch (e: any) {
    yield put(
      forgotPasswordActions.onForgotPasswordError({
        error: e
      })
    );
  }
}

export default function* forgotPasswordSaga() {
  yield takeLatest(forgotPasswordActions.forgotPassword.type, forgotPassword);
}
