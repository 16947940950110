import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { feedbackActions, modalActions } from '../actions';
import { getAuthUserId } from '../auth';
import { requestSendFeedback } from './requests';

function* feedback(action: PayloadAction<{ body: string; rating: number }>) {
  try {
    const { body, rating } = action.payload;
    const userId: string = yield select(getAuthUserId);
    yield call(requestSendFeedback, userId, body, rating);
    yield put(feedbackActions.onFeedbackSuccess());
    yield put(modalActions.close());
  } catch (e: any) {
    yield put(
      feedbackActions.onFeedbackError({
        error: e
      })
    );
  }
}

export default function* feedbackSaga() {
  yield takeLatest(feedbackActions.feedback.type, feedback);
}
