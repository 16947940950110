import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { AuthProvider } from './auth/withAuth';
import { ModalManager } from './components';
import GuideThru from './components/guide-thru';
import { ToastContainerComponent } from './components/toast-notifications';
import { HttpClient } from './http/client';
import FallbackPage from './pages/404';
import { FallbackProvider } from './providers/FallbackProvider';
import { history, store } from './redux/store';
import { routes } from './routes';
import './styles/antd.less';
import './styles/global.scss';
import { getCookie } from './utils/cookie';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

function App() {
  const userLang = navigator.language || 'en-US';
  moment.locale(userLang);

  const authToken = getCookie('token');
  useEffect(() => {
    if (authToken) {
      HttpClient.token = authToken;
    }
  }, [authToken]);

  return (
    <div>
      <Suspense fallback={<></>}>
        <Provider store={store}>
          <Router history={history}>
            <FallbackProvider>
              <AuthProvider>
                <Elements stripe={stripePromise}>
                  <>
                    <Switch>
                      <Route exact path="/">
                        <Redirect to={routes.login.url} />
                      </Route>
                      {Object.entries(routes).map(([routeKey, route]) => {
                        if (route.enabled) {
                          return (
                            <Route exact key={routeKey} path={route.url}>
                              {route.render()}
                            </Route>
                          );
                        }
                        return <Redirect key={routeKey} to={routes.login.url} />;
                      })}
                      <Route path="*">
                        <FallbackPage></FallbackPage>
                      </Route>
                    </Switch>
                  </>

                  <GuideThru />
                </Elements>
              </AuthProvider>
            </FallbackProvider>
            <ToastContainerComponent />
          </Router>
          <ModalManager></ModalManager>
        </Provider>
      </Suspense>
    </div>
  );
}

export default App;
