import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export default createSlice({
  name: 'forgot-password',
  initialState: {
    loading: false,
    error: '' as string,
    isEmailSent: false
  },
  reducers: {
    forgotPassword: (state, action: PayloadAction<{ email: string }>) => {
      state.loading = true;
      state.isEmailSent = false;
    },
    resendForgotPassword: (state) => {
      state.loading = false;
      state.isEmailSent = false;
    },
    onForgotPasswordSuccess: (state) => {
      state.loading = false;
      state.isEmailSent = true;
    },
    onForgotPasswordError: (state, action: PayloadAction<{ error: number }>) => {
      state.loading = false;
      state.isEmailSent = false;
      switch (action.payload.error) {
        case 401:
          state.error = 'Error while sending the email, please try again';
          break;
        default:
          state.error = 'Error while sending the email, please try again';
      }
    }
  }
});
