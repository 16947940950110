import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { IProfile } from '.';
import { authActions, profileActions } from '../actions';
import { getAuthUserId, getToken } from '../auth';
import { requestProfile, requestProfileEdit } from './requests';

function* profile() {
  try {
    const userId: string = yield select(getAuthUserId);
    const response: IProfile = yield call(requestProfile, userId);
    yield put(profileActions.onProfileSuccess(response));
  } catch (e: any) {
    yield put(
      profileActions.onProfileError({
        error: e
      })
    );
  }
}

function* profileEdit(
  action: PayloadAction<{
    name: string;
    surname: string;
    email: string;
    deleted: boolean;
  }>
) {
  try {
    const { name, surname, email, deleted } = action.payload;
    const token: string = yield select(getToken);
    const userId: string = yield select(getAuthUserId);
    yield call(requestProfileEdit, userId, name, surname, email, deleted);
    yield put(authActions.refreshToken(token));

    yield put(profileActions.toggleIsEditing());
    yield put(profileActions.onProfileEditSuccess());
  } catch (e: any) {
    yield put(
      profileActions.onProfileEditError({
        error: e
      })
    );
  }
}

export default function* profileSaga() {
  yield takeLatest(profileActions.profile.type, profile);
  yield takeLatest(profileActions.profileEdit.type, profileEdit);
}
