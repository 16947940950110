import { HttpClient } from '../../http/client';

export async function requestChangePassword(
  userId: string,
  current_password: string,
  new_password: string,
  new_password_confirmation: string
) {
  const body = {
    current_password,
    new_password,
    new_password_confirmation
  };

  return await HttpClient.put(`/credentials/v1/password/${userId}`, body);
}
