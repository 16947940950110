import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export type IToastNotification = {
  message?: string;
  icon?: Element;
};

export const ToastContainerComponent: React.FC<IToastNotification> = ({
  message = '',
  icon,
  ...rest
}) => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnHover
      pauseOnFocusLoss
      draggable
      theme={'colored'}
      bodyStyle={{ color: 'white' }}
    ></ToastContainer>
  );
};
