import { HttpClient } from '../../http/client';
import { Meta } from '../explorer';

export type OperationsResponse = {
  operations: Operation[];
  Pagination: {
    meta: Meta;
  };
};

export type Operation = {
  transaction_id: string;
  action: string;
  key_name: string;
  ip: string;
  created_at: string;
};

export async function requestGetOperations(
  operationTypeFilter: string,
  page: number,
  pageSize: number
): Promise<OperationsResponse> {
  let url = `/keys/v1/operations?page=${page}&per_page=${pageSize}`;
  if (operationTypeFilter) {
    url += `&operation=${operationTypeFilter}`;
  }
  return await HttpClient.get(url);
}

export async function requestGetOperationsByKey(
  keyId: string,
  operationTypeFilter: string,
  page: number,
  pageSize: number
): Promise<OperationsResponse> {
  let url = `/keys/v1/keys/${keyId}/operations?page=${page}&per_page=${pageSize}`;
  if (operationTypeFilter) {
    url += `&operation=${operationTypeFilter}`;
  }
  return await HttpClient.get(url);
}

export type CountOperationsResponse = {
  sign_operations: number;
  encrypt_operations: number;
};

export async function requestGetCountOperationsByDate(
  date: string
): Promise<CountOperationsResponse> {
  const url = `/keys/v1/operations/counter?date=${date}`;
  return await HttpClient.get(url);
}
