import { CopyOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

export const EllipsisMiddle: React.FC<{
  length?: number;
  value?: string;
  copyable?: boolean;
  children?: string;
}> = ({ length = 30, copyable = true, value, children }) => {
  const separator = '...';

  if (!value) {
    value = children;
  }

  if (children && children.length > length) {
    const sepLen = separator.length,
      charsToShow = length - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    children =
      children.substr(0, frontChars) + separator + children.substring(children.length - backChars);
  }

  let copyableProp = undefined;
  if (copyable) {
    copyableProp = {
      text: value,
      icon: (
        <span className="custom-copy-icon">
          <CopyOutlined />
        </span>
      )
    };
  }

  return (
    <Typography.Text copyable={copyableProp} style={{ maxWidth: '100%' }}>
      {children}
    </Typography.Text>
  );
};
